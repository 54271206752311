<template>
        <div :id="'id'+ person.id" class="main-person-wrapper flex column" :class="{ubo_person}">
            <div
                v-if="!ubo_person && canBeDeleted" 
                style="margin-left: auto"
                >
                    <button @click="DeletePerson()">Delete</button>
            </div>
            <div class='header-info' v-if="header">{{ header }}</div>
            <div class="flex col gap-30" v-if="ubo_person">
                <InpComponent
                    class="country-select flex-1"
                    label="Origin"
                    type="options"
                    :disabled="!canBeUpdated"

                    :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                    placeholder="Local"
                    theme="light"
                    light_label="true"
                    :kyc_validate="true"
                    :key_ref="'location_type'"
                    :value="person.location_type.value"
                    @inputChange="inputChangeFunc"

                ></InpComponent>
                <InpComponent
                    :disabled="!canBeUpdated"
                    
                    class="flex-1"
                    label="Percentage of shares held"
                    type="number"
                    placeholder=""
                    :light_label="true"
                    theme="light"
                    :key_ref="'percentage_held'"
                    :value="person.percentage_held.value"
                    :error="person.percentage_held.error"
                    :kyc_validate="true"

                    @inputChange="inputChangeFunc"
                ></InpComponent>
                <div class="flex-1"></div>
            </div>

            <div class="flex row gap-30" v-if="isOfficial">
                <InpComponent
                    :disabled="!canBeUpdated"
                    class="flex-1"
                    label="Title"
                    type="options"
                    :options="[
                        { value: 'Director', text: 'Director' },
                        { value: 'Secretary', text: 'Secretary' },
                        { value: 'MD', text: 'MD' },
                        { value: 'Registered Agent', text: 'Registered Agent' },
                    ]"
                    placeholder=""
                    :light_label="true"
                    theme="light"
                    :key_ref="'title'"
                    :value="person.title.value"
                    :error="person.title.error"
                    :kyc_validate="true"

                    @inputChange="inputChangeFunc"
                ></InpComponent>
                <div class="flex-1"></div>
                <div class="flex-1"></div>
            </div>

            <div class="flex row gap-30 wrap">
                <div class="flex-1">
                    <div class="passport-fields file-area">
                    
                    <InpComponent
                            :disabled="!canBeUpdated"
                            label="First Name"
                            type="text"
                            placeholder="Don"
                            :light_label="true"
                            theme="light"
                            :key_ref="'passport_file | firstName'"
                            :value="person.passport_file.firstName.value"
                            :error="person.passport_file.firstName.error"
                            :kyc_validate="true"

                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                    
                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Last Name"
                            type="text"
                            placeholder="Jon"
                            :light_label="true"
                            theme="light"
                            :key_ref="'passport_file | lastName'"
                            :value="person.passport_file.lastName.value"
                            :error="person.passport_file.lastName.error"
                            :kyc_validate="true"
                            @inputChange="inputChangeFunc"
                            ></InpComponent>

                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Date of Birth"
                            type="date"
                            :light_label="true"
                            theme="light"
                            :key_ref="'passport_file | dateOfBirth'"
                            :value="person.passport_file.dateOfBirth.value"
                            :error="person.passport_file.dateOfBirth.error"
                            :kyc_validate="true"
                            @inputChange="inputChangeFunc"
                            ></InpComponent>

                            
                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Passport Number"
                            type="number"
                            :light_label="true"
                            placeholder="number"
                            theme="light"
                            :key_ref="'passport_file | passportNumber'"
                            :value="person.passport_file.passportNumber.value"
                            :error="person.passport_file.passportNumber.error"
                            :kyc_validate="true"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                        <InpComponent
                            :disabled="!canBeUpdated"
                            
                            class="country-select"
                            label="Issue country"
                            type="options"
                            :options="Utils && Utils.getCountryList()"
                            placeholder="Curacao"
                            theme="light"
                            :light_label="true"
                            :key_ref="'passport_file | passportCountry'"
                            :value="person.passport_file.passportCountry.value"
                            :kyc_validate="true"
                            :error="person.passport_file.passportCountry.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Issue Date"
                            type="date"
                            :light_label="true"
                            placeholder="2024/01/01"
                            theme="light"
                            :kyc_validate="true"
                            :key_ref="'passport_file | dateIssued'"
                            :value="person.passport_file.dateIssued.value"
                            :error="person.passport_file.dateIssued.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>

                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Expiry Date"
                            type="date"
                            :light_label="true"
                            placeholder="2024/01/01"
                            theme="light"
                            :key_ref="'passport_file | dateExpiry'"
                            :kyc_validate="true"
                            :value="person.passport_file.dateExpiry.value"
                            :error="person.passport_file.dateExpiry.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>

                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Taxpayer Identification Number"
                            type="number"
                            :light_label="true"
                            placeholder="number"
                            theme="light"
                            :key_ref="'passport_file | tinNumber'"
                            :kyc_validate="true"
                            :value="person.passport_file.tinNumber.value"
                            :error="person.passport_file.tinNumber.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                    </div>
                </div>
                <div class="file-area flex-1">
                    <AddressComp
                        :key="person.id"
                        :disabled="!canBeUpdated"
                        
                        :address="person.address_file"
                        @addressChange="changeAddress">
                    </AddressComp>
                </div>
                
                <div class='file-area flex-1' v-if="
                    (
                        ComponentPerson.location_type.new_value == 'abroad' ||
                        (ComponentPerson.location_type.value == 'abroad' && ComponentPerson.location_type.new_value == '') || 
                        (ComponentPerson.location_type.value == 'abroad' && ComponentPerson.location_type.new_value == 'abroad')
                    )">

                    <div class="passport-fields ">

                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Document Type"
                            type="options"
                            :light_label="true"
                            placeholder=""
                            theme="light"
                            :kyc_validate="true"

                            :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                            :key_ref="'secondPassport_file | secondPassportType'"
                            :value="person.secondPassport_file.secondPassportType.value"
                            :error="person.secondPassport_file.secondPassportType.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>

                        <InpComponent
                            :label="docLabel"
                            type="number"
                            :disabled="disabled_number || !canBeUpdated"
                            :light_label="true"
                            placeholder="number"
                            :kyc_validate="true"
                            theme="light"
                            :key_ref="'secondPassport_file | secondPassportNumber'"
                            :value="person.secondPassport_file.secondPassportNumber.value"
                            :error="person.secondPassport_file.secondPassportNumber.error"
                        @inputChange="inputChangeFunc"
                        ></InpComponent>
                        
                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            class="country-select"
                            label="Issue country"
                            type="options"
                            :options="Utils && Utils.getCountryList()"
                            placeholder="Curacao"
                            :kyc_validate="true"
                            theme="light"
                            :light_label="true"
                            :key_ref="'secondPassport_file | secondPassportCountry'"
                            :value="person.secondPassport_file.secondPassportCountry.value"
                            :error="person.secondPassport_file.secondPassportCountry.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>

                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Issue Date"
                            type="date"
                            :light_label="true"
                            placeholder="2024/01/01"
                            :kyc_validate="true"
                            theme="light"
                            :key_ref="'secondPassport_file | secondDateIssued'"
                            :value="person.secondPassport_file.secondDateIssued.value"
                            :error="person.secondPassport_file.secondDateIssued.error"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>

                        <InpComponent
                            
                            :disabled="!canBeUpdated"
                            label="Expiry Date"
                            type="date"
                            :light_label="true"
                            placeholder="2024/01/01"
                            :kyc_validate="true"
                            theme="light"
                            :key_ref="'secondPassport_file | secondDateExpiry'"
                            :value="person.secondPassport_file.secondDateExpiry.value"
                            :error="person.secondPassport_file.secondDateExpiry.error"
                        @inputChange="inputChangeFunc"
                        ></InpComponent>
                    </div>
                </div>
                <div v-else class="flex-1">

                </div>
            </div>
            <div class="table-wrap" style="margin-top: 40px;">

            <table style="height: 100%;">
                <TableHeaderRow 
                    :preview="preview"
                />

                    <tbody>
                        <TableFileRow 
                            :id="person.passport_file?.id"
                            class="margin-div-el"
                            @handleFileChange="handleFileChange" 
                            @commentChange="commentChange"
                            :comment_data_handler_value="person.passport_file?.comment_data_handler"
                            :comment_compliance_officer_value="person.passport_file?.comment_compliance_officer"
                            :comment_supervisor_value="person.passport_file?.comment_supervisor"
                            fileType='passport_file' 
                            :index="person.passport_file?.file?.id"
                            :filePresent="!person.passport_file.new_file && person.passport_file?.file"  
                            :file="person?.passport_file.file"  
                            :header="`Certified copy of valid passport`"
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                        />
                        <TableFileRow 
                            :id="person.address_file?.id"
                            @commentChange="commentChange"
                            :comment_data_handler_value="person.address_file?.comment_data_handler"
                            :comment_compliance_officer_value="person.address_file?.comment_compliance_officer"
                            :comment_supervisor_value="person.address_file?.comment_supervisor"
                            @handleFileChange="handleFileChange"
                            fileType='address_file' 
                            :index="person.address_file?.file?.id"
                            :filePresent="!person.address_file.new_file && person.address_file?.file"  
                            :file="person?.address_file.file"  
                            :header="`Certified copy of proof of address  \n(not older then 3 months)`" 
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                        />
                        <TableFileRow 
                            v-if="
                            (
                                ComponentPerson.location_type.new_value == 'abroad' ||
                                (ComponentPerson.location_type.value == 'abroad' && ComponentPerson.location_type.new_value == '') || 
                                (ComponentPerson.location_type.value == 'abroad' && ComponentPerson.location_type.new_value == 'abroad')
                            )"
                            :id="person.secondPassport_file?.id"

                            @commentChange="commentChange"
                            :comment_data_handler_value="person.secondPassport_file?.comment_data_handler"
                            :comment_compliance_officer_value="person.secondPassport_file?.comment_compliance_officer"
                            :comment_supervisor_value="person.secondPassport_file?.comment_supervisor"
                            @handleFileChange="handleFileChange" 
                            fileType='secondPassport_file' 
                            class="margin-div-el"
                            :index="person.secondPassport_file?.file?.id"
                            :file="person?.secondPassport_file.file"  

                            :filePresent="!person.secondPassport_file.new_file && person.secondPassport_file?.file"  
                            :header="`Certified copy of valid second ID`"
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                         />
                        <TableFileRow 
                            v-if="source_of_wealth"
                            :id="person.source_of_wealth?.id"
                            @commentChange="commentChange"
                            :comment_data_handler_value="person.source_of_wealth?.comment_data_handler"
                            :comment_compliance_officer_value="person.source_of_wealth?.comment_compliance_officer"
                            :comment_supervisor_value="person.source_of_wealth?.comment_supervisor"
                            @handleFileChange="handleFileChange"
                            fileType='source_of_wealth' 
                            :filePresent="!person.source_of_wealth.new_file && person.source_of_wealth?.file"  
                            :file="person?.source_of_wealth.file"  
                            :header="`Source of Wealth`" 
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                        />
                        <TableFileRow 
                            v-if="bank_reference_letter"
                            :id="person.bank_reference_letter?.id"
                            @commentChange="commentChange"
                            :comment_data_handler_value="person.bank_reference_letter?.comment_data_handler"
                            :comment_compliance_officer_value="person.bank_reference_letter?.comment_compliance_officer"
                            :comment_supervisor_value="person.bank_reference_letter?.comment_supervisor"
                            @handleFileChange="handleFileChange"
                            fileType='bank_reference_letter' 
                            :filePresent="!person?.bank_reference_letter?.new_file && person.bank_reference_letter?.file"  
                            :file="person?.bank_reference_letter.file"  
                            :header="`Certified Bank Reference Letter`" 
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                        />
                        <TableFileRow 
                            v-if="source_of_funds"
                            :id="person.source_of_funds?.id"
                            @commentChange="commentChange"
                            :comment_data_handler_value="person.source_of_funds?.comment_data_handler"
                            :comment_compliance_officer_value="person.source_of_funds?.comment_compliance_officer"
                            :comment_supervisor_value="person.source_of_funds?.comment_supervisor"
                            @handleFileChange="handleFileChange"
                            fileType='source_of_funds' 
                            :filePresent="!person.source_of_funds.new_file && person.source_of_funds?.file"  
                            :file="person?.source_of_funds.file"  
                            :header="`Source of funds`" 
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                        />
                        <TableFileRow 
                            v-if="google_search"
                            :id="person.google_search?.id"
                            @commentChange="commentChange"
                            :comment_data_handler_value="person.google_search?.comment_data_handler"
                            :comment_compliance_officer_value="person.google_search?.comment_compliance_officer"
                            :comment_supervisor_value="person.google_search?.comment_supervisor"
                            @handleFileChange="handleFileChange"
                            fileType='google_search' 
                            :filePresent="!person.google_search.new_file && person.google_search?.file"  
                            :file="person?.google_search.file"  
                            :header="`Google Search`"
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                        />
                        <TableFileRow 
                            v-if="world_check"
                            :id="person.world_check?.id"
                            @commentChange="commentChange"
                            :comment_data_handler_value="person.world_check?.comment_data_handler"
                            :comment_compliance_officer_value="person.world_check?.comment_compliance_officer"
                            :comment_supervisor_value="person.world_check?.comment_supervisor"
                            @handleFileChange="handleFileChange"
                            @sanctionScreaningRequest="sanctionScreaningRequest"
                            fileType='world_check' 
                            :filePresent="!person.world_check.new_file && person.world_check?.file"  
                            :file="person?.world_check.file"  
                            :header="`Sanctions / PEP / Relevant Adverse Information`"
                            :preview="preview"
                            :upload_available="canBeUpdated"
                            :download_available="canDownloadFiles"
                            :delete_available="canDeleteFiles"
                        />    
                </tbody>
            </table>
        </div>
        </div>
</template>

<script setup>

    import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import TableHeaderRow from '@/components/TableHeaderRow.vue'
    import InpComponent from '@/components/InpComponent.vue'
    import TableFileRow from '@/components/TableFileRow.vue'
    import Utils from '@/utils/index.js'
    import AddressComp from '@/components/AddressComp.vue'
    import API from '../api/index'
    const props = defineProps([
        'person', 
        'index', 
        'ubo', 
        'ubo_person',
        'isOfficial',
        'mba', 
        'source_of_wealth',
        'bank_reference_letter',
        'source_of_funds',
        'google_search',
        'world_check',
        'header',
        'preview',
        'canBeUpdated',
        'canDownloadFiles',
        'canDeleteFiles',
        'canBeDeleted',
    ])
    
    const fieldNameMap = {
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'dateOfBirth': 'Date of Birth',
        'passportCountry': 'Passport Issue Country',
    }
    const store = useStore();
    const user = computed(() => store.state.user)
    const emit = defineEmits(['handleStructureUpdates', 'delete_person', 'commentChange'])
    
    const ComponentPerson = ref(props.person)
    const docLabel = ref(0)


    function commentChange(event) {
        const comment_type = event.comment_type;
        ComponentPerson.value[event.fileType][comment_type] = event.comment;
        ComponentPerson.value[event.fileType][`new_${comment_type}`] = true;
        emit('personChange', {
            person: ComponentPerson.value
        })
    }
    const disabled_number = computed(() => {
        return !(
            ComponentPerson.value.secondPassport_file.secondPassportType.new_value.length
            || ComponentPerson.value.secondPassport_file.secondPassportType.value.length
        )
    })
    async function sanctionScreaningRequest(event) {
        console.log('sanction Screaning Request', event)
        const response = await API.newDueDiligenceRequest({person_id: ComponentPerson.value.id});
        console.log('sanctionScreaningRequest', response)
        if (response.status == 'error') {


            if (response.requiredFileds) {
                const errorText = `Please fill ${response.requiredFileds.map(el => fieldNameMap[el]).join(', ')} fields before using API sanction screening`
                store.dispatch('addNotification', {text: errorText, type: 'error'})
            } else {
                store.dispatch('addNotification', {text: response.message, type: 'error'})
            }
        }
    }
    function setLabel(value){
        if (value == 'id_card') {
            docLabel.value = 'ID Card Number'
        } else if (value == 'driver_license') {
            docLabel.value = 'Driver license Number'
        } else {
            docLabel.value = 'Document Number'
        }
    }
   
    onMounted(() => {
        setLabel(ComponentPerson.value.secondPassport_file.secondPassportType.value)
    })

    function changeAddress(event) {
        ComponentPerson.value.address_file[event.property].new_value = event.value;
        emit('personChange', {
            person: ComponentPerson.value
        })
    }

    function inputChangeFunc(event) {
        const event_type = event[0];
        const event_value = event[1];
        const [doc, field] = event_type.split(' | ');
        if (!field) {
            ComponentPerson.value[doc].new_value = event_value;
        } else {
            ComponentPerson.value[doc][field].new_value = event_value;
        }
        if (doc == 'secondPassport_file' && field == 'secondPassportType') {
            setLabel(event_value)
        }
        emit('personChange', {
            person: ComponentPerson.value
        })
    }

    async function DeletePerson() {
        if (window.confirm('Are you sure you want to delete this Official?')) {
            const structure = JSON.stringify(ComponentPerson.value)
            const result = await API.deleteObject(ComponentPerson.value.id, 'person', 'Official', structure);
            if (result) {
                emit('delete_person', ComponentPerson.value.id)
            }
        }
    }

    function handleFileChange(event) {
        ComponentPerson.value[event.fileType].file = event.file;
        ComponentPerson.value[event.fileType].new_file = true
        emit('personChange', {
            person: ComponentPerson.value
        })
    }
    
</script>

<style scoped lang="scss">

.file-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.dynamic-file {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 35px;
}
.flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .header-info {
        width: 100%;
        text-align: left;
        font-size: 24px;
    }
    .passport-fields {
        margin-top: 20px;
    }
    
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    

    .margin-div-el {
        margin-top: 19px;
    }
    .main-person-wrapper {
        border-radius: 5px;
        background-color: white;
        &:not(.ubo_person) {
            padding: 20px;
        }
    }
    table {
        border-collapse: collapse;
    }
    thead {
        font-weight: bold;
    }
    td {
        padding: 10px;
        border: 1px solid var(--border-color);
    }

</style>