<template>
    <div class="register">
        <div class="register-wrap border-section">
            <div class="title flex" >
                Set up TwoFactor Authentication
            </div>
            <div class="w-full flex column items-center justify-center" >
                <QrCode
                    :twoFaCode="user.two_factor_secret"
                    :email="user.email"
                />
                <TwoFactorInput
                    class="mt-4 mb-4 w-full "
                    @twoFaCodeUpdate="handleTwoFaCode"
                    :twoFacode="user?.two_factor_secret"
                    :twoFaError="error"
                    @twoFaCodeInput="twoFaInput"
                />
                <button class='button main-color-bg' @click="handleTwoFaCode()">Submit</button>
            </div>
        </div>
    </div>
</template>

<script setup>

import { defineProps, ref, onMounted, computed } from 'vue';
import QrCode from './QrCode.vue';
import TwoFactorInput from './TwoFactorInput.vue';
import API from '../api/index.js';
import { useStore } from 'vuex';
const store = useStore();

const user = computed(() => store.state.user);
const challengeError = ref(null);
const code = ref('');
const error = ref('');

async function handleTwoFaCode(codeValue) {
    code.value = codeValue;
    const response = await API.verifyTwoFaCode(code.value);
    console.log(response);
    if (response.data.status == 'success') {
        store.dispatch('addNotification', {
            text: 'Two Factor Authentication set up successfully',
            type: 'success'
        });
        store.state.user.two_factor_enabled = true;
        store.state.user.two_factor_secret = null;
    } else {
        error.value = response.data.message;
    }
}
function twoFaInput() {
    error.value = '';
    console.log('twoFaInput');
}


</script>

<style scoped lang="scss">
    .title {
        margin-bottom: 20px;
    }
    .error-item {
        color: red;
        margin: 0;
        height: fit-content;
        text-align: left;
        font-size: 9px;
        margin: 0;
        margin-left: -15px;
        margin-top: -5px;
    }
    .register {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 60px);
        color: #38314e;
        font-size: 23px;
        font-weight: bold;
        text-align: left;
    }
    .title {
        text {
        font-size: 20px;
        font-weight: bold;
        margin: auto;
        }
    }
    .register-wrap {
        background-color: white;
        padding: 20px;
        margin-top: -60px;
        border-radius: 10px;

    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 330px;
        > * {
        margin: 10px;
        width: 100%;
        font-size: 15px;
        }
        input {
        border: 1px solid #2b263a;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-radius: 0;
        outline: none;
        caret-color: #2b263a;
        color: #38314e;
        background-color: white;
        padding: 3px 10px;
        box-sizing: border-box;
        &.error {
            border: 1px solid red;
        }
        }
        button {
        height: fit-content;
        margin-bottom: 0;

        }   
    }
    .forgot-password {
        height: fit-content;
        text-align: right;
        margin: 2px;
        font-size: 11px;
        width: fit-content;
        margin-left: auto;
        text-decoration: underline;
        cursor: pointer;
    }
    .login {
        height: fit-content;
        text-align: right;
        margin: 2px;
        font-size: 11px;
        text-decoration: underline;
        cursor: pointer;
    }
</style>