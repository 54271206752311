<template>
    <div class="wrapper-main">
        <div class="flex column gap40">
            <div class="type-selector">
                <InpComponent
                    class="country-select"
                    label="Origin"
                    type="options"
                    :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                    placeholder="Local"
                    theme="light"
                    :disabled="!canBeUpdated"
                    light_label="true"
                    :key_ref="'mbd | location_type'"
                    :value="mbd['location_type'].new_value || mbd['location_type'].value"
                    @inputChange="inputChangeFunc"

                ></InpComponent>
                <InpComponent 
                    v-if="typeShown"
                    class="country-select"
                    label="Director Type"
                    type="options"
                    :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                    placeholder="Local"
                    theme="light"
                    :disabled="!canBeUpdated"
                    light_label="true"
                    :key_ref="'mbd | entity_type'"
                    :value="mbd['entity_type'].new_value || mbd['entity_type'].value"
                    @inputChange="inputChangeFunc"
                ></InpComponent>
          
                <div style="margin-left: auto" v-if="canBeDeleted">
                    <button @click="DeleteMBD()">Delete</button>
                </div>
            </div>
          
            <div v-if="mbd?.entity_type">
                <div v-if="mbd?.struct?.legalEntity && (getCurrentEntityType != 'person' || getCurrentLocationType == 'local')">
                    <EntityView
                        :key="`${mbd.id}_entity_view_${mbd?.struct?.legalEntity?.id}`"
                        :entity="mbd?.struct?.legalEntity"
                        :mbd="mbd"
                        @entityChange="handleEntityChange"
                        :preview="preview"
                        :canBeUpdated="canBeUpdated"
                        :canBeDeleted="canBeDeleted"
                        :canDownloadFiles="canDownloadFiles"
                        :canDeleteFiles="canDeleteFiles"
                    >
                
                    </EntityView>
                </div>
                <div class='officials margin-top-20'>
                    <div class="flex row align-center space-between mb-20">
                        <div></div>
                        <h3 class="doc-header">Official(s) </h3>
                        <div v-if="PermissionChecker.creatembdSection()">
                            <button class="add-new no-margin fit-content" @click="addNewOfficial()">Add Official</button>
                        </div>
                        <div v-else></div>
                    </div>
                    <div class="person-wrapper">
                        <PersonComp
                            v-for="(person, index ) in mbd?.struct?.person"
                            :key="`${person.id}_person_view`"
                            :person="person"
                            :isOfficial="true"
                            :bank_reference_letter="true"
                            :world_check="true"
                            :google_search="true"
                            @personChange="personChange"
                            @delete_person="deletePerson"
                            :preview="preview"
                            :canBeUpdated="canBeUpdated"
                            :canBeDeleted="canBeDeleted"
                            :canDownloadFiles="canDownloadFiles"
                            :canDeleteFiles="canDeleteFiles"
                        ></PersonComp>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script setup>
    import UploaderTable from '@/components/UploaderTable.vue'
    import InpComponent from '@/components/InpComponent.vue'
    
    import Utils from '@/utils/index.js'
    import { useStore } from 'vuex'
    import { ref, computed, defineProps, defineEmits } from 'vue'
    import PersonComp from './PersonComp.vue'
    import EntityView from './EntityView.vue'
    import { getPersonStructure } from '../utils/kyc_utils.js'
    import API from '../api/index'
    import { PermissionChecker } from '@/utils/iam_utils'

    const store = useStore()
    const user = computed(() => store.state.user)

    const emit = defineEmits(['update_mbd'])

    const props = defineProps([
        'mbd', 
        'preview',
        'canBeUpdated',
        'canBeDeleted',
        'canDownloadFiles',
        'canDeleteFiles',
    ])
    const localMBD = ref(props.mbd)

    const typeShown = computed(() => (localMBD.value['location_type'].value === 'abroad' && localMBD.value['location_type'].new_value === '') ||
                        localMBD.value['location_type'].new_value === 'abroad' )

    const supervisedShown = computed(() => (localMBD.value['entity_type'].value === 'entity' && localMBD.value['entity_type'].new_value === '') ||
                    localMBD.value['entity_type'].new_value === 'entity')

    const getCurrentLocationType = computed(() => (localMBD.value['location_type'].new_value || localMBD.value['location_type'].value))
    const getCurrentEntityType = computed(() => (localMBD.value['entity_type'].new_value || localMBD.value['entity_type'].value))
                    
    function inputChangeFunc(event) {
     
        const event_type = event[0];
        const event_value = event[1];
        const [type, field] = event_type.split(' | ');
        if (type == 'mbd') {
            localMBD.value[field].new_value = event_value;
            if (field == 'supervised') {
                localMBD.value.struct.legalEntity[field].new_value = event_value
            }
            emit('update_mbd', {
                mbd: localMBD.value.id,
                field,
                value: event_value
            })
        }

    }
    function addNewOfficial(){
        if (!localMBD.value.struct.person) {
            localMBD.value.struct.person = []
        }
        localMBD.value.struct.person.unshift(
            getPersonStructure(localMBD.value['location_type'].new_value || localMBD.value['location_type'].value)
        )
    }
    async function DeleteMBD(){
        if (window.confirm('Are you sure you want to delete this Managing board of directors?')) {
            const structure = JSON.stringify(localMBD.value)
            const result = await API.deleteObject(localMBD.value.id, 'mbd', 'Managing board of directors', structure);
        }
    }

    function deletePerson(event) {
        const id = event.id;
        const index = localMBD.value.struct.person.findIndex(person => person.id == id);
        localMBD.value.struct.person.splice(index, 1)
        emit('update_mbd', {
            mbd: localMBD.value.id,
        })
    }
    function personChange(event) {
        const id = event.person.id;
        const index = localMBD.value.struct.person.findIndex(person => person.id == id);
        localMBD.value.struct.person[index] = event.person;
        emit('update_mbd', {
            mbd: localMBD.value.id,
        })
    }
    function handleEntityChange(event) {
        localMBD.value.struct.legalEntity = event.entity;
        emit('update_mbd', {
            mbd: localMBD.value.id,
            // field: 'legalEntity',
            // value: event.entity
        })
    }

</script>

<style scoped lang="scss">
.officials {
    border-radius: 5px;
    padding: 20px;
    background-color: var(--app-background);
    border: 1px solid var(--border-color);
}


.type-selector {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.wrapper-main {
    border-radius: 5px;
    background-color: white;
    max-width: 1350px;
    // width: 100%;
    margin: auto;
    margin-bottom: 40px;
    border: 1px solid #e0e0e0;

    padding: 20px;
}

.header-section {
        background-color: white;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
    }
    .kyc-fields-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .kyc-fields-area {
        max-width: 700px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;

    }
    .kyc-item-section {
        width: 700px;
        margin-bottom: 30px;
        h3 {
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    .person-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .margin-top-40 {
        margin-top: 40px;
    }
    .entity_person_header {
        width: 100%;
        text-align: left;
    }
</style>