<template>
    <div class="subsidiary-container">
    <div class="subsidiary-content">
        <div class="sub-header" >
            <h4 class="subsidiary-title">Subsidiary List</h4>
            <button v-if="PermissionChecker.createSubsidiaries()" @click="addObject()">Add Subsidiary</button>
        </div>
        <table class="subsidiary-list">
            <thead>
                <tr>
                    <th>Subsidiary Name</th>
                    <th>Last Annual Account on File</th>
                </tr>
            </thead>
            <tbody class='table-content' v-if="store.state.subsidiaries">
                
                <tr class="account-list" v-for="subsidiary in store.state.subsidiaries" :key="subsidiary._id" @click="openObject(subsidiary)">
                    <td class="user-info"> 
                        <div class="icon-person">
                            <img src="@/assets/subsidiaries.svg" style='width: 25px;' alt="kyc" />
                        </div>
                        <div class="user-name">
                            {{ subsidiary.name }}
                        </div>
                    </td>
                    <td>{{ subsidiary.last_annual_account_on_file }}</td>
                </tr>

            </tbody>
        </table>
    </div>
 
     <PopupCover v-if="showPopups" @close="closePopup">
        <SubsidiaryPopup 
            @close="closePopup" 
            :objectInfo="objectInformation"
            @InfoUpdate="createObjectPopupHandler"
            @objectAction="objectActionHandler"
            @delete_object="deleteObjectHandler"
        />
    </PopupCover>
 </div>
 </template>
 
 <script setup>

import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import { PermissionChecker } from '@/utils/iam_utils';
import API from '../api/subsidiary'
import API_MAIN from '../api/index'
import store from '@/store/index.js';
import PopupCover from '@/components/PopupCover.vue';
import SubsidiaryPopup from '@/components/SubsidiaryPopup.vue';
import dayjs from 'dayjs'
 
import { useRouter, useRoute } from 'vue-router'

const router = useRouter();

const user = computed(() => store.state.user);
const emit = defineEmits(['delete_person', 'commentChange'])
const objectInformation = ref(null);
const showPopups = ref(false);


async function createObjectPopupHandler(event) {
    showPopups.value = false;
    const addedSubsidiary = await API.addSubsidiary(event.object);
    store.state.subsidiaries.unshift(addedSubsidiary.subsidiary)
}

async function objectActionHandler() {
    showPopups.value = false;

    // store.dispatch('setValue', {key: 'selectedSnapshot', value: objectInformation.value});
    // router.push('/kyc')
}

function closePopup() {
    showPopups.value = false;
    objectInformation.value = null;
}
async function deleteObjectHandler() {
    showPopups.value = false;
    let structure = JSON.stringify(objectInformation.value);
    const result = await API_MAIN.deleteObject(objectInformation.value._id, 'subsidiary', 'Subsidiary', structure);
    if (result) {
        store.state.subsidiaries = store.state.subsidiaries.filter(object => object._id !== objectInformation.value._id)
        store.dispatch('addNotification', {text: 'Subsidiary has been deleted successfully', type: 'success'})
    }
    objectInformation.value = null;
}
function openObject(object) {
    objectInformation.value = object;
    showPopups.value = true;
}

function addObject() {
    showPopups.value = true;
}

onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
    await API.getSubsidiaries()
});

</script>
 
<style scoped lang="scss">
    
    .icon-person {

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        height: 30px;
        color: var(--primary-color);
        background: rgb(236, 240, 255);
    }
    .user-info {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .subsidiary-container {
        width: 100%;
    }
    
    .subsidiary-content {
        min-width: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: fit-content;
    }
    
    .sub-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 20px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
    .subsidiary-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
    }
    
    .sub-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;
        }
    }
    .subsidiary-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            // border: 1px solid #ccc;
            padding: 15px;
            text-align: left;
            border: none;
            padding-left: 30px;
            color: black;
        }
        td {
            padding-top: 19px;
            padding-bottom: 19px;
        }
        th {
            text-align: left;
            border: none;
        }
        tr {
            border-top: 1px solid #ccc;
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    background: white;
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
                &:hover {
                    td {
                        background: #f9f9f9;
                    }
                }
            }
        }
    }
    
    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;
    
    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    h4 {
        margin: 0;
    }
    .add-new-subsidiary-area {
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
 
 .more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    width: 35px;
    height: 25px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background: #d9d9d9;

        .dropdown {
            display: flex;
            flex-direction: column;

        }
    }
    position: relative;

    .dropdown {
        display: none;
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
    }
}
 
</style>












<!-- <template>
   <div class="subsidiary-container">
    <div class="subsidiary-content">
        <div class="sub-header" >
            <h4 class="subsidiary-title"> Subsidiary list</h4>
            <button @click="addSubsidiary()">Add Subsidiary</button>
        </div>
        <table class="subsidiary-list">
            <thead>
                <tr style="background-color: #f9f9f9;">
                    <th>Subsidiary Name (label)</th>
                    <th>Last Annual Account on file</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class='table-content' v-if="store.state.subsidiaries">
                <tr class='add-new' v-if="addSubsidiaryOn">
                    <td class="new">
                        <InpComponent
                            label=""
                            type="text"
                            class="text-input"
                            placeholder=""
                            light_label="true"
                            theme="light"
                            :key_ref="'name'"
                            :value="newSubsidiary.name"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                    </td>
                    <td class="add-new-subsidiary-area">
                        <InpComponent 
                            type="options"
                            :options="yearOptions"  
                            theme="light"
                            key_ref="last_annual_account_on_file"
                            :value="newSubsidiary?.last_annual_account_on_file" 
                            @inputChange="inputChangeFunc"
                        />    
                    </td>
                    <td>
                        <button class='save-button' @click="save()">Save</button>
                    </td>
                </tr>
                <tr v-for="subsidiary in store.state.subsidiaries" :key="subsidiary._id">
                    <td v-if="editElement !== subsidiary._id">{{ subsidiary.name }}</td>
                    <td class="new" v-else>
                        <InpComponent
                            label=""
                            type="text"
                            placeholder=""
                            light_label="true"
                            class="text-input"
                            theme="light"
                            :key_ref="'name'"
                            :value="subsidiary.name"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                    </td>

                    <td v-if="editElement !== subsidiary._id">{{ subsidiary.last_annual_account_on_file }}</td>
                    <td class="add-new-subsidiary-area" v-else>
                        <InpComponent 
                            type="options"
                            :options="yearOptions"  
                            theme="light"
                            key_ref="last_annual_account_on_file"
                            :value="subsidiary.last_annual_account_on_file" 
                            @inputChange="inputChangeFunc"
                        />   
                    </td>
                    <td >
                        <div class="more-actions" v-if="editElement !== subsidiary._id">
                            <svg data-v-a5ea9760="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" ssr="true" class=" iconify iconify--cosmos" width="0.29em" height="1em" viewBox="0 0 4 14"><g fill="none"><path d="M3.16683 1.49998C3.16683 2.14431 2.6445 2.66665 2.00016 2.66665C1.35583 2.66665 0.833496 2.14431 0.833496 1.49998C0.833496 0.855647 1.35583 0.333313 2.00016 0.333313C2.6445 0.333313 3.16683 0.855647 3.16683 1.49998Z" fill="#74777F"></path>
                            <path d="M3.16683 6.99998C3.16683 7.64431 2.6445 8.16665 2.00016 8.16665C1.35583 8.16665 0.833496 7.64431 0.833496 6.99998C0.833496 6.35565 1.35583 5.83331 2.00016 5.83331C2.6445 5.83331 3.16683 6.35565 3.16683 6.99998Z" fill="#74777F"></path>
                            <path d="M3.16683 12.5C3.16683 13.1443 2.6445 13.6666 2.00016 13.6666C1.35583 13.6666 0.833496 13.1443 0.833496 12.5C0.833496 11.8556 1.35583 11.3333 2.00016 11.3333C2.6445 11.3333 3.16683 11.8556 3.16683 12.5Z" fill="#74777F"></path></g></svg>
                            <div class="dropdown">
                                <div @click="editElementFunc(subsidiary)">Edit</div>
                                <div @click="deleteElement(subsidiary._id)">Delete</div>

                            </div>    
                        </div>
                        <button class='save-button' v-else @click="confirmEdit()">Save</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
</template>

<script setup>

import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import API from '../api/subsidiary'
import store from '@/store/index.js';


const newSubsidiary = ref({
    name: '',
    last_annual_account_on_file: ''
})


const editElement = ref(null);

const emit = defineEmits(['handleStructureUpdates', 'delete_person', 'commentChange'])

const subsidiaries = ref(store.state.subsidiaries)
const addSubsidiaryOn = ref(false);

function addSubsidiary() {
    editElement.value = null;
    newSubsidiary.value = {
        name: '',
        last_annual_account_on_file: '',
    }
    addSubsidiaryOn.value = true;
    console.log('addSubsidiary')
}

function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    newSubsidiary.value[event_type] = event_value;
}

function editElementFunc(element) {
    addSubsidiaryOn.value = false;
    editElement.value = element._id;
    newSubsidiary.value = {
        name: element.name,
        last_annual_account_on_file: element.last_annual_account_on_file
    }
    console.log('editElement', element._id)
}

async function deleteElement(id) {
    console.log('deleteElement', id)
    await API.deleteSubsidiary(id);
    store.state.subsidiaries = store.state.subsidiaries.filter(subsidiary => subsidiary._id !== id)
}
async function save() {
    const addedSubsidiary = await API.addSubsidiary(newSubsidiary.value);
    store.state.subsidiaries.unshift(addedSubsidiary.subsidiary)
    console.log('addedSubsidiary', addedSubsidiary)
    newSubsidiary.value = {
        name: '',
        last_annual_account_on_file: ''
    }
    addSubsidiaryOn.value = false;
}
async function confirmEdit() {
    const updatedSubsidiary = await API.editSubsidiary({id: editElement.value, name: newSubsidiary.value.name, year: newSubsidiary.value.last_annual_account_on_file});
    
    
    store.state.subsidiaries = store.state.subsidiaries.map(subsidiary => {
        if (subsidiary._id === editElement.value) {
            return { ...subsidiary, name: newSubsidiary.value.name, last_annual_account_on_file: newSubsidiary.value.last_annual_account_on_file }
        }
        return subsidiary
    })
    editElement.value = null;
    newSubsidiary.value = {
        name: '',
        last_annual_account_on_file: ''
    }
}
onMounted( async () => {
    await API.getSubsidiaries()

})


</script>

<style scoped lang="scss">


.subsidiary-container {
    width: 100%;
    max-width: 1000px;
}
.subsidiary-content {
    width: 100%;
    max-width: 1000px;
    border: 1px solid #ccc;
    border-radius: 20px;

}
.sub-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subsidiary-title {
    display: flex;
    justify-content: space-between;
 
}
.sub-header {
    display: flex;
    justify-content: space-between;
    button {
        width: fit-content;
        padding: 10px;
    }
}
.subsidiary-list {
    padding: 20px;
    width: 100%;
    border-collapse: collapse;
    th, td {
        // border: 1px solid #ccc;
        padding: 10px;
    td {
        border: none;
    }
    }
    th {
        border: none;
        tr {
            border: none;
        }
    }
    tr {
        border-top: 1px solid #ccc;
    }

}

.add-new td{
    padding-top: 0px;
    padding-bottom: 0px;

}
.save-button {
    padding: 10px 20px;
    width: fit-content;
    margin-left: auto;
}
h4 {
    margin: 0;
}
.add-new-subsidiary-area {
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.text-input {
    &:deep(input) {
        text-align: center;
    }
}
.sub-header {
    border-bottom: 1px solid #ccc;
}

.more-actions {
    width: 30px;
    padding: 5px 0px;
    border-radius: 4px;
    max-width: 40px;
    margin-left: auto;
    cursor: pointer;
    &:hover {
        background: #d9d9d9;

        .dropdown {
            display: flex;
            flex-direction: column;

        }
    }
    position: relative;

    .dropdown {
        display: none;
        position: absolute;
        top: 30px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
    }
}

</style> -->