<template>
    <header class="header">
        <div class="logo">
            <img src="@/assets/main_.png" class="logo-img">
        </div>
        <div class="header-nav">
        <div class='header-item' v-if="!user">
            <router-link class="link-item" to="/contact">
                <div class="image-place"></div>
                <div class="text">Contact</div>
            </router-link> 
        </div>
        <div class='header-item' v-if="!user">
            <router-link class="link-item" to="/login">
                <div class="image-place"></div>
                <div class="text">Sign in</div>
            </router-link> 
        </div>
        <Dropdown v-if="user"/>

        </div>
    </header>
</template>

<script>

    import { mapState } from 'vuex';
    import API from '../api/index.js'
    import Dropdown from './userDropDown.vue'
    export default {
        name: 'Header',
        data() {
            return {
                // Define your local data properties here
            }
        },
        components: {
            Dropdown
        },
        computed: {
            ...mapState(['user', 'credits_balance', 'notificationsCount']),
            // Define your computed properties here
        },
        methods: {
            // Define your methods here
            logout() {
                this.$store.commit('setUser', null)
                localStorage.removeItem('token');
                window.location.href = window.location.href;
            },
            downloadSample() {
                API.downloadFile('sample', {name: 'sample.xlsx'})
            }
        },
        mounted() {
            // Load the data when the component is mounted
        }
    }



</script>

<style lang="scss" scoped>
.header-nav {
    padding: 0;
    margin-left: 140px;
    height: 100%;
    display: flex;
}


.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-bottom: 1px solid var(--border-color);
}
.header-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    &.special {
        display: flex;
        align-items: center;
        width: fit-content;
    }
}
.header-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 50px;
}
.header-nav li {
    display: inline-block;
    margin: 0 15px;
    font-size: 12px;
    a {
        padding: 15px 0;
    }
}
.logo-img {
    height: 40px;
    margin-left: 20px;
}
.header-nav a, .header-nav span{
    text-decoration: none;
    color: #091440;
    cursor: pointer;
    font-weight: bolder;
}

.header-nav a:hover, .header-nav span:hover{
    color: #10193e;
}

.section-white {
    text-decoration: none;
    color: #091440; 
    display: inline-block;
    margin: 0 15px;

}
.list-of-links {
    display: flex;
    align-items: center;
}
.greeting {
    margin-left: auto;
    white-space: nowrap;
    font-size: 12px;
}
.logout {
    font-size: 12px;
    font-weight: bolder;
    cursor: pointer;
    &:hover {
        color: #10193e;
    }
}
.register {
    margin-left: auto;
}
.notification-parent[class] {
    position: relative;
    margin-right: 30px;
}
.notification-counter {
    background: #f59f9f;
    border-radius: 50%;
    padding: 5px;
    top: -2px;
    right: -20px;
    position: absolute;
}
</style>
