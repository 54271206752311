<template>
  <div class="contact-view">
    <div class="contact-form">
      <div class="contact-form-header">
        Have a question or need assistance? Fill out the form below, and we’ll get back to you as soon as possible.
      </div>
      <div class="contact-form-body">
          <InpComponent
            label="First & Last Name"
            type="text"
            theme="light"
            :key_ref="'name'"
            :value="messageInfo.name"
            :error="''"
            @inputChange="inputChangeFunc"
          ></InpComponent>

          <InpComponent
            label="Email"
            type="email"
            theme="light"
            :key_ref="'email'"
            :value="messageInfo.email"
            :error="''"
            @inputChange="inputChangeFunc"
            >
          </InpComponent>

          <InpComponent
            label="Company Name"
            type="text"
            theme="light"
            :key_ref="'companyName'"
            :value="messageInfo.companyName"
            :error="''"
            @inputChange="inputChangeFunc"
          ></InpComponent>

          <div class="question-label">Your question</div>
          <textarea v-model="messageInfo.message">

          </textarea>

        <button class="submit-button" @click="sendInfo">Submit</button>
    </div>
  </div>
</div>
</template>

<script setup>
import { ref, computed, onMounted, watch, defineEmits } from 'vue'

import InpComponent from '@/components/InpComponent.vue';
import API from '../api/index.js'
function inputChangeFunc(event) {
  const event_type = event[0];
  const event_value = event[1];
  messageInfo.value[event_type] = event_value;
}

const messageInfo = ref({
  name: '',
  email: '',
  companyName: '',
  message: ''
})

async function sendInfo() {
  console.log('sendInfo', messageInfo.value.name, messageInfo.value.email, messageInfo.value.companyName, messageInfo.value.message)
  if (!messageInfo.value.name.trim() || !messageInfo.value.email.trim() || !messageInfo.value.companyName.trim() || !messageInfo.value.message.trim()) {
    alert('Please fill out all fields')
    return;
  }
  let success = await API.sendContactInfoText({
    name: messageInfo.value.name,
    email: messageInfo.value.email,
    companyName: messageInfo.value.companyName,
    message: messageInfo.value.message
  })
}

</script>

<style scoped lang='scss'>
  .contact-form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid var(--border-color);
    background-color: white;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .question-label {
    width: fit-content;
    background-color: white;
    font-size: 11px;
    position: relative;
    padding: 0 5px;
    bottom: -10px;
    left: 5px;
  }

  textarea {
    width: 95%;
    resize: none;
    height: 150px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    &:focus {
      outline: none;
      border: 1px solid var(--primary-color);
    }
  }
  
  .contact-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
  }
  
  button.submit-button {
    width: 100%;
  }

  .contact-form-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
</style>