import { createRouter, createWebHistory } from 'vue-router'
import ContactView from '../views/ContactView.vue'

import RegisterVue from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import AdminView from '../admin_views/AdminView.vue'
import UsersView from '../admin_views/UsersView.vue'
import DashboardView from '../admin_views/DashboardView.vue'
import UserView from '../admin_views/UserView.vue'
import PlatofrmControlView from '../admin_views/PlatformControlView.vue'
import WalletView from '../views/WalletView.vue'
import VerifyView from '../views/VerifyView.vue'

import KYCView from '../views/KycView.vue'
import ResetPasswordView from '../views/ResetPassword.vue'
import SupportTicket from '../views/SupportView.vue'
import TicketView from '../views/TicketView.vue'
import UserManagementView from '../views/UserManagementView.vue'
import SnapshotView from '../views/SnapshotView.vue'
import SubsidiaryView from '../views/SubsidiaryView.vue'
import TermsPage from '../admin_views/TermsPage.vue'
import TermsUser from '../views/TermsUser.vue'

import userViewNormal from '../views/UserView.vue'
import UserProfile from '../views/UserProfile.vue'
import SanctionsView from '../views/SanctionsView.vue'
import SanctionView from '@/views/SanctionView.vue'

const routes = [

  {
    path: '/profile-settings',
    name: 'profile-settings',
    component: UserProfile
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: SupportTicket
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: UserManagementView,
  },
  {
    path: '/document-history',
    name: 'document-history',
    component: SnapshotView
  },
  {
    path: '/subsidiary',
    name: 'subsidiary',
    component: SubsidiaryView,
  },
  {
    path: '/sanction-screening-lookups',
    name: 'sanction-screening-lookups',
    component: SanctionsView
  },
  {
    path: '/sanction-screening-lookups/:id/',
    name: 'sanction-screening-item',
    props: true,
    component: SanctionView,
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: UserManagementView,
  },
  {
    path: '/user-management/users/:id/',
    name: 'company_users',
    props: true,
    component: userViewNormal,
  },
  {
    path: '/help-center/:id/',
    name: 'single_ticket',
    props: true,
    component: TicketView,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterVue
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ResetPasswordView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: WalletView
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: KYCView
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'users/:id/',
        name: 'bo_user',
        props: true,
        component: UserView,
      },
      {
        path: 'users',
        name: 'bo_users',
        component: UsersView,
      },
      {
        path: 'platform_control',
        name: 'platform_control',
        component: PlatofrmControlView,
      },
      {
        path: 'terms-admin',
        name: 'terms-admin',
        component: TermsPage,
      }
    ]
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: TermsUser
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/convert',
    name: 'convert',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ConverFileView.vue')
  }
]

const router = createRouter({
  mode: 'history',
  base: '/',
  history: createWebHistory(),
  routes
})

export default router
