<template>
    <div class="main-block">
        <div class="verify-section" v-if="response">
            
            <div class="email">
                <img 
                    v-if="response.data && response.data[0]" 
                    src="@/assets/email-ver.svg" alt="close" 
                />
                <img 
                    v-else 
                    src="@/assets/email-error.svg" alt="close" 
                />
            </div>
            <span v-if="response.data && response.data[0]">
                Your email address {{ response.data[0] }} is verified now 
            </span>
            <span v-else>
                There was issue processing your request or link got expired
            </span>
            <button @click="goToHome()">Ok</button>
        </div>
    </div>
  </template>
  
  <script>
  
  
  import API from '@/api/index.js'
  import { mapState } from 'vuex';
  export default {
    data() {
      return {
        verify_response: null,
        response: null
      };
    },
    async mounted() {

        const queryString = window.location.search;
        // Parse the query string
        const urlParams = new URLSearchParams(queryString);
        // Convert URLSearchParams to a dictionary
        const paramsDict = {};
            urlParams.forEach((value, key) => {
            paramsDict[key] = value;
        });

        const resp = await API.verifyToken(paramsDict.token);
        this.verify_response = resp && resp.data;
        this.response = resp;

    },
    computed: {
        ...mapState(['wallet'])
    },
    methods: {
        goToHome() {
            this.$router.push('/user-profile');
        }
    },
  };
  </script>
  
  
  <style scoped>
  .main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: black;
  }
  .verify-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: black;
    width: fit-content;
    height: 180px;
    margin: auto;

    border-radius: 15px;
    border: 1px solid var(--border-color);

    background-color: white;
    padding: 35px;
  }
  .email img {
    width: 90px;
    height: 90px;
  }
  button {
    margin-top: 20px;
    padding: 10px 20px;
    width: 80px;
  }
  </style>