<template>
    <div class="container">
        <div class="header">
            <text> 
                {{ headerText }} info
            </text>
            <div class="cross" @click="closePopup">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="info-holder flex gap-5" >
            <div class="comment-info flex column gap-4">
                <div class="flex column input-row w-full" v-if="showAccountManagerComment && (commentsToShow == 'comment_data_handler' || commentsToShow == 'all')" >
                    <div class="header-of-comments">Account manager comments</div>
                    <div class="input-row flex w-full">
                        <CommentSection 
                            attrId='comment_data_handler' 
                            class="markdown-content"
                            :class="{disabled: accountManagerDisabled}"
                            :disabled="accountManagerDisabled"
                            :commentSection="comment_data_handler_value"
                            @changeinput="quillContent" 
                        />
                    </div>
                </div>
                <div class="flex column input-row w-full" v-if="showSupervisorComment && (commentsToShow == 'comment_data_processor' || commentsToShow == 'all')">
                    <div class="header-of-comments">Supervisor comments</div>
                    <div class="input-row flex w-full">
                        <CommentSection  
                            attrId='comment_data_processor' 
                            class="markdown-content"
                            :class="{disabled: supervisorDisabled}"
                            :disabled="supervisorDisabled"
                            :commentSection="comment_supervisor_value"
                            @changeinput="quillContent"
                        />
                    </div>
                </div>
                <div class="flex column input-row w-full" v-if="showComplienceOfficerComment && (commentsToShow == 'comment_compliance_officer' || commentsToShow == 'all')">
                    <div class="header-of-comments">Compliance officer comments</div>
                    <div class="input-row flex w-full">
                        <CommentSection 
                            @changeinput="quillContent" 
                            attrId='comment_compliance_officer' 
                            :class="{disabled: complienceOfficerDisabled}"
                            :disabled="complienceOfficerDisabled"
                            :commentSection="comment_compliance_officer_value"
                            class="markdown-content"
                        />
                    </div>
                </div>
            </div>
 
            <div class="approval-section flex column" v-if="proposedChangesLocal">
                <div class="flex row" v-for="proposition in proposedChangesLocal">
                    <div class="current-changes">
                        {{ proposition.current }}
                    </div>
                    <div class="new-changes">
                        {{ proposition.new }}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="!objectInfo" class="add-new-ticket-info flex gap-5">
            <button class="transparent cancel-btn" @click="closePopup">Cancel</button>
            <button @click="InfoUpdate()">Save</button>
        </div>
        <div v-else-if="editMode" class="add-new-ticket-info flex gap-5">
            <button class="transparent cancel-btn" @click="closeEditView()">Cancel</button>
            <button @click="InfoEdit()">Save</button>
        </div>
        <div class="add-new-ticket-info view-actions flex gap-5" v-else>
            <button class='transparent' @click="ShowDeletePopup">Delete</button>
            <button @click="ObjectAction">Edit</button>
        </div> -->
        <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
            <ConfirmationPopup 
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="'Delete Subsidiary'"
                :message="'Are you sure you want to delete this subsidiary?'"
            />
        </PopupCover>
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import InpComponent from '@/components/InpComponent.vue';
    import CommentSection from '@/views/CommentSection.vue';
    import PopupCover from '@/components/PopupCover.vue';
    import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
    import API from '@/api/subsidiary';
    import dayjs from 'dayjs';

    const props = defineProps([
        'objectInfo', 
        'headerText',
        'commentsToShow',
        'proposedChanges',
        'comment_data_handler_value',
        'comment_supervisor_value',
        'comment_compliance_officer_value',
    ])
    const proposedChangesLocal = ref(props.proposedChanges);

    const emit = defineEmits([
        'close', 
        'InfoUpdate', 
        'loading', 
        'delete_object',    
    ])
    const store = useStore()
    const router = useRouter()
    const deleteObject = ref(false);
    
    const editMode = ref(false); 

    const user = computed(() => store.state.user);


    const showAccountManagerComment = ref(false);
    const showSupervisorComment = ref(false);
    const showComplienceOfficerComment = ref(false);
    const shownInputsNumber = ref(0);

    const accountManagerDisabled = ref(true);
    const supervisorDisabled = ref(true);
    const complienceOfficerDisabled = ref(true);

    if (user.value && user.value.type == 'DATA_CONTROLLER') {
        showAccountManagerComment.value = true
        accountManagerDisabled.value = false
        shownInputsNumber.value = 1
    }
    if (user.value && user.value.type == 'DATA_PROCESSOR') {
        showAccountManagerComment.value = true
        showSupervisorComment.value = true;
        supervisorDisabled.value = false
        shownInputsNumber.value = 2
    }
    if (['COMPLIANCE_OFFICER', 'EXTERNAL_AUDITOR', 'INTERNAL_AUDITOR', 'TRUST_OFFICER'].includes(user.value.type)) {
        showComplienceOfficerComment.value = true
        showAccountManagerComment.value = true
        showSupervisorComment.value = true
        shownInputsNumber.value = 3
    }
    if (user.value.type == 'COMPLIANCE_OFFICER') {
        complienceOfficerDisabled.value = false
    }

    const yearOptions = computed(() => store.state.yearOptions);
    const object = ref({
        accountComment: '',
        complianceComment: '',
        supervisorComment: '',
    })

    onMounted(() => {
        if (proposedChangesLocal.value && proposedChangesLocal.value.length) {
            for (let proposition of proposedChangesLocal.value) {
                proposition.fileInfo = JSON.parse(proposition.fileInfo)
                proposition.fields = JSON.parse(proposition.fields)
            }
        }
    })
    function quillContent(text) {
        object.value[text.id] = text.structure
        emit('InfoUpdate', {type:text.id, value: text.structure})
    }
    function InfoUpdate() {
        emit('InfoUpdate', {object: object.value})
    }
    function ObjectAction() {
        editMode.value = true;
        object.value.comment = props.objectInfo.comment;
        object.value.name = props.objectInfo.name;
        object.value.last_annual_account_on_file = props.objectInfo.last_annual_account_on_file;
    }
    async function InfoEdit() {
        object.value._id = props.objectInfo._id;
        await API.editSubsidiary(object.value)
        props.objectInfo.comment = object.value.comment;
        props.objectInfo.name = object.value.name;
        props.objectInfo.last_annual_account_on_file = object.value.last_annual_account_on_file;
        editMode.value = false;
        object.value = {
            comment: '',
            last_annual_account_on_file: '',
            name: '',
        }
    }
    function popupConfirm(event){
        deleteObject.value = false;
        if (event) {
            emit('delete_object')
        }
    }
    
    function ShowDeletePopup() {
        deleteObject.value = true;
    }
    function closeEditView(){
        editMode.value = false;
        object.value = {
            comment: '',
            last_annual_account_on_file: '',
            name: '',
        }
    }
    function closePopup(event){
        event.stopPropagation();
        console.log('closePopup AAAAAAAAAAAAAA', event)
        emit('close')
    }
</script>


<style scoped lang="scss">

    .input-row {
        > * {
            flex: 1;
        }
    }
    .info-section-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: self-start;
    }
    .info-section {
        text-align: left;
        line-height: 18px;
    }
    .name {
        font-size: 14px;
        color: rgb(116 119 127);
    }
    .value {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }
    .button-delete {
        align-items: flex-end;
    }
    .header-of-comments {
        text-align: left;
        font-size: 16px;
        color: black;
        font-weight: 600;
        padding-left: 5px;
    }
    .generate-password {
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        height: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
        &:hover {
            cursor: pointer;
            background-color: var(--button-hover-color-blue);
        }

    }
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 1100px;
        max-width: 95vw;
        border: 1px solid var(--border-color);
        max-height: 100vh;
        overflow-y: auto;
        padding: 20px;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        margin-left: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .cross {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:deep(.ql-container){
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &.disabled {
            background-color: #f5f5f5;
            opacity: 0.6;
        }
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }
    .info-section {
        width: 95%;
    }
    .comment {
        padding: 10px;
        background-color: #f5f5f5;
        border-radius: 5px;
        width: 100%;
        min-height: 60px;
        font-weight: 300;
        font-size: 14px;
        color: black;
        opacity: 0.6;
        border: 1px solid var(--border-color);
        &:deep(*) {
            margin: 0;
        }
    }
    .uploader-table {
        flex: 2;
    }
    .comment-info {
        flex: 3;
    }
    .info-holder {
        margin-bottom: 20px;
    }
</style>