<template>
    <div class="container">
        <div class="two_fa_code">Scan QR Code with Google Auth App</div>
        <canvas class="qr-code-area" ref="canvas"></canvas>
        <div class="two_fa_code">Or copy secret: {{ props.twoFaCode }}</div>
    </div>
</template>
<script setup>
    import QRCode from 'qrcode';
    import { ref, onMounted } from 'vue';

    const canvas = ref(null);
  
    const props = defineProps(['twoFaCode', 'email']);
    console.log('props.twoFaCode', props.twoFaCode);
    function generateQRCode() {
      const otpauthUrl = `otpauth://totp/AMLLINK_${props.email}?secret=${props.twoFaCode}`;
      QRCode.toCanvas(canvas.value, otpauthUrl, (error) => {
        if (error) console.error(error);
      });
    }
    onMounted(() => {
        generateQRCode();
    })
</script>
<style scoped lang="scss">
    .qr-code-area {
        width: 150px;
        height: 150px;
    }
    .two_fa_code {
        font-size: 12px;
        text-align: center;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
</style>
  