<template>
    <div class="terms-page">
        <h1>Terms</h1>
        <p>Terms and conditions</p>

        <CommentSection
            v-if="loaded"
            class="comment-section"
            :commentSection="terms.structure"
            @changeinput="quillContent" 
        >

        </CommentSection>
        <div>
            <button class="save" @click="save()">Save</button>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import BO_API from '../api/bo_api.js'
    import API from '../api/index.js'

    import { useStore } from 'vuex';
    import CommentSection from '@/views/CommentSection.vue';

    const store = useStore();
    const terms = ref([]);
    const loaded = ref(false);
    onMounted( async () => {
        const data = await API.getTerms();
        console.log('data', data)
        terms.value = data.terms;
        terms.value.structure = JSON.parse(terms.value.structure);
        loaded.value = true;
    });

    function quillContent(event) {
        terms.value.structure = event.structure;
        terms.value.content = event.text;
        console.log('quillContent', event)
    }
    function save(){
        BO_API.saveTerms(terms.value);
    }

</script>


<style lang="scss" scoped>
    .terms-page {
        min-height: 80vh;
    }
    .comment-section {
        height: 100%;


        &:deep(.editor-section) {
            height: 70vh;
        }
    }
    .save {
        margin-top: 20px;
        width: 100px;
        margin-left: auto;
    }
</style>