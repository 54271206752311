<template>
    <div class="sidebar">
        <div class='sidebar_item no-height' v-if="user && user.is_super_user">
            <router-link class="link-item" to="/admin">
                <div class="image-place">
                    <img src="@/assets/admin.svg" style="margin-right: -3px; margin-left: 3px;">
                </div>
                <div class="text">
                    Admin
                </div>
            </router-link>
            <div>
                <div class='left-nav'>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'bo_users'}"><div class="text">Users</div></router-link>
                    </div>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'dashboard'}"><div class="text">Dashboard</div></router-link>
                    </div>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'platform_control'}"><div class="text">Platform Control</div></router-link>
                    </div>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'terms-admin'}"> <div class="text">Terms</div></router-link>
                    </div>
                </div>
            </div>
        </div>
        <hr v-if="user && user.is_super_user">
        <div class='sidebar_item no-height'>
            <span class="link-item pointer" @click="() => opened = !opened" >
                <img src="@/assets/arrow-opener.svg" class="arrow-opener" :class="opened ?'opened': ''">
                <div class="image-place">
                    <img src="@/assets/kyc.svg">
                </div>
                <div class="text">Compliance</div>
            </span> 
            <div v-if="opened">
                <div class='left-nav'>
                    <div class='sidebar_item' :class="view == 'dashboard' ? 'active': ''" v-if="PermissionChecker.viewDashboardSection()">
                        <router-link class='link-item self-determined' to="/kyc?view=dashboard"><div class="text">Dashboard</div></router-link>
                    </div>
                    <div class='sidebar_item' :class="view == 'corporate' ? 'active': ''" v-if="PermissionChecker.viewGeneralSection()">
                        <router-link class='link-item self-determined' to="/kyc?view=corporate"><div class="text">Corporate</div></router-link>
                    </div>
                    <div class='sidebar_item' :class="view == 'ubo' ? 'active': ''" v-if="PermissionChecker.viewUboSection()">
                        <router-link class='link-item self-determined' to="/kyc?view=ubo"><div class="text">UBO</div></router-link>
                    </div>
                    <div class='sidebar_item' :class="view == 'mbod' ? 'active': ''" v-if="PermissionChecker.viewmbdSection()">
                        <router-link class='link-item self-determined' to="/kyc?view=mbod"> <div class="text">Managing Directors</div></router-link>
                    </div>
                    <div class='sidebar_item' :class="view == 'work-flow' ? 'active': ''" v-if="PermissionChecker.viewRequestLog()">
                        <router-link class='link-item self-determined' to="/kyc?view=work-flow"> 
                            <div class="amount-of-items" v-if="notificationsCount">
                                {{ notificationsCount }}
                            </div>
                            <div class="text">Work Flow</div>
                        </router-link>
                    </div>
                    <div class='sidebar_item' :class="view == 'change-log' ? 'active': ''" v-if="PermissionChecker.viewChangeLog()">
                        <router-link class='link-item self-determined' to="/kyc?view=change-log"> <div class="text">Change Log</div></router-link>
                    </div>
                    <div class='sidebar_item' :class="view == 'comment_section' ? 'active': ''" v-if="PermissionChecker.viewAuditSection()">
                        <router-link class='link-item self-determined' to="/kyc?view=comment_section"> <div class="text">Comment Section</div></router-link>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class='sidebar_item' v-if="PermissionChecker.viewSubsidiaries()">
            <router-link class="link-item" to="/subsidiary">
                <div class="image-place">
                    <img src="@/assets/subsidiaries.svg">
                </div>
                <div class="text">Subsidiaries</div>
            </router-link> 
        </div>
        
        <div class='sidebar_item' v-if="PermissionChecker.viewDocumentHistory()">
            <router-link class="link-item" to="/document-history">
                <div class="image-place">
                    <!-- <img src="@/assets/kyc.svg"> -->
                </div>
                <div class="text">Review History</div>
            </router-link> 
        </div>
        <div class='sidebar_item'>
            <router-link class="link-item" to="/sanction-screening-lookups">
                <div class="image-place">
                    <!-- <img src="@/assets/kyc.svg"> -->
                </div>
                <div class="text">Sanction Screening</div>
            </router-link> 
        </div>
        <hr>
        <div v-if="PermissionChecker.viewUser()">
            <router-link class="link-item" to="/user-management">
                <div class="image-place">
                    <img src="@/assets/person.svg">
                </div>
                <div class="text">User Management</div>
            </router-link> 
        </div>

            <!-- <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/user-info">
                    <div class="image-place">
                        <img src="@/assets/user-info.svg">


                    </div>
                    <div class="text">User Information</div>
                </router-link> 
            </div> -->

            <!-- <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/convert">
                    <div class="image-place">
                        <img src="@/assets/convert.svg" style="margin-left:-3px;">

                    </div>
                    <div class="text">Convert</div>
                </router-link> 
            </div>
            <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/file-list">
                    <div class="image-place"></div>
                    <div class="text">Uploads history</div>
                </router-link> 
            </div> -->
            <!-- <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/saved-info">
                    <div class="image-place"></div>
                    <div class="text">Saved Info</div>
                </router-link> 
            </div> -->
            <!-- <div class='sidebar_item'>
                <span class="link-item" v-if="user && user.type != 'DATA_CONTROLLER'" @click="downloadSample()">
                    <div class="image-place"></div>
                    <div class="text">Download Sample</div>
                </span> 
            </div> -->

        <div class='sidebar_item' v-if="PermissionChecker.viewWallet()">
            <router-link class="link-item" to="/wallet">
                <div class="image-place">
                    <img src="@/assets/wallet.svg" style="margin-left: -4px;">
                </div>
                <div class="text">Wallet</div>
            </router-link> 
        </div>
        <div class='sidebar_item'>
            <router-link class="link-item" to="/terms-and-conditions">
                <div class="image-place">
                </div>
                <div class="text">Terms and Conditions</div>
            </router-link> 
        </div>

        
    </div>
</template>

<script setup>

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import API from '../api/index.js'
import  { PermissionChecker } from '../utils/iam_utils.js'
import { computed, ref } from 'vue';

const store = useStore();
const notificationsCount = computed(() => store.state.notificationsCount);
const user = store.state.user;

const opened = ref('opened');

function downloadSample() {
    API.downloadFile('sample', {name: 'sample.xlsx'})
}

const route = useRoute();
const view = computed(() => route.query.view);
</script>

<style scoped lang="scss">
    .sidebar {
        padding: 20px;
        background-color: white;
        width: var(--sidebar-width);
        position: fixed;
        top: 60px;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        border-right: 0.5px solid var(--border-color);
        z-index: 100;
    }
    .sidebar_item:not(.no-height) {
        height: 40px;
    }
    .text {
        color: rgb(116 119 127);
        font-size: 14px;
    }
    .image-place {
        width: 25px;
        height: 25px;
        padding-bottom: 3px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    
    a.link-item, .link-item {
        height: 40px;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: bold;
        color: var(--sidebar-text-color);
        border-radius: 1.5rem;
        padding-left: 10px;
        &.router-link-exact-active:not(.self-determined) {
            .text {
                color: var(--primary-color); 
            }
            position: relative;
            background-color: rgb(236 240 255);
        }
    }
    .active a[class] {
        .text {
            color: var(--primary-color); 
        }
        position: relative;
        background-color: rgb(236 240 255);
    }
    .left-nav {
        a {
            padding-left: 45px;
        }
    }
    .arrow-opener {
        transition: 0.3s all;
    }
    .opened {
        transform: rotate(90deg);
    }
    .link-item {
        position: relative;
    }
    .amount-of-items {
        background: var(--primary-color);
        color: white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        left: 10px;
    }

</style>