<template>
<div class="w-full h-full input-box flex column items-center justify-center mb-20">
      <Vue2FACodeInput 
        @input="twoFaInput"
        v-model="code"
        class="w-full h-full input-section"
        :autofocus="true" 
        :inputmode="'numeric'"
        />
        <div v-if="twoFaError" class="two-fa-error w-full">{{ twoFaError }}</div>
        <div v-else class='title-info'> 
          Enter 6 digit code from auth app
        </div>
    </div>
</template>
<script setup >

  import { defineEmits, ref, watch } from 'vue';
  import Vue2FACodeInput from '@loltech/vue3-2fa-code-input';

  const emit = defineEmits(["twoFaCodeUpdate", "twoFaCodeInput"]);
  
  const props = defineProps(['twoFaError']);

  const code = ref('');

  function twoFaInput(event){
    code.value = +event.data;
    console.log(event);
    emit("twoFaCodeInput");

  }
  watch(code, () => {
    if (code.value?.length === 6) {
      emit("twoFaCodeUpdate", code.value);
      code.value = '';
    }
  });

</script>
  <style scoped lang="scss">  
  .two-fa-error {
    color: red;
    font-size: 12px;
    text-align: left;

  }
  .title-info {
    text-align: left;
    font-size: 12px;
    width: 100%;
  }
  .input-box {
    width: 100%;
    height: 60px;
  }
  .input-section {
    height: 40px;
  }
  </style>
  