class PermissionChecker {
    constructor() {
        this.permissions = {}
    }
    setPermissions(permissions) {
        this.permissions = permissions;
    }
    generalRule(key){
        if (this.permissions[key] == 'allow') {
            return true;
        }
        return false
    }
    viewUser() {
        const key = 'view_user';
        return this.generalRule(key)
    }
    createUser() {
        const key = 'create_user';
        return this.generalRule(key)

    }
    updateUser() {
        const key = 'update_user';
        return this.generalRule(key)
    }
    deleteUser() {
        const key = 'delete_user';
        return this.generalRule(key)
    }
    changeUserPermissions() {
        const key = 'change_permission_of_user';
        return this.generalRule(key)
    }
    viewUserPermissions() {
        const key = 'view_permission_of_user';
        return this.generalRule(key)
    }
    viewListingService() {
        const key = 'view_listing_service';
        return this.generalRule(key)
    }
    updateListingService() {
        const key = 'update_listing_service';
        return this.generalRule(key)
    }
    viewGeneralSection() {
        const key = 'view_general_section';
        return this.generalRule(key)
    }
    viewDashboardSection() {
        const key = 'view_dashboard_tab';
        return this.generalRule(key)
    }
    updateDashboardSection() {
        const key = 'update_dashboard';
        return this.generalRule(key)
    }
    viewChartSection() {
        const key = 'view_chart';
        return this.generalRule(key)
    }
    verifyReport() {
        const key = 'verify_report';
        return this.generalRule(key)
    }
    uploadFileCorporate() {
        const key = 'upload_general_section';
        return this.generalRule(key)
    }
    deleteCorporate() {
        const key = 'delete_general_section';
        return this.generalRule(key)
    }
    downloadCorporate() {
        const key = 'donwload_general_section';
        return this.generalRule(key)
    }
    viewUboSection() {
        const key = 'view_ubo_section_general';
        return this.generalRule(key)
    }
    createUBOSection() {
        const key = 'create_ubo_section_general';
        return this.generalRule(key)
    }
    updateUBOSection() {
        const key = 'upload_ubo_section_general';
        return this.generalRule(key)
    }
    deleteUBOSection() {
        const key = 'delete_ubo_section_general';
        return this.generalRule(key)
    }
    deleteUBOSectionFiles() {
        const key = 'delete_ubo_section_files';
        return this.generalRule(key)
    }
    downloadUbOSection() {
        const key = 'donwload_ubo_section_general';
        return this.generalRule(key)
    }
    


    //MBD
    viewmbdSection() {
        const key = 'view_mbd_section';
        return this.generalRule(key)
    }
    creatembdSection() {
        const key = 'create_mbd_section';
        return this.generalRule(key)
    }
    updatembdSection() {
        const key = 'upload_file_mbd_section';
        return this.generalRule(key)
    }
    deletembdSection() {
        const key = 'delete_mbd_section';
        return this.generalRule(key)
    }
    deletembdSectionFiles() {
        const key = 'delete_file_mbd_section';
        return this.generalRule(key)
    }
    downloadmbdSection() {
        const key = 'donwload_file_mbd_section';
        return this.generalRule(key)
    }


    //Subsidiaries
    viewSubsidiaries() {
        const key = 'view_subsidiary';
        return this.generalRule(key)
    }
    createSubsidiaries() {
        const key = 'create_subsidiary';
        return this.generalRule(key)
    }
    updateSubsidiaries() {
        const key = 'update_subsidiary';
        return this.generalRule(key)
    }
    deleteSubsidiaries() {
        const key = 'delete_subsidiary';
        return this.generalRule(key)
    }


    viewChangeLog() {
        const key = 'view_change_log';
        return this.generalRule(key)
    }
    viewRequestLog() {
        const key = 'view_requested_changes';
        return this.generalRule(key)
    }
    approveRequestChanges() {
        const key = 'approve_requested_changes';
        return this.generalRule(key)
    }
    rejectRequestChanges() {
        const key = 'reject_requested_changes';
        return this.generalRule(key)
    }
    canViewDetails() {
        const key = 'can_view_details';
        return this.generalRule(key)
    }
    canDownloadFiles() {
        const key = 'can_download_files';
        return this.generalRule(key)
    }

    viewDocumentHistory() {
        const key = 'view_document_history';
        return this.generalRule(key)
    }
    viewWallet() {
        const key = 'view_wallet';
        return this.generalRule(key)
    }
    viewAuditSection() { 
        const key = 'view_audit_section_comment';
        return this.generalRule(key)
    }
    editAuditSection() {
        const key = 'edit_audit_section_comment';
        return this.generalRule(key)
    }

}

function getUserType(type) {
    const types = {
        'DATA_CONTROLLER': 'ACCOUNT MANAGER',
        'DATA_PROCESSOR': 'SUPERVISOR',
        'COMPLIANCE_OFFICER': 'COMPLIANCE OFFICER',
        'TRUST_OFFICER': 'TRUST OFFICER',
        'EXTERNAL_AUDITOR': 'EXTERNAL AUDITOR',
        'INTERNAL_AUDITOR': 'INTERNAL AUDITOR',
        'CLIENT': 'CLIENT',
        'ADMINISTRATOR': 'ADMINISTRATOR',

    }
    return types[type];
}




const permissionsObject = new PermissionChecker();

module.exports = {
    PermissionChecker: permissionsObject,
    getUserType: getUserType
}