import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE + '/api/v1/';

import { PermissionChecker } from '@/utils/iam_utils.js';

import { mapObjects } from './../utils/kyc_utils.js';

import store from '../store';
const instance = axios.create({
    // baseURL: 'http://your-api-base-url.com', // Replace with your API base URL
});

const setAuthToken = (token) => {
    instance.interceptors.request.use((config) => {
        // Add your custom headers here
        config.headers['Authorization'] = token; // Replace with your actual JWT token
      
        return config;
    });
}

if (localStorage.getItem('token')) {
    setAuthToken(localStorage.getItem('token'));
} else {
    store.dispatch('setValue', {key: 'loaded', value: true});
}

const saveUserChanges = async (user) => {
    try {
        const response = await instance.put(`${API_URL}save-user-changes`, user);
        if (response.data.error) {
            store.dispatch('addNotification', {type: 'error', text: response.data.error});
            return response;
        } else if (response.status == 200){
            store.dispatch('addNotification', {type: 'success', text: 'User changes applied successfully'});
        }
        // store.dispatch('setValue', {key: 'user', value: response.data});

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error applying user changes'});
        throw new Error(error);
    }
}

const getFilesList = async () => {
    try {
        const response = await instance.get(`${API_URL}get-files-list`);
        store.dispatch('setValue', {key: 'fileList', value: response.data});
        console.log('response', response);

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error loading user files list'});
        store
        throw new Error(error);
    }
}
const getUserBalance = async () => {
    try {
        const response = await instance.get(`${API_URL}get-balance`);
        store.dispatch('setValue', {key: 'credits_balance', value: response.data.credits_balance});
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user balance'});
        throw new Error(error);
    }
}

const updateSavedData = async (data) => {
    let response = null
    try {
        response = await instance.post(`${API_URL}save-saved-info`, data).catch((error) => {
            console.log('error', error);
            console.log('error.response', error.response)
            console.log('error.response.status', error.response.status)
            if (error.response.status == 400) {
                store.dispatch('addNotification', {type: 'error', text: 'Error updating user information'});
                return {error: error.response.data.error};
            }
        });
        console.log('response', response);
        if (response && response.status == 200) {
            store.dispatch('addNotification', {type: 'success', text: 'Information updated successfully'});
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user information'});
        
        throw new Error(error);
    }
}

const getSavedInfo = async () => {
    try {
        const response = await instance.get(`${API_URL}get-saved-info`);
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error updating user balance'});
        throw new Error(error);
    }
}


const requestWallet = async () => {
    console.log('requestWallet');
    try {
        const response = await instance.post(`${API_URL}allocate-wallet`);
        store.dispatch('setValue', {key: 'wallet', value: {loaded: true, address: response.data.address}});
        console.log('response', response);
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'Error requesting wallet'});
        throw new Error(error);
    }

}

const getUser = async (id = '') => {
    let response = null;
    try {
        response = await instance.get(`${API_URL}get-user?id=${id}`).catch((error) => {
            console.log('error', error);
            if (error.response.status >= 400 && error.response.status < 500) {
                store.dispatch('setValue', {key: 'loaded', value: true});
                if (error.response.status == 500) {
                    store.dispatch('addNotification', {type: 'error', text: 'Error loading user data\n, Plese contact support'});
                }
                
                if (!id) {
                    localStorage.removeItem('token');
                    setAuthToken(null);
                }
                return;
            }
        });
        if (response) {
            if (id) {
                return {
                    ...response.data,
                }
            } else {
                console.log('response', response);
                store.dispatch('setValue', {key: 'user', value: response.data.user});
                store.dispatch('setValue', {key: 'credits_balance', value: response.data.credits_balance});
                store.dispatch('setValue', {key: 'userAddress', value: response.data.userAddress});
                store.dispatch('setValue', {key: 'userPhone', value: response.data.userPhone});
                store.dispatch('setValue', {key: 'userInformation', value: response.data.userInformation});
                store.dispatch('setValue', {key: 'userPermissions', value: response.data.permissions});
                PermissionChecker.setPermissions(response.data.permissions);

                store.dispatch('setValue', { 
                    key: 'wallet', value: { 
                        loaded: true, 
                        address: response.data.wallet 
                    }
                });
                store.dispatch('setValue', {key: 'loaded', value: true});
            }
        }
        return response;
    } catch (error) {
        console.log('response', response)
        store.dispatch('addNotification', {type: 'error', text: 'Error loading user data'});
        throw new Error(error);
    }
}

const register = async ({
    password,
    username, 
    email
}) => {
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}register`, {
            password,
            username,
            email
        }).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
        });
        if (error_) {
            return error_;
        }
        
        setAuthToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        window.location.reload();
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during user registration.\nPlease try again or contact support'});
        throw new Error(error);
    }
};


const verifyTwoFaCode = async (token) => {
    try {
        const response = await instance.post(`${API_URL}user/verify-otp`, {
            token
        }).catch((error) => {
            if (error.response.status == 400) {
                return {};
            }
        })
        return response;
    }
    catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during token verification.\nPlease try again or contact support'});
        throw new Error(error);
    }
}

const verifyToken = async (token) => { 
    try {
        const response = await instance.post(`${API_URL}verify-token`, {
            token
        }).catch((error) => {
            if (error.response.status == 400) {
                return {};
            }
        })
        return response;
    }
    catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during token verification.\nPlease try again or contact support'});
        throw new Error(error);
    }
}

const sendVerificationEmail = async (email) => {
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}send-verification-email`, {
            email
        }).catch((error) => {
            if (error.response.status == 401) {
                return {error: error.response.data.message};
            }
        });
        if (response.status == 200) {
            store.dispatch('addNotification', {type: 'success', text: 'Verification email sent successfully'});
        }
        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error sending verification emaiil.\nPlease try again or contact support'});
        throw new Error(error);
    }

}


const login = async ({
    password,
    email,
    token
}) => {
    console.log('login')
    try {
        let error_ = null;
        const response = await instance.post(`${API_URL}login`, {
            password,
            email,
            token
        }).catch((error) => {
            console.log('error', error)
            if (error.response.data.message) {
                store.dispatch('addNotification', {type: 'error', text: error.response.data.message});
            } else {
                store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
            }
        });

        if (error_) {
            return error_;
        }
        
        setAuthToken(response.data.token);
        return response;
    } catch (error) {
        // store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
    }
};

const newDueDiligenceRequest = async (data) => {
    try {
        const response = await instance.post(`${API_URL}due-diligence`, data).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during due diligence request.\nPlease try again or contact the support'});
        throw new Error(error);
    }
}

const getSansctionScreeningList = async () => {
    try {
        const response = await instance.get(`${API_URL}due-diligence/list`).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during due diligence request.\nPlease try again or contact the support'});
        throw new Error(error);
    }
}

const getSansctionScreeningInfo = async (id) => {
    try {
        const response = await instance.get(`${API_URL}due-diligence/${id}`).catch((error) => {
            if (error.response.status == 401) {
                return error.response.data
            }
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during due diligence request.\nPlease try again or contact the support'});
        throw new Error(error);
    }
} 

const uploadFileGeneral = async (file, url, metadata) => {
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        if (metadata) {
            for (let key in metadata) {
                formData.append(key, metadata[key]);
            }
        }
        console.log('formData', formData)
        let response_info = null
        fetch(`${API_URL}${url}`, {
            method: 'POST',
            body: formData,
            headers
        })
        .then(response => {
            response_info = response
            return response.json()
        })
        .then(data => {

            resolve(data); 
            if (response_info.status == 500) {
                throw Error('Failed to upload the file')
            }
            if (response_info.status == 401) {
                throw Error('Failed to upload the file')
            }
            if (response_info.status == 403) {
                throw Error('Failed to upload the file')
            }
            console.log('File uploaded successfully:', data, response_info.status);
        })
        .catch(error => {
            console.error('Error uploading file:', error);
            store.dispatch('addNotification', {type: 'error', text: 'There was an error uploading the file or file information.\nPlease try again or contact the support'});
            reject(error);
        });
    })
}

const uploadSupportFile = async (file) => {
    const response = await uploadFileGeneral(file, 'support/upload-file')
    return response;
}

const uploadFile = async (file) => {
    const response = await uploadFileGeneral(file, 'upload-file')
    return response;
}

const uploadKYCFile = async (file, metadata) => {
    const response = await uploadFileGeneral(file, 'upload-kyc-file', metadata)
    return response;
}

const downloadSupportFile = async (file) => {
    
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-support-file/?id=${file._id}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => response.blob())
    .then(async (blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `${file.filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}

const downloadFile = async (fileId,selectedFile) => {

    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-file/?id=${fileId}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => response.blob())
    .then(async (blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        let file_name = selectedFile.name.split('.')
        file_name.pop()
        file_name = file_name.join('')

        link.setAttribute('download', `${file_name}.${fileId == 'sample' ? 'xlsx' : 'zip'}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        await getUserBalance()
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}


const denyChanges = async (data) => {
    try {
        const response = await instance.post(`${API_URL}changes/deny`, data).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during request.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during request.\nPlease try again or contact the support'});
        throw new Error(error);
    }

}

const approveChanges = async (data) => {
    try {
        const response = await instance.post(`${API_URL}changes/approve`, data).catch((error) => {
            if (error.response.status == 401) {
                error_ = {error: error.response.data.message};
            }
            store.dispatch('addNotification', {type: 'error', text: `There was an error during request.\nPlease try again or contact the support`});
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during request.\nPlease try again or contact the support'});
        throw new Error(error);
    }
}

const getKYCFilesList = async (id) => {
    try {
        
        store.dispatch('setValue', { key: 'kycDataLoaded', value: false});
        store.dispatch('setValue', { key: 'dataStructure', value: null});
        store.dispatch('setValue', { key: 'companyData', value: null});
        store.dispatch('setValue', { key: 'companyDataInitial', value: null});
        store.dispatch('setValue', { key: 'commentSection', value: null});
        store.dispatch('setValue', { key: 'commentSectionInitial', value: null});

        let error_ = null;
        let path = id ? `get-kyc-files-info?snapshotId=${id}` : 'get-kyc-files-info';

        const response = await instance.get(`${API_URL}${path}`).catch((error) => {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        });

        if (error_) {
            return error_;
        }
        const mappedStructure = mapObjects(response.data.data);
        store.dispatch('setValue', { key: 'objectMap', value: mappedStructure});


        store.dispatch('setValue', { key: 'confirmationNeeded', value: response.data.confirmationNeeded});
        store.dispatch('setValue', { key: 'loaded', value: true});
        store.dispatch('setValue', { key: 'dataStructureStore', value: {
            ...response.data.data,
            textarea: {
                value: response.data.data.textarea.value,
                prev_val: response.data.data.textarea.value
            }
        }});
        store.dispatch('setValue', { key: 'companyData', value: response.data.companyData});
        store.dispatch('setValue', { key: 'companyDataInitial', value: JSON.parse(JSON.stringify(response.data.companyData))});
        store.dispatch('setValue', { key: 'commentSection', value: JSON.parse(response.data.commentSection.comment_structure) });
        store.dispatch('setValue', { key: 'commentSectionInitial', value: JSON.parse(response.data.commentSection.comment_structure) });

        return response;
    } catch (error) {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error during user login.\nPlease try again or contact the support'});
        console.log('error', error.stack)
        throw new Error(error);
    }

}

async function DownloadKYCFile(id, name) {
    await generateViewToken();
    
    const headers = new Headers({
        'Authorization': localStorage.getItem('token'), // Example header for authentication
    });
    fetch(`${API_URL}get-kyc-file/?id=${id}&viewingToken=${store.state.viewingToken}`,{
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        console.log('response', response)
        if (response.status != 200) {
            store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
            return;
        }
        return response.blob()
    })
    .then(async (blob) => {
        if (!blob) {
            return;
        }
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // await getUserBalance()
    })
    .catch(error => {
        store.dispatch('addNotification', {type: 'error', text: 'There was an error downloading the file.\nPlease try again or contact the support'});
        console.error('Error downloading file:', error);
    });
}
async function deleteObject(object_id, type, objectName, structure) {
    console.log('delete ubo', object_id)
    let error_ = null;

    const response = await instance.post(`${API_URL}delete-object`, {
        id: object_id,
        type,
        structure,
    }).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during ${objectName} deletion.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during ${objectName} deletion.\nPlease try again or contact the support`});
        return false;
    } else {
        store.dispatch('addNotification', {type: 'success', text: `Deleted request has been submited successfully`});
    }
    if (error_) {
        return false;
    }
    return true
}



async function uploadComment(content) {
    console.log('uploadComment')
    let error_ = null;

    const response = await instance.post(`${API_URL}comment-upload`, {comment_structure: content}).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response && response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
        return false;
    }
    if (error_) {
        return false;
    }
    return true
}



async function uploadSettings(settings) {
    console.log('uploadSettings')
    let error_ = null;

    const response = await instance.post(`${API_URL}upload-settings`, settings).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
    });
    console.log('response', response)
    if (response.data.status != 'success') {
        store.dispatch('addNotification', {type: 'error', text: `There was an error during company settings update.\nPlease try again or contact the support`});
        return false;
    }
    if (error_) {
        return false;
    }
    return true
}

async function forgotPassword(email) {
    let error_ = null;

    const response = await instance.post(`${API_URL}user/forgot-password-request`, {
        email: email
    }).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error sending email with reset link.\nPlease try again or contact the support`});
    })
    if (response && response.data && response.data.timeout) {
        store.dispatch('addNotification', {type: 'error', text: `You can send new email in ${Math.floor(response.data.timeout)} seconds`});
        return null
    }
    return response.data;
}

async function resetPassword(data) {
    let error_ = null;
    const response = await instance.post(`${API_URL}user/forgot-password-response`, data).catch((error) => {
        return error.response
    })
    return response;
}


async function createSnapshot(data) {
    let error_ = null;
    const response = await instance.post(`${API_URL}snapshot-save`, data).catch((error) => {
        return error.response
    })
    return response.data;
}


async function getUserAccounts() {
    console.log('getUserAccounts')
    let error_ = null;
    const response = await instance.get(`${API_URL}user/accounts`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading users of the company.\nPlease try again or contact the support`});
    })
    store.dispatch('setValue', {key: 'accounts', value: response.data});

    return response.data;
}

async function deleteUser(user_id) {
    let error_ = null;
    const response = await instance.post(`${API_URL}user/account/delete`, {user_id}).catch((error) => {
        return error.response
    })
    return response;
}

async function createNewUser(user){ 
    const response = await instance.post(`${API_URL}user/account/create`, user).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error creating user for the company.\nPlease try again or contact the support`});
    })
    return response.data;
}

async function getNotificationList({
    page=1,
    view='forConfirmation' 
}) {
    const offset = (page - 1) * 50;
    const limit = 50;
    const response = await instance.get(`${API_URL}notifications?offset=${offset}&limit=${limit}&view=${view}`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading notifications.\nPlease try again or contact the support`});
    })
    return response.data;
}

async function markNotificationAsRead(notification_id) {
    const response = await instance.post(`${API_URL}notifications/read`, {notification_id}).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error updating notifications.\nPlease try again or contact the support`});
    })
    return response.data;
}


async function getNotificationCount({
    view = 'forConfirmation'
}) {
    const response = await instance.get(`${API_URL}notifications/count?view=${view}`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading notifications.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('setValue', {key: 'notificationsCount', value: response.data.count});
    console.log('response COUNT', response)

    return response.data;
}

async function generateViewToken() {
    const response = await instance.get(`${API_URL}generate-view/token`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error generating view token.\nPlease try again or contact the support`});
        return null
    })
    store.dispatch('setValue', { key: 'viewingToken', value: response.data.token});
    
    setTimeout(() => {
        store.dispatch('setValue', { key: 'viewingToken', value: null});
    }, 60000)
}

async function createTicket(data) {
    const response = await instance.post(`${API_URL}support/create`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error creating ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}

async function getTickets(data) {
    const response = await instance.get(`${API_URL}support/get/tickets`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading tickets.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function getTicket(tikcetId) {
    const response = await instance.get(`${API_URL}support/get/ticket/${tikcetId}`).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function sendTicketMessage(data) {
    const response = await instance.post(`${API_URL}support/message/create`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error creating ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function updateTicketStatus(data) {
    const response = await instance.post(`${API_URL}support/ticket/update`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }
        store.dispatch('addNotification', {type: 'error', text: `There was an error updating ticket.\nPlease try again or contact the support`});
    })
    return response.data;
}
async function sendContactInfoText(data) {
    const response = await instance.post(`${API_URL}contact-info-text`, data).catch((error) => {
        if (error.response.status == 401) {
            error_ = {error: error.response.data.message};
        }

        store.dispatch('addNotification', {type: 'error', text: `There was an error sending contact info text.\nPlease try again later`});
        return null
    })
    return response.data;
}
async function getSnapshotList(){
    const response = await instance.get(`${API_URL}snapshots/list`).catch((error) => {
        store.dispatch('addNotification', {type: 'error', text: `There was an error loading snapshots.\nPlease try again or contact the support`});
    })
    return response.data;
}
const getTerms = async () => {
    try {
        const response = await instance.get(`${API_URL}get-terms`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading terms', type: 'error'})
        throw new Error(error);

    }
}

const getUserInfo = async (user_id) => {
    try {
        const response = await instance.get(`${API_URL}user/account?id=${user_id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading user info', type: 'error'})
        throw new Error(error);
    }
}
const loadPermissions = async (user_id) => {
    try {
        const response = await instance.get(`${API_URL}user/account/permissions?id=${user_id}`);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading user info', type: 'error'})
        throw new Error(error);
    }
}
const getRoles = async () => {
    try {
        const response = await instance.get(`${API_URL}get-roles`);
        store.dispatch('setValue', {key: 'roles', value: response.data.roles});
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error loading roles', type: 'error'})
        throw new Error(error);
    }
}

const updateUserPermissions = async (data) => {
    try {
        const response = await instance.post(`${API_URL}update-permissions`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error updating permissions', type: 'error'})
        throw new Error(error);
    }
}

const updateUser = async (data) => {
    try {
        const response = await instance.post(`${API_URL}user/account/update`, data);
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error updating user', type: 'error'})
        throw new Error(error);
    }
}
const changePassword = async (data) => {
    let error = null;
    try {
        const response = await instance.post(`${API_URL}user/account/change-password`, data).catch((error) => {
            return error.response
        });
        return response.data;
    } catch (error) {
        store.dispatch('addNotification', {text: 'Error changing password', type: 'error'})
        throw new Error(error);
    }
}

export default {
    updateUserPermissions,
    login,
    updateUser,
    register,
    getUser,
    deleteObject,
    uploadFile,
    downloadFile,
    DownloadKYCFile,
    saveUserChanges,
    requestWallet,
    getUserBalance,
    createNewUser,
    getNotificationList,
    instance,
    API_URL,
    getUserAccounts,
    createSnapshot,
    getSavedInfo,
    updateSavedData,
    getFilesList,
    sendVerificationEmail,
    verifyToken,
    uploadComment,
    uploadKYCFile,
    markNotificationAsRead,
    getKYCFilesList,
    uploadSettings,
    getNotificationCount,
    forgotPassword,
    resetPassword,
    createTicket,
    generateViewToken,
    getTickets,
    getTicket,
    sendTicketMessage,
    updateTicketStatus,
    downloadSupportFile,
    uploadSupportFile,
    sendContactInfoText,
    deleteUser,
    getSnapshotList,
    approveChanges,
    denyChanges,
    getTerms,
    getUserInfo,
    loadPermissions,
    getRoles,
    changePassword,
    verifyTwoFaCode,
    newDueDiligenceRequest,
    getSansctionScreeningList,
    getSansctionScreeningInfo,
};
