<template>
    <div v-if=loaded class='main-container'>
        <div class='section'>
            <div class='title-section'>
                Request Information: 
                <b>
            {{ sanctionRequest.general.surname }}
            {{ sanctionRequest.general.firstname }}
            {{ sanctionRequest.general.dob }}
            {{ sanctionRequest.general.nationality }}
                </b>
            </div>
            
        </div>
     
        <div>
            <div class='person flex column gap-40' v-for="person in sanctioResponse.result">
                <div class='section'>
                    <div class='title-section'>
                        Individual
                    </div>
                    <div class='table-section'>
                        <table>
                            <tbody>
                                <tr v-for="key in Object.keys(person.individual)">
                                    <th>{{key}}:</th>
                                    <td>{{person.individual[key]}}</td>
                                </tr>
                             
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class='section'>
                    <div class='title-section'>
                        Addresses
                    </div>
                    <div v-for="(address, index) in person.addresses">
                        <div class='sub-title'>Address {{index + 1}}</div>
                        <div class='table-section' >
                            <table>
                                <tbody>
                                    <tr v-for="key in Object.keys(address)">
                                        <th>{{key}}:</th>
                                        <td>{{address[key]}}</td>
                                    </tr>
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class='section'>
                    <div class='title-section'>
                        Sanctions
                    </div>
                    <div class='table-section' v-if="getCurrentSanctions(person).length">
                        <table>
                            <tbody>
                                <tr v-for="sanction in getCurrentSanctions(person)">
                                    <th>{{sanction.body}}</th>
                                    <td>Current: {{sanction.current}}</td>
                                    <td>Country Code: {{sanction.countryCode}}</td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                    <div v-else class='not-found-section'>
                        No Sanctions found
                    </div>
                </div>
                <div class='section'>
                    <div class='title-section'>
                        Notes
                    </div>
                    <div class='table-section' v-if="person.notes.length">
                        <table>
                            <tbody>
                                <tr v-for="note in person.notes">
                                    <th>{{note.note}}</th>
                                    <td>Source: {{note.source}}</td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                    <div v-else class='not-found-section'>
                        No Notes found
                    </div>
                </div>
                <div class='section'>
                    <div class='title-section'>
                        Flags
                    </div>
                    <div class='table-section' v-if="getFlags(person).length">
                        <table>
                            <tbody>
                                <tr v-for="flag in getFlags(person)">
                                    <th>{{flag.data.rep}}</th>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                    <div v-else class='not-found-section'>
                        No Flags found
                    </div>
                </div>
                <div class='section'>
                    <div class='title-section'>
                        Articles
                    </div>
                    <div class='table-section' v-if="person.articles.length">
                        <table>
                            <tbody>
                                <tr v-for="acticle in person.articles">
                                    <th class="article-url">{{acticle.url}}</th>
                                    <td>
                                        <div v-for="snippet of acticle.snippets">
                                            <div v-if="snippet.title" class='snippet-title'>{{snippet.title}}</div>

                                            <div>{{snippet.text}}</div>
                                            <div>{{snippet.terms}}</div>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else class='not-found-section'>
                        No Articles found
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showJSON">
            {{sanctioResponse}}
        </div>
        <div @click="showJSON = !showJSON" class='toggle'></div>
    </div>
    <div v-else>
        Loading...
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import { PermissionChecker } from '@/utils/iam_utils';

    import API from '@/api/index.js'


    const props = defineProps(['id'])
    const userId = props.id;


    const sanctionRequest = ref(null);
    const sanctioResponse = ref(null);
    const sanctionInformation = ref(null);
    const loaded = ref(false);
    const showJSON = ref(false);
    const getCurrentSanctions = (person) => {
        return person.sanction_types.filter(sanction => (sanction.current || sanction.found));
    }
    const getFlags = (person) => {
        return person.flags.filter(flag => flag.found);
    }
    onMounted(async () => {
        const response = await API.getSansctionScreeningInfo(userId);
        sanctionInformation.value = response.data;
        sanctioResponse.value = JSON.parse(response.data.response);
        sanctionRequest.value = JSON.parse(response.data.request);
        loaded.value = true;
    })



</script>

<style lang='scss' scoped>
    .main-container {
        padding: 20px;
        padding-top: 60px;
        box-sizing: border-box;
    }
    .response-header {
        text-align: left;
        color: black;
        width: 100%;
        font-size: 1.5rem;
        margin-bottom: 10px;
        padding-left: 5px;
    }
    .snippet-title {
        font-weight: 700;
        font-size: 1rem;
    }
    .article-url {
        vertical-align: baseline;
    }
    .title-section {
        padding-left: 5px;
        color: black;
        text-align: left;
        font-size: 1.5rem;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 10px;
    }
    .not-found-section {
        padding: 10px;
        color: black;
        text-align: left;
        font-size: 1.2rem;
        background-color: white;
        border: 1px solid var(--border-color);
        padding: 10px;
    }
    .sub-title {
        padding-left: 5px;
        margin-top: 5px;

        border-bottom: 1px solid var(--border-color);
        margin-bottom: 5px;
        color: black;
        text-align: left;
        font-size: 1.2rem;

    }
    .table-section {
        background-color: white;
        // border-radius: 5px;
        width: 100%;
        border: 1px solid var(--border-color);
        padding: 10px;
        box-sizing: border-box;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    tr td, tr th {
        border-bottom: 1px solid var(--border-color);
        padding: 10px;
        color: black;
    }
    tr {
        text-align: left;
        &:nth-child(even) {
            background-color: var(--app-background);
        }
    }
    .toggle {
        width: 200px;
        height: 20px;
        background-color: #d4d4d7;
    }
</style>