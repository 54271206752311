<template>
    <div class="terms-page">
        <div class="cross" v-if="withConfirm" @click="closePopup()">
            <img src="@/assets/cross.svg" alt="cross"/>
        </div>
        <div class='content' v-html="terms.text"></div>

        <div v-if="withConfirm">
            <button @click="confirmTC">I Read Terms and Conditions and ready to proceed</button>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import API from '../api/index.js'
    import { useStore } from 'vuex';
    import CommentSection from '@/views/CommentSection.vue';


    const emit = defineEmits(['confirmTC', 'closePopup']);
    const prop = defineProps(['withConfirm']);



    const store = useStore();
    const terms = ref([]);
    const loaded = ref(false);
    onMounted( async () => {
        const data = await API.getTerms();
        console.log('data', data)
        terms.value = data.terms;
        terms.value.structure = JSON.parse(terms.value.structure);
        loaded.value = true;
    });
    function closePopup(){
        emit('closePopup');
    }
    function confirmTC(){
        emit('confirmTC');
    }



</script>


<style lang="scss" scoped>
    .terms-page {
        margin: 70px;
        padding: 30px;
        color: black;
        text-align: left;
        position: relative;
        background: white;
        border: 1px solid var(--border-color);
        height: fit-content;
        border-radius: 10px;

    }
    button {
        width: fit-content;
        margin-left: auto;
    }
    .cross {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
</style>