<template>
    <div class="container">
        <div class="header">
            <text v-if="!userInfo">
                Add new User
            </text>
            <text v-else> 
                Edit User Information
            </text>
            <div class="cross" @click="closePopup()">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="input-row flex gap-10 w-full" >
            <InpComponent 
                label="Username"
                type="text" 
                theme="light"
                light_label="true"
                key_ref="username"
                :value="user.username" 
                @inputChange="changeInput"
            />
        </div>
        <div class="input-row flex gap-10 w-full" >
            <InpComponent
                label="Email"
                theme="light"
                key_ref="email"
                :value="user.email"
                @inputChange="changeInput"
                type="email"
            />
        </div>
        <div class="flex input-row gap-10 w-full">
            <InpComponent 
                v-if="!userInfo"
                label="Role"
                type="options"
                theme="light"
                light_label="true"
                key_ref="type"
                :value="user.type" 
                :options="[
                    { text: 'ACCOUNT MANAGER', value: 'DATA_CONTROLLER'},
                    { text: 'SUPERVISOR', value: 'DATA_PROCESSOR'},
                    { text: 'COMPLIANCE OFFICER', value: 'COMPLIANCE_OFFICER'},
                    { text: 'EXTERNAL AUDITOR', value: 'EXTERNAL_AUDITOR'},
                    { text: 'INTERNAL AUDITOR', value: 'INTERNAL_AUDITOR'},
                    { text: 'ClIENT', value: 'CLIENT'},
                ]"
                @inputChange="changeInput"
            />
        </div>
        <div class="flex input-row gap-10 w-full" v-if="!userInfo">
            <InpComponent
                style="flex: 3;"
                label="Password"
                theme="light"
                key_ref="password"
                :value="user.password"
                @inputChange="changeInput"
                type="password"
            />
            <div class="generate-password" @click="generatePassword">
                Generate Password
            </div>
        </div>
        <div class="add-new-ticket-info flex gap-5">
            <button class="transparent cancel-btn" @click="closePopup()">Cancel</button>
            <button @click="InfoUpdate()">Save</button>
        </div>
      
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'

    import InpComponent from './InpComponent.vue';
    const props = defineProps(['userInfo'])

    const emit = defineEmits(['close', 'InfoUpdate', 'loading', 'delete_person'])
    const store = useStore()
    const router = useRouter()
    const userStore = computed(() => store.state.user);
    const deletePerson = ref(false);
    const user = ref({
        _id: null,
        username: '',
        email: '',
        type: '',
        password: '',
    })
    const content = ref(null);
    const fileList = ref([]);
    
    function InfoUpdate() {
        emit('InfoUpdate', {user: user.value})
    }

    onMounted(() => {
        if (props.userInfo) {
            user.value.username = props.userInfo.username;
            user.value.email = props.userInfo.email;
            user.value.type = props.userInfo.type;
            user.value._id = props.userInfo._id;
            delete user.value.password;
        }

    })

    function changeInput(event) {
        const event_type = event[0];
        const event_value = event[1];
        user.value[event_type] = event_value;
    }
    
    function generatePassword(){
        const length = 14;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let password = "";
        for (let i = 0; i < length; i++) {
            password += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        user.value.password = password;
    }
    function ShowDeletePopup() {
        deletePerson.value = true;
    }
    function closePopup(){
        emit('close')
    }
</script>


<style scoped lang="scss">

    .input-row {
        > * {
            flex: 1;
        }
    }
    .info-section-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: self-start;
    }
    .info-section {
        text-align: left;
        line-height: 18px;
    }
    .name {
        font-size: 14px;
        color: rgb(116 119 127);
    }
    .value {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }
    .button-delete {
        align-items: flex-end;
    }
    .generate-password {
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        height: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
        &:hover {
            cursor: pointer;
            background-color: var(--button-hover-color-blue);
        }

    }
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 415px;
        border: 1px solid var(--border-color);
        min-height: 400px;
        padding: 20px;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        margin-left: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .cross {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }

</style>