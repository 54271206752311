<template>
    <div class="kyc-fields-area mb-40">
        <div class="flex full-width gap-40 mb-40">
            <div class="section flex-1 border-rounded-gray" style="flex: 2">
                <h3>Company Information</h3>
                <button class='mb-20' v-if="PermissionChecker.verifyReport()">Verify report</button>
                <div class="flex gap-40 full-width">
                    <div class="flex column flex-1">

                        <InpComponent
                            :disabled="!canEditInputs"
                            type="text" 
                            theme="light"
                            light_label="true"
                            key_ref="name"
                            :value="companyData?.name" 
                            @inputChange="inputChangeFunc"
                            label="Name"/>
                        <InpComponent
                            :disabled="!canEditInputs"
                            type="text" 
                            theme="light"
                            light_label="true"
                            key_ref="chamber_of_commerce"
                            :value="companyData?.chamber_of_commerce" 
                            @inputChange="inputChangeFunc"
                            label="Chamber of Commerce"/>
                        <InpComponent
                            :disabled="!canEditInputs"
                            type="text" 
                            theme="light"
                            light_label="true"
                            key_ref="activity"
                            :value="companyData?.activity" 
                            @inputChange="inputChangeFunc"
                            label="Activity"
                        />
                        <AddressComp
                            :disabled="!canEditInputs"
                            key="company-address"
                            class="full-width address"
                            :address="localAddress"
                            @addressChange="changeAddress">
                        </AddressComp>
                       
                    </div>
                </div>
            </div>
            <div class="flex column gap-40" style="flex:3">

                <div class="section inner-section flex-1 border-rounded-gray">
                        <h3 class="">Annual Accounts</h3>
                        <div class="flex column">
                            <div class="label-like"> Annual accounts confirm calendar year</div>
                            <div class="flex row gap-10 mb-15" >
                                <div class="flex align-center justify-center">
                                    <input 
                                        :disabled="!canEditInputs"
                                        type="radio" 
                                        id="YES" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="YES" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'YES'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'YES')"
                                    >
                                    <label for="YES">YES</label>
                                </div>
                                <div class="flex">
                                    <input 
                                        :disabled="!canEditInputs"
                                        type="radio" 
                                        id="NO" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="NO" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'NO'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'NO')"
                                    >
                                    <label for="NO">NO</label>
                                </div>
                            </div>
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="options"
                                :options="yearOptions"  
                                theme="light"
                                label="Current fiscal year"
                                key_ref="current_fiscal_year"
                                :value="localCompanyData?.current_fiscal_year" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                v-if="localCompanyData?.annual_account_conform_calendar_year == 'NO'"
                                type="date"
                                theme="light" 
                                label="Fiscal Year runs from"
                                key_ref="fiscal_year_starts_on"
                                :value="localCompanyData?.fiscal_year_starts_on" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                v-if="localCompanyData?.annual_account_conform_calendar_year == 'NO'"
                                type="date"
                                theme="light" 
                                label="Fiscal Year runs up till"
                                key_ref="fiscal_year_ending_on"
                                :value="localCompanyData?.fiscal_year_ending_on" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="options"
                                :options="yearOptions"  
                                theme="light" 
                                label="Last Annual Accounts on file"
                                key_ref="last_annual_account_on_file"
                                :value="localCompanyData?.last_annual_account_on_file" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent
                                :disabled="!canEditInputs"
                                type="date"
                                theme="light" 
                                label="Last General Meeting of Shareholders"
                                key_ref="last_board_meeting"
                                :value="localCompanyData?.last_board_meeting" 
                                @inputChange="inputChangeFunc"
                            />
                    </div>
                </div>

                <div class="section inner-section risk-section flex-1 border-rounded-gray">
                    <h3 class="header">Risk Classification</h3>
                    <div class="flex row gap-10 sections">
                        <div class="flex align-center">
                            <input class='mt-0' :disabled="!canEditInputs" type="radio" id="low" name="risk_level" value="low" :checked="localCompanyData.risk_level == 'low'" @change="handleChange('risk_level', 'low')">
                            <label for="low">LOW</label>
                        </div>
                        <div class="flex align-center">
                            <input class='mt-0' :disabled="!canEditInputs" type="radio" id="medium" name="risk_level" value="medium" :checked="localCompanyData.risk_level == 'medium'" @change="handleChange('risk_level', 'medium')">
                            <label for="medium">MEDIUM</label>
                        </div>
                        <div class="flex align-center">
                            <input class='mt-0' :disabled="!canEditInputs" type="radio" id="high" name="risk_level" value="high" :checked="localCompanyData.risk_level == 'high'" @change="handleChange('risk_level', 'high')">
                            <label for="high">HIGH</label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="flex column gap-40" style="flex: 3">
                

                <div class="section inner-section flex-1 kyc-review-date border-rounded-gray">
                    <h3>Kyc Review</h3>
                    <div class="flex column">
                        <InpComponent
                            :disabled="!canEditInputs"
                            type="date" 
                            theme="light"
                            label="Date previous compliance review"
                            key_ref="previous_review_date"
                            :value="localCompanyData?.previous_review_date" 
                            @inputChange="inputChangeFunc"
                        />
                        <InpComponent
                            :disabled="!canEditInputs && localCompanyData?.next_review_date_type != 'manual'"
                            type="date"  
                            theme="light"
                            :label="`Date proposed compliance review ${localCompanyData?.next_review_date_type != 'manual' ? '(calculated automatically)' : ''}`"
                            key_ref="next_review_date"

                            :value="localCompanyData?.next_review_date" 
                            @inputChange="inputChangeFunc"
                        />
                        <InpComponent
                            :disabled="!canEditInputs"
                            type="date" 
                            theme="light" 
                            label="Date final compliance review"
                            key_ref="date_final_compliance_review"
                            :value="localCompanyData?.date_final_compliance_review" 
                            @inputChange="inputChangeFunc"
                        />
                    </div>
                </div>



                
                <div class="section inner-section flex-1 border-rounded-gray">
                    <h3>Profit Tax</h3>
                    <div class="flex column">
                        <InpComponent
                            :disabled="!canEditInputs"
                            type="options"
                            :options="yearOptions"                   
                            theme="light"
                            label="Last filed Profit Tax on file "
                            key_ref="last_filed_profit_tax_on_file"
                            :value="localCompanyData?.last_filed_profit_tax_on_file" 
                            @inputChange="inputChangeFunc"
                        />
                        <div class="label-like"> Extension Profit Tax</div>

                        <div class="flex row gap-10 mb-15" >
                            <div class="flex align-center justify-center">
                                <input 
                                    :disabled="!canEditInputs"
                                    type="radio" 
                                    id="YES" 
                                    style="margin: 0 5px"
                                    name="extension_profit_tax"
                                    value="YES" 
                                    :checked="localCompanyData?.extension_profit_tax == 'YES'" 
                                    @change="handleChange('extension_profit_tax', 'YES')"
                                >
                                <label for="YES">YES</label>
                            </div>
                            <div class="flex">
                                <input 
                                    :disabled="!canEditInputs"
                                    type="radio" 
                                    id="NO" 
                                    style="margin: 0 5px"
                                    name="extension_profit_tax"
                                    value="NO" 
                                    :checked="localCompanyData?.extension_profit_tax == 'NO'" 
                                    @change="handleChange('extension_profit_tax', 'NO')"
                                >
                                <label for="NO">NO</label>
                            </div>
                        </div>


                        <InpComponent
                            :disabled="!canEditInputs"
                            v-if="localCompanyData?.extension_profit_tax == 'YES'"
                            type="date"
                            theme="light" 
                            label="Extension up till"
                            key_ref="extension_up_till"
                            :value="localCompanyData?.extension_up_till" 
                            @inputChange="inputChangeFunc"
                        />
                    </div>
                </div>
            </div>
        
        </div>
        <div class="flex row gap-40 w-full">
            <ChartComponent
                v-if="PermissionChecker.viewChartSection()"
                :dataStructure="props.dataStructure"
                class="flex justify-center align-center section"
                style="flex: 41;"
            ></ChartComponent>

            <div class="section inner-section border-rounded-gray h-fit"
                style="flex: 21;"
            >
                <div class="flex column">

                    <div class="label-like"> Allow sanction screaning via third party API</div>

                    <div class="flex row gap-10 mb-15" >
                        <div class="flex align-center justify-center">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="YES" 
                                style="margin: 0 5px"
                                name="sanction_screening_available"
                                value="YES" 
                                :checked="localCompanyData?.sanction_screening_available == 'YES'" 
                                @change="handleChange('sanction_screening_available', 'YES')"
                            >
                            <label for="YES">YES</label>
                        </div>
                        <div class="flex">
                            <input 
                                :disabled="!canEditInputs"
                                type="radio" 
                                id="NO" 
                                style="margin: 0 5px"
                                name="sanction_screening_available"
                                value="NO" 
                                :checked="localCompanyData?.sanction_screening_available == 'NO'" 
                                @change="handleChange('sanction_screening_available', 'NO')"
                            >
                            <label for="NO">NO</label>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import InpComponent from '@/components/InpComponent.vue'
import Subsidiaries from '@/components/Subsidiaries.vue'
import DataSpanshot from '@/components/DataSpanshot.vue'
import { PermissionChecker } from '@/utils/iam_utils'
import CompanyAcounts from '@/components/CompanyAcounts.vue'
import AddressComp from '@/components/AddressComp.vue'
import { ref, defineEmits, onMounted, computed } from 'vue' 

import ChartComponent from '@/components/ChartComponent.vue'

import API from '@/api/index.js'
import store from '@/store'

const emit = defineEmits(['changeCompanyData'])

const yearOptions = computed(() => store.state.yearOptions);

const props = defineProps(['companyData','dataStructure', 'yearLabel']);
const localCompanyData = ref(props.companyData);
const canEditInputs = computed(() => PermissionChecker.updateDashboardSection())

function changeAddress(event) {

    localAddress.value[event.property] = event.value;
    localCompanyData.value[event.property] = event.value;
    emit('changeCompanyData', localCompanyData)

}

const localAddress = ref({
    address_line_1: {
        value: props.companyData.address_line_1,
        new_value: '',
        error: ''
    },
    address_line_2: {
        value: props.companyData.address_line_2,
        new_value: '',
        error: ''
    },
    address_country: {
        value: props.companyData.address_country,
        new_value: '',
        error: ''
    },
    city: {
        value: props.companyData.city,
        new_value: '',
        error: ''
    },
    address_country: {
        value: props.companyData.address_country,
        new_value: '',
        error: ''
    },
    zip: {
        value: props.companyData.zip,
        new_value: '',
        error: ''
    },
})


function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    
    localCompanyData.value[event_type] = event_value;

    emit('changeCompanyData', localCompanyData)
}


function handleChange(field, event) {
    if (field === 'risk_level') {
        localCompanyData.value.risk_level = event
    } 
    localCompanyData.value[field] = event
    emit('changeCompanyData', localCompanyData)
}

</script>

<style lang="scss" scoped>

    .table-users {
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid #e6eaed;
    }
    .header {
        margin-bottom: 0;
    }
    .risk-section {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .section {
        background: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border: 1px solid var(--border-color);
    }
    .sections {
        padding-top: 10px;
    }
    .kyc-fields-area {
        // border: 1px solid #e0e0e0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        // background-color: white;
        box-sizing: border-box;

        border-radius: 5px;
        color: black;
    }

    h3 {
        text-align: left;
    }

    td {
        padding: 10px 30px;
        text-align: left;
    }
    th {
        text-align: left;
        padding: 10px 30px;
        background: #F3F5F6;
    }

    .address[class] {
        max-width: unset;
        
    }
    .border-rounded-gray {
        border: 1px solid var(--border-color);
        h3 {
            text-align: left;
            margin-top: 0;
        }
    }
  
    .seciont-info {
        background-color: white;
        padding: 20px;
        border: 1px solid var(--border-color);
        border-radius: 5px;
    }
    
</style>