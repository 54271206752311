<template>
<div class="main-wrap">
    <div
        class="drop-area"
        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
        @drop.prevent="dropFile"
        @click="$refs.fileInput.click()"
        :class="{ 'drag-over': isDragOver, 'full-width': fullWidth, 'long-header' : headerText && headerText.length > 50 }"
        >
        <span v-if="headerText" class="header" :class="{'long' : headerText && headerText.length > 50}">
            <i>{{ headerText }}</i>
        </span>

        <div class="more-actions" v-if="filePresentLocal">
            <svg data-v-a5ea9760="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" ssr="true" class=" iconify iconify--cosmos" width="0.29em" height="1em" viewBox="0 0 4 14"><g fill="none"><path d="M3.16683 1.49998C3.16683 2.14431 2.6445 2.66665 2.00016 2.66665C1.35583 2.66665 0.833496 2.14431 0.833496 1.49998C0.833496 0.855647 1.35583 0.333313 2.00016 0.333313C2.6445 0.333313 3.16683 0.855647 3.16683 1.49998Z" fill="#74777F"></path>
            <path d="M3.16683 6.99998C3.16683 7.64431 2.6445 8.16665 2.00016 8.16665C1.35583 8.16665 0.833496 7.64431 0.833496 6.99998C0.833496 6.35565 1.35583 5.83331 2.00016 5.83331C2.6445 5.83331 3.16683 6.35565 3.16683 6.99998Z" fill="#74777F"></path>
            <path d="M3.16683 12.5C3.16683 13.1443 2.6445 13.6666 2.00016 13.6666C1.35583 13.6666 0.833496 13.1443 0.833496 12.5C0.833496 11.8556 1.35583 11.3333 2.00016 11.3333C2.6445 11.3333 3.16683 11.8556 3.16683 12.5Z" fill="#74777F"></path></g></svg>
            <div class="dropdown">
                <div >Open File</div>
                <div @click="Download">Download</div>
                <div @click="DeleteFile">DeleteFile</div>

            </div>    
        </div>
        <div class='upload-section' v-if="filePresentLocal">
        <img src="@/assets/upload.svg" class="image-upload"/>
        <span> Attached file: <b><i>{{ filePresentLocal.filename }}</i></b></span>
        </div>
        <div class="upload-section" v-else-if="!selectedFile">

        <img src="@/assets/upload.svg" class="image-upload"/>
        <span class="instruction">Drag and drop file here to upload</span>
        <span class="instruction-2">Max file size is 10MB</span>
        <button class="flex justify-center transparent button">
            <img src="@/assets/plus.svg" class="plus"/>
            Attach File
        </button>
        </div>
        <p v-else>Selected File: {{ selectedFile.name }}</p>
        <input type="file" ref="fileInput" accept=".pdf,.png" style="display: none" multiple @change="handleFileChange" />
    </div>
    <div class="filelist" v-if="Object.keys(fileList).length > 0">
        <div class='file-item' v-for="fileKey of Object.keys(fileList)">
            <div class="file">
                <img src="@/assets/file.svg" class='fileicon'>
                <span class="text-section">{{ fileKey }}</span>
            </div> 
            <img src="@/assets/cross.svg" class='cross pointer' @click="removeFile(fileKey)">
        </div> 
    </div>
</div>
</template>


<script setup>
import { ref, defineEmits, computed, withDefaults } from 'vue'
import InpComponent from '@/components/InpComponent.vue'
import { useStore } from 'vuex';
import API from '../api/index.js'

const store = useStore()
const fileList = ref({});
const isDragOver = ref(false)

const user = computed(() => store.state.user);
const viewingToken = computed(() => store.state.viewingToken);

const emit = defineEmits(['sendFileInfo']);

const selectedFile = ref(null);

const MAX_FILE_SIZE = 10485760;
function handleFileChange(event) {
    const files = event.target.files || event.dataTransfer.files;
    for(let file of files) {
        if (file.size > MAX_FILE_SIZE ) {
            store.dispatch('addNotification', {type: 'error', text: `${file.name} is to big, max file size is 10MB`})
        }
        fileList.value[file.name] = file
    }
    emit("sendFileInfo", Object.values(fileList.value))
}

function dragOver() {
    isDragOver.value = true;
}

function dragLeave() {
    isDragOver.value = false;
}
function dropFile(event) {
    isDragOver.value = false;
    handleFileChange(event);
}
function removeFile(filename) {
    console.log('filename', filename)
    console.log('fileList', fileList.value)
    console.log('fileList[filename]', fileList.value[filename])
    console.log('fileList.value[filename].size', fileList.value[filename].size)
    delete fileList.value[filename]
}

</script>



<style scoped lang="scss">


.file-item {
    padding: 5px 10px;
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    gap: 10px;
    border: 1px solid var(--border-color)
}
.text-section {
    max-width: 158px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.file {
    display: flex;
    align-items: center;
    gap: 5px;
}

.image-upload {
    width: 50px;
    height: 50px;
  }
.filelist {
    margin-top: 20px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
}

.button {

    width: fit-content;
    color: var(--primary-color);
    
    img {
        width: 10px;
        height: 10px;
        margin-right: 5px;
    }
}
.instruction {
    color: rgb(24 28 34);
    font-size: 1rem;
}
.instruction-2 {

}
.drop-area {
    position: relative;
    height: 200px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8997A5;
    border-radius: 5px;
    border-radius: .5rem;
    border-style: dashed;
    border-width: 2px;
    border-color: rgb(224 226 236);
    cursor: pointer;
    transition: all 0.3s;
   
    &:hover {
        border-color: var(--primary-color);
        font-size: 15.5px;
        .header {
            border-color: var(--primary-color);

        }
    }
}
.main-wrap {
    height: fit-content;
}
.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.cross {
    margin-left: auto;
    width: 10px;
    height: 10px;
}



</style>