<template>
    <div class="subsidiary-container">
    <div class="subsidiary-content">
        <div class="sub-header" >
            <h4 class="subsidiary-title">Reviews History list</h4>
            <button @click="addObject()">Create version</button>
        </div>
        <table class="subsidiary-list">
            <thead>
                <tr>
                    <th>Name (label)</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody class='table-content' v-if="store.state.labelYear">
                <tr class="account-list" v-for="snapshot in store.state.labelYear" :key="snapshot._id" @click="openObject(snapshot)">
                    <td class="user-info"> 
                        <div class="icon-person">
                            <img src="@/assets/history.svg" alt="kyc" />
                        </div>
                        <div class="user-name">
                            {{ snapshot.label }}
                        </div>
                    </td>
                    <td>{{ dayjs(snapshot.snapshotDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
                </tr>
            </tbody>
         </table>
     </div>
 
    
     <PopupCover v-if="showPopups" @close="closePopup">
        <SnapshotPopup 
            @close="closePopup" 
            :objectInfo="objectInformation"
            @InfoUpdate="createObjectPopupHandler"
            @objectAction="objectActionHandler"
            @delete_object="deleteObjectHandler"
        />
    </PopupCover>
 </div>
 </template>
 
 <script setup>

import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import API from '../api/index'
import store from '@/store/index.js';
import PopupCover from '@/components/PopupCover.vue';
import SnapshotPopup from '@/components/SnapshotPopup.vue';
import dayjs from 'dayjs'
 

import { useRouter, useRoute } from 'vue-router'

const router = useRouter();

const user = computed(() => store.state.user);
const emit = defineEmits(['delete_person', 'commentChange'])
const objectInformation = ref(null);
const showPopups = ref(false);


async function createObjectPopupHandler(event) {
    showPopups.value = false;
    const response = await API.createSnapshot(event.object);
    store.state.labelYear.unshift(response.snapshot)
}
async function objectActionHandler() {
    showPopups.value = false;

    store.dispatch('setValue', {key: 'selectedSnapshot', value: objectInformation.value});
    router.push('/kyc')
}

function closePopup() {
    showPopups.value = false;
    objectInformation.value = null;
}
async function deleteObjectHandler() {
    showPopups.value = false;
    const result = await API.deleteObject(objectInformation.value._id, 'revision', 'Document Revision');
    if (result) {
        store.state.labelYear = store.state.labelYear.filter(object => object._id !== objectInformation.value._id)
        store.dispatch('addNotification', {text: 'Document Revision has been deleted successfully', type: 'success'})
    }
    objectInformation.value = null;
}
function openObject(object) {
    objectInformation.value = object;
    showPopups.value = true;
}

function addObject() {
    showPopups.value = true;
}

onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
});

</script>
 
<style scoped lang="scss">
    
    .icon-person {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        height: 30px;
        color: var(--primary-color);
        background: rgb(236, 240, 255);
    }
    .user-info {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .subsidiary-container {
        width: 100%;
    }
    
    .subsidiary-content {
        min-width: 100%;
        border: 1px solid #ccc;
        border-radius: 10px;
        width: fit-content;
    }
    
    .sub-header {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 20px;
        padding-left: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
    }
    
    .subsidiary-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 20px;
    }
    
    .sub-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;
        }
    }
    .subsidiary-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            // border: 1px solid #ccc;
            padding: 15px;
            text-align: left;
            border: none;
            padding-left: 30px;
            color: black;
        }
        td {
            padding-top: 19px;
            padding-bottom: 19px;
        }
        th {
            text-align: left;
            border: none;
        }
        tr {
            border-top: 1px solid #ccc;
        }
        tbody {
            tr {
                cursor: pointer;
                td {
                    background: white;
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
                &:hover {
                    td {
                        background: #f9f9f9;
                    }
                }
            }
        }
    }
    
    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;
    
    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    h4 {
        margin: 0;
    }
    .add-new-subsidiary-area {
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
 
 .more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    width: 35px;
    height: 25px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
     cursor: pointer;
     &:hover {
         background: #d9d9d9;
 
         .dropdown {
             display: flex;
             flex-direction: column;
 
         }
     }
     position: relative;
 
     .dropdown {
        display: none;
        position: absolute;
        top: 35px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
     }
 }
 
 </style>































<!-- 


<template>
    <div class="table-container">
    <div class="table-content">
        <div class="table-header" >
            <h4 class="table-title"> Snapshot list</h4>
            <button @click="createSnapshot()">Create Snapshot</button>
        </div>
        <table class="table-list">
            <thead>
                <tr style="background-color: #f9f9f9;">
                    <th>Snapshot Name (label)</th>
                    <th>Date</th>
                    <th></th>
                </tr>
            </thead>

            <tbody class='table-content' v-if="store.state.labelYear">
                <tr class='add-new' v-if="createSnapshotOn">
                    <td class="new">
                        <InpComponent
                            label=""
                            type="text"
                            class="text-input"
                            placeholder=""
                            light_label="true"
                            theme="light"
                            :key_ref="'label'"
                            :value="newSnapshot.label"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                    </td>
                    <td class="add-new-tableitem-area">
                        
                    </td>
                    <td>
                        <button class='save-button' @click="save()">Save</button>
                    </td>
                </tr>
                <tr v-for="snapshot in store.state.labelYear" :key="snapshot._id">
                    <td v-if="editElement !== snapshot._id">{{ snapshot.label }}</td>
                    <td class="new" v-else>
                        <InpComponent
                            label=""
                            type="text"
                            placeholder=""
                            light_label="true"
                            class="text-input"
                            theme="light"
                            :key_ref="'label'"
                            :value="snapshot.label"
                            @inputChange="inputChangeFunc"
                        ></InpComponent>
                    </td>
                    <td>{{ dayjs(snapshot.snapshotDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
                    <td >
                        <div class="more-actions" v-if="editElement !== snapshot._id">
                            <svg data-v-a5ea9760="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" ssr="true" class=" iconify iconify--cosmos" width="0.29em" height="1em" viewBox="0 0 4 14"><g fill="none"><path d="M3.16683 1.49998C3.16683 2.14431 2.6445 2.66665 2.00016 2.66665C1.35583 2.66665 0.833496 2.14431 0.833496 1.49998C0.833496 0.855647 1.35583 0.333313 2.00016 0.333313C2.6445 0.333313 3.16683 0.855647 3.16683 1.49998Z" fill="#74777F"></path>
                            <path d="M3.16683 6.99998C3.16683 7.64431 2.6445 8.16665 2.00016 8.16665C1.35583 8.16665 0.833496 7.64431 0.833496 6.99998C0.833496 6.35565 1.35583 5.83331 2.00016 5.83331C2.6445 5.83331 3.16683 6.35565 3.16683 6.99998Z" fill="#74777F"></path>
                            <path d="M3.16683 12.5C3.16683 13.1443 2.6445 13.6666 2.00016 13.6666C1.35583 13.6666 0.833496 13.1443 0.833496 12.5C0.833496 11.8556 1.35583 11.3333 2.00016 11.3333C2.6445 11.3333 3.16683 11.8556 3.16683 12.5Z" fill="#74777F"></path></g></svg>
                            <div class="dropdown">
                                <div @click="editElementFunc(snapshot)">Edit</div>
                                <div @click="deleteElement(snapshot._id)">Delete</div>
                                <div @click="selectSnapshot(snapshot._id)">Select To View</div>

                            </div>    
                        </div>
                        <button class='save-button' v-else @click="confirmEdit()">Save</button>
                    </td>
                </tr>
            </tbody>
        </table>
    
    </div>
    </div>
</template>

<script setup>

import dayjs from 'dayjs'
import { ref, defineEmits, computed, withDefaults, defineProps, watch, onMounted } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import API from '../api/index'
import store from '@/store/index.js';


const newSnapshot = ref({
    label: '',
})


const editElement = ref(null);

const emit = defineEmits(['handleStructureUpdates', 'delete_person', 'commentChange'])

const subsidiaries = ref(store.state.labelYear)
const createSnapshotOn = ref(false);

function createSnapshot() {
    editElement.value = null;
    newSnapshot.value = {
        label: '',

    }
    createSnapshotOn.value = true;
    console.log('createSnapshot')
}

function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    newSnapshot.value[event_type] = event_value;
}

function editElementFunc(element) {
    createSnapshotOn.value = false;
    editElement.value = element._id;
    newSnapshot.value = {
        label: element.label,
        last_annual_account_on_file: element.last_annual_account_on_file
    }
    console.log('editElement', element._id)
}

async function deleteElement(id) {
    console.log('deleteElement', id)
    await API.deleteSubsidiary(id);
    store.state.labelYear = store.state.labelYear.filter(subsidiary => subsidiary._id !== id)
}
async function selectSnapshot(id) {
    console.log('selectSnapshot', id)    
    store.dispatch('setValue', {key: 'selectedSnapshot', value: id});
    await API.getKYCFilesList(id);

}
async function save() {
    const addedSubsidiary = await API.createSnapshot(newSnapshot.value);
    store.state.labelYear.unshift(addedSubsidiary.data.snapshot)

    console.log('addedSubsidiary')

    newSnapshot.value = {
        label: '',
    }
    createSnapshotOn.value = false;
}
async function confirmEdit() {
    const updatedSubsidiary = await API.editSubsidiary({id: editElement.value, label: newSnapshot.value.label});
    
    
    store.state.labelYear = store.state.labelYear.map(subsidiary => {
        if (subsidiary._id === editElement.value) {
            return { ...subsidiary, label: newSnapshot.value.label }
        }
        return subsidiary
    })
    editElement.value = null;
    newSnapshot.value = {
        label: '',
    }
}


</script>

<style scoped lang="scss">


.table-container {
    width: 100%;
    max-width: 1000px;
}
.table-content {
    width: 100%;
    max-width: 1000px;
    border: 1px solid #ccc;
    border-radius: 20px;

}
.table-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-title {
    display: flex;
    justify-content: space-between;
 
}
.table-header {
    display: flex;
    justify-content: space-between;
    button {
        width: fit-content;
        padding: 10px;
    }
}
.table-list {
    padding: 20px;
    width: 100%;
    border-collapse: collapse;
    th, td {
        // border: 1px solid #ccc;
        padding: 10px;
    td {
        border: none;
    }
    }
    th {
        border: none;
        tr {
            border: none;
        }
    }
    tr {
        border-top: 1px solid #ccc;
    }

}

.add-new td{
    padding-top: 0px;
    padding-bottom: 0px;

}
.save-button {
    padding: 10px 20px;
    width: fit-content;
    margin-left: auto;
}
h4 {
    margin: 0;
}
.add-new-tableitem-area {
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.text-input {
    &:deep(input) {
        text-align: center;
    }
}
.table-header {
    border-bottom: 1px solid #ccc;
}

.more-actions {
    padding: 5px 0px;
    border-radius: 4px;
    max-width: 40px;
    width: 30px;
    margin-left: auto;
    cursor: pointer;
    &:hover {
        background: #d9d9d9;

        .dropdown {
            display: flex;
            flex-direction: column;

        }
    }
    position: relative;

    .dropdown {
        display: none;
        position: absolute;
        top: 30px;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        z-index: 100;
        width: 130px;
        div {
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #d9d9d9;
            }
        }
    }
}

</style> -->