<template>
    <div class="notification-view">
        <div class='notification-container' v-if="loaded && confirmationNeeded.length">
            
            <NotificationItem 
                v-for="notification in confirmationNeeded" 
                :key="'notification' + notification.datetime" 
                @click="openCase(notification)"
                :notification="notification"
                :view="view"
                >
            </NotificationItem>
        </div>
        <div v-else-if="!loaded">
            Loading...
        </div>
        <div v-else>
            No items 
        </div>
        <PopupCover v-if="reviewAction" @close="close()">
            <ActionNeeded
                @deleteConfirm="deleteConfirm()"
                :reviewAction="reviewAction"
                :view="view"
                @close="close()" 
            ></ActionNeeded>
        </PopupCover>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, defineEmits } from 'vue'

const emit = defineEmits(['updatingURL'])

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

import { PermissionChecker } from '@/utils/iam_utils';
import ActionNeeded from '@/dialogs/ActionNeeded.vue'
import PopupCover from '@/components/PopupCover.vue'
import NotificationItem from '@/components/NotificationItem.vue'
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import Utils from '@/utils/index.js'
import store from '../store';
import { mapState } from 'vuex';
import { VuejsPaginate } from 'vuejs-paginate';

import API from '../api/index.js'


const router = useRouter()
const props = defineProps(['view'])
const reviewAction = ref(null);
const loaded = ref(false);

const confirmationNeeded = computed(() => store.state.confirmationNeeded);
const notifications = ref([])
const notificationsCountTotal = ref(null);
const currentPage = ref(1);
onMounted(async() => {
    const response = await API.getNotificationList({page:currentPage.value, view: props.view});
    if (props.view == 'forConfirmation') {
        await API.getNotificationCount({view: props.view});
    }
    store.state.confirmationNeeded = response.notifications;
    notifications.value = response.notifications
    notificationsCountTotal.value = response.count
    loaded.value = true;
});

function deleteConfirm() {
    notifications.value = notifications.value.filter(notification => notification._id != reviewAction.value._id);
    reviewAction.value = null;
}

function close() {
    reviewAction.value = null;
}

function openCase(notification) {
    if (PermissionChecker.canViewDetails()) {
        reviewAction.value = notification;
    } else {
        store.dispatch('addNotification', {text: 'You do not have a permission to view details of this section', type: 'error'});
    }
}

</script>

<style scoped lang="scss">


.notification-view {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white;
    margin: auto;
    padding: 20px;
}
.notification-item {
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}
.notification-container {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white; 
}
.user-name {
    font-weight: bold;
    color: #595b61;
}
.notification-time {
    color: #74777F;
    font-size: 12px;
    display: flex;
    gap: 5px;
}
.notification-message {
    text-align: left;
    color: #181C22;
    font-size: 16px;
}
.notiifcation-info-conteiner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.time-relative {
    width: 100px;
    padding-right: 0;
}
.table-users {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #e6eaed;
}
.is-read-false {
    background: #f9f9f9;
}
td {
    padding: 10px 30px;
    text-align: left;
}
th {
    text-align: left;
    padding: 10px 30px;
    background: #E6EAED;
}
.preview {
    padding: 10px;
    cursor: pointer;
    width: 30px;
}

.icon {
    background-color: gray;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224 226 236);
    position: relative;
    &.is-read-false {
        &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 10px;
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: rgb(224 226 236);
    
        }

    }
}
.change-item {
    padding: 2px 12px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 3px;
    width: fit-content;
}
.pagination {
    display: flex;
    gap: 10px;

  &:deep(li) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

  }
  &:deep(.paginate-buttons) {
    height: 40px;

    width: 40px;
   
    border-radius: 20px;

    cursor: pointer;

    background-color: rgb(242, 242, 242);

    border: 1px solid rgb(217, 217, 217);

    color: black;
  }

  &:deep(.paginate-buttons:hover) {
    background-color: #d8d8d8;
  }

  &:deep(.active-page) {
    background-color: var(--primary-color);

    border: 1px solid var(--primary-color);

    color: white;
  }

  &:deep(.active-page:hover) {
    background-color: var(--primary-color);
  }
}

.notificaiton-read {
    width: 100%;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
}
</style>