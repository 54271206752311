<template>
    <div class='main'>
        <div class='main-area'>
            <router-view></router-view>
        </div>
    </div>
</template>


<script>


import AdminTable from '@/components/AdminTable.vue'

import { mapState } from 'vuex';
import BO_API from '../api/bo_api.js'
export default {
    name: 'AdminView',
    components: {
        AdminTable,
    },
    data() {
        return {};
    },
    mounted(){
        if (!this.user) {
            this.$router.push('/login')
        }
    },
    methods: {

    },
    computed: {
        ...mapState(['user']),
    },
    watch: {
        user: function (val) {
        if (!val) {
            this.$router.push('/login')
        }
        },
    }
};
</script>

<style scoped lang="scss">
    .main {
        width: calc(100vw - 250px);
        height: 100vh;
        display: flex;
        background: white;
    }
    .left-nav {
        padding: 20px;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-right: 1px solid gray;
    }
    .route-link {
        padding: 10px;
        text-decoration: none;
    }
    .main-area {
        height: calc(100vh - 61px);
        padding: 20px;
        box-sizing: border-box;
        flex: 5;
        overflow-y: auto;
    }
    .date-selectors {
        display: flex;
        align-items: center;
        // justify-content: center;
        gap: 10px;
    }
</style>
