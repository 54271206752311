<template>
  <div class="register">
    <div class="register-wrap border-section" v-if="!responseState.challenge">
      <div class="title flex" >
        
        <text v-if="state == 'login'">Log in to your account</text>
        <text v-else>Reset Password</text>
      
      </div>
      <form @submit.prevent="saveData" v-if="state == 'login'">
        <InpComponent 
          v-model="email" 
          label="Email" 
          theme="light"
          type="text" 
          key_ref="email"
          @inputChange="inputChangeFunc"
          :error="emailError"
        />
        <InpComponent 
          v-model="email" 
          label="Password" 
          theme="light"
          type="password"
          key_ref="password"
          @inputChange="inputChangeFunc"
          :error="passwordError"
        />
        <div class='forgot-password main-color' @click="stateChange('forgot')">Forgot Password?</div>
        <button class='button main-color-bg' type="submit" @click="login()">Log in</button>
        <router-link to="/register" class='login main-color'>Register</router-link>

      </form>
      <form @submit.prevent="sendEmail" v-else>
        <InpComponent 
          v-model="email" 
          label="Email" 
          theme="light"
          type="emial"
          key_ref="emial"
          @inputChange="inputChangeFunc"
          :error="emailError"
        />
        <div class="flex flex-row gap-10">
          <button class='button main-color-bg' @click="stateChange('login')">Back To Login</button>
          <button class='button main-color-bg' type="submit">Send</button>
        </div>
      </form>

    </div>
    <div class="register-wrap border-section" v-if="['set_up_two_fa', 'verify_two_fa'].includes(responseState.challenge)">
      <div class="title flex" >
        <text v-if="responseState.challenge == 'set_up_two_fa'">Set up TwoFactor Authentication</text>
        <text v-else-if="responseState.challenge == 'verify_two_fa'">Finish Authentication</text>
      </div>
      <div class="w-full flex column items-center justify-center" >
        <QrCode
          v-if="responseState.challenge == 'set_up_two_fa'"
          :twoFaCode="responseState.secret"
          :email="email"
        />
        <TwoFactorInput
          class="mt-4 mb-4 w-full "
          @twoFaCodeUpdate="handleTwoFaCode"
          :twoFacode="responseState?.secret"
          :twoFaError="challengeError"
          @twoFaCodeInput="twoFaInput"
        />
        <button class='button main-color-bg' @click="handleTwoFaCode()">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>

import InpComponent from '@/components/InpComponent.vue';
import QrCode from '@/components/QrCode.vue'
import TwoFactorInput from '@/components/TwoFactorInput.vue';
import API from '../api/index.js'
import { mapState } from 'vuex';
import Utils from '../utils/index.js'
import store from '../store/index.js'
export default {
  name: 'RegisterVue',
  components: {
    InpComponent,
    QrCode, 
    TwoFactorInput
  },
  data() {
    return {
      // Define your local data properties here
      username: '',
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      challenge: '',
      state: 'login',
      twoFaCode: '',
      challengeError: '',
      responseState: {},
    }
  },
  methods: {
    handleTwoFaCode(code) {
      console.log('handleTwoFaCode', code)
      // send request to server again
      this.twoFaCode = code;
      this.login();
    },
    twoFaInput() {
      this.challengeError = '';
    },
    inputChangeFunc(event) {
      console.log('inputChangeFunc', event)
      if (event[0] == 'email'){
        this.email = event[1];
        this.validateEmailField();
      } else if (event[0] == 'password') {
        this.password = event[1];
        this.validatePasswordField();
      }
    },
    validateEmailField() {
      if (this.emailError) {
        if (Utils.validateEmail(this.email)) {
          this.emailError = false;
        }
      }
    },
    validatePasswordField() {
      if (this.passwordError) {
        if (Utils.validatePassword(this.password)) {
          this.passwordError = false;
        }
      }
    },
    async sendEmail(){
      let resp = await API.forgotPassword(this.email)
      if (resp) {
        store.dispatch('addNotification', {
          text: 'Reset Password email has been sent to your email',
          type: 'success'
        })
      }
    },
    stateChange(state) {
      console.log('state', state)
      this.state = state
    },
    async login() {
      console.log('login', this.email, this.password, this.twoFaCode)
      let error = false
      if (!Utils.validateEmail(this.email)) {
        this.emailError = 'Invalid email';
        error = true;
      }
      if (!Utils.validatePassword(this.password)) {
        this.passwordError = 'Password must be from 6 to 30 characters long';
        error = true;
      }
      if (error) {
        return;
      }
      console.log('AAAAAAAAAAAAAAA', {
        email: this.email,
        password: this.password,
        token: this.twoFaCode,
      })
      let resp = await API.login({
        email: this.email,
        password: this.password,
        token: this.twoFaCode,
      })
      console.log('AAAAAAAAAAAAAAA resp', JSON.stringify(resp))
      if (!resp) {
        return;
      }
      if (resp.data.challenge) {

        this.responseState = resp.data;
        if (resp.data.error) {
          this.challengeError = resp.data.error;
        }
        return;

      }
      if (resp.error) {
        this.emailError = resp.error;
        return
      }
      localStorage.setItem('token', resp.data.token);
      window.location.reload();
    },
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$router.push('/kyc?view=corporate')
    }
  },

  computed: {
    ...mapState(['user']),
  },

}
</script>

<style scoped lang="scss">


  .title {
    margin-bottom: 20px;
  }
  .error-item {
    color: red;
    margin: 0;
    height: fit-content;
    text-align: left;
    font-size: 9px;
    margin: 0;
    margin-left: -15px;
    margin-top: -5px;
  }
  .register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
    color: #38314e;
    font-size: 23px;
    font-weight: bold;
    text-align: left;


  }
  .title {
    text {
      font-size: 20px;
      font-weight: bold;
      margin: auto;
    }
  }
  .register-wrap {
    background-color: white;
    padding: 20px;
    margin-top: -60px;
    border-radius: 10px;

  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;
    > * {
      margin: 10px;
      width: 100%;
      font-size: 15px;
    }
    input {
      border: 1px solid #2b263a;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-radius: 0;
      outline: none;
      caret-color: #2b263a;
      color: #38314e;
      background-color: white;
      padding: 3px 10px;
      box-sizing: border-box;
      &.error {
        border: 1px solid red;
      }
    }
    button {
      height: fit-content;
      margin-bottom: 0;

    }   
  }
  .forgot-password {
    height: fit-content;
    text-align: right;
    margin: 2px;
    font-size: 11px;
    width: fit-content;
    margin-left: auto;
    text-decoration: underline;
    cursor: pointer;
  }
  .login {
    height: fit-content;
    text-align: right;
    margin: 2px;
    font-size: 11px;
    text-decoration: underline;
    cursor: pointer;
  }
</style>