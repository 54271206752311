<template>
    <div class='chart-container' style="display: flex; justify-content: space-between;">
        <canvas id="acquisitions"></canvas>
        <div class='holder-label' style='text-align: left;'v-if="chartRequirements">
            <div class="labels">
                <div class="tip corporate"></div>
                Corporate - {{ chartRequirements.filledGeneralSectionDocs }} out of {{ chartRequirements.totalGeneralSectionDocs }} 
                ({{ (100 / +chartRequirements.totalGeneralSectionDocs * +chartRequirements.filledGeneralSectionDocs).toFixed(2) }}%)
            </div>
            <div class="labels">
                <div class="tip ubo"></div>
                UBO - {{ chartRequirements.filledUbo }} out of {{ chartRequirements.TotalUbo }} 
                ({{ (100 / +chartRequirements.TotalUbo * +chartRequirements.filledUbo).toFixed(2) }}%)
            </div>
            <div class="labels">
                <div class="tip md"></div>
                MD - {{ chartRequirements.filledMD }} out of {{ chartRequirements.TotalMD }} 
                ({{ (100 / +chartRequirements.TotalMD * +chartRequirements.filledMD).toFixed(2) }}%)
            </div>
            <div class="labels">
                <div class="tip missing"></div>
                Missing
            </div>
        </div>
    
    </div>
</template>

<script setup>
import Chart from 'chart.js/auto';
import { ref, defineEmits, onMounted, computed } from 'vue' 
const props = defineProps(['dataStructure'])
const chartRequirements = ref(null)


function getEntity() {
    const docs = [
        {
            docType: '', file: false, additinal_fields: [
                'name', 'address_line_1', 'address_line_2', 'city', 'address_country', 'zip'
            ]
        },
        {
            docType: 'entity_aoi', file: true
        },
        {
            docType: 'entity_members', file: true
        },
        {
            docType: 'entity_coc', file: true
        },
        {
            docType: 'entity_soi', file: true
        },
        {
            docType: 'entity_rodo', file: true
        },
        {
            docType: 'entity_license', file: true, isMandatory: function(is_supervisd) {
                if (is_supervisd) {
                    return true;
                } 
                return false
            }
        },
    ]
    return docs;
}




function getPerson(){
    const docs = [
        {
            docType: 'passport_file', file: true, additinal_fields: [
                'firstName', 'lastName', 'passportNumber', 'passportCountry', 'dateIssued', 'dateExpiry', 'tinNumber'
            ]
        }, 
        {
            docType: 'address_file', file: true, additinal_fields: [
                'address_line_1', 'address_line_2', 'address_date_issues', 'city', 'address_country', 'zip', 
            ]
        },
        {
            docType: 'secondPassport_file', file: true, additinal_fields: [
                'secondPassportNumber', 'secondPassportType', 'secondPassportCountry', 'secondDateIssued', 'secondDateExpiry', 
            ], isMandatory: function(location_type) {
                if (location_type == 'abroad') {
                    return true;
                } 
                return false
            }
        },
        {
            docType: 'bank_reference_letter', file: true
        },
        {
            docType: 'source_of_funds', file: true
        },
        {
            docType: 'source_of_wealth', file: true
        },
        {
            docType: 'google_search', file: true
        },
        {
            docType: 'world_check', file: true
        },
    ]
    return docs;
}


function getUboPercentage(){
    const docs = [
        ...getPerson(),
        {
            docType: 'percentage_held', file: false,
        }
    ]
    return docs
}




function getOfficialPercentage(){
    const docs = [
        ...getPerson(),
        {
            docType: 'percentage_held', file: false,
        }
    ]
    return docs
}





const generalSectionDocs = [

    {docType: 'kyc-ma-1', file: true}, 
    {docType: 'kyc-ppa-2', file: true}, 
    {docType: 'kyc-feeibtbvdna-3', file: true,  additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-lteabibtdoea-4', file: true, additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-mlibtdoea-4-1', file: true, additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-aoi-5', file: true}, 
    {docType: 'kyc-attaoi-5-1', file: true, additinal_fields: ['dateIssued']}, 
    {docType: 'kyc-reftcoc-5-2', file: true, additinal_fields: ['dateIssued']}, 

    {docType: 'kyc-oc-6', file: true}, 
    {docType: 'kyc-sr-7', file: true}, 
    {docType: 'kyc-reftcoc-7-1', file: true}, 

    {docType: 'kyc-sof-8', file: true}, 
    {docType: 'tax-ruling', file: true}, 
    {docType: 'cosbaof', file: true}, 
    {docType: 'scfba', file: true}, 
    {docType: 'kyc-aa-y1-12', file: true, additinal_fields: ['year', 'fiscal_year_ending_on']}, 
    {docType: 'kyc-aa-y2-12', file: true, additinal_fields: ['year', 'fiscal_year_ending_on']},

    {docType: 'kyc-dogmos-13', file: true, additinal_fields: ['year']}, 
    {docType: 'kyc-fpt-14', file: true, additinal_fields: ['year']},
    {docType: 'kyc-pcitaa-15', file: true, optional: true, additinal_fields: ['year', 'fiscal_year_ending_on']},
]


const filledGeneralSectionDocs = ref(null)


function getFilledDocsMD () {
    let minRequiredDocument = getEntity().length;
    let filledDocuments = 0;
    let allDocuments = 0
    
    for( let md of props.dataStructure['mbds']) {
        const minRequiredDocumentForOfficial = getOfficialPercentage().length;
        let filledDocumentsForOfficial = 0;
        let allDocumentsForOfficial = 0
        if (md.struct.person) {
         
            for (let official of md.struct.person) {
                for (let property of getOfficialPercentage()) {
                    allDocumentsForOfficial += 1;
                    const propValue = official[property.docType]
                    if (property.isMandatory && property.isMandatory(official.location_type.value) == false) {
                        allDocumentsForOfficial -=1
                        continue;
                    }
                    if (   
                        property.file && 
                        propValue.file && 
                        propValue.file.filename
                        && propValue.comment_data_handler  
                    ) {
                        if (property.additinal_fields) {
                            let filled = true;
                            for(let field of property.additinal_fields) {
                                if (!propValue[field].value) {
                                    filled = false;
                                    break;
                                }
                            }
                            if (filled) {
                                filledDocumentsForOfficial += 1;
                            }
                        } else {
                            filledDocumentsForOfficial += 1;
                        }

                    }
                    if (!property.file) {
                        if (propValue) {
                            filledDocumentsForOfficial += 1;
                        }
                    }
                }
            }
        }
        if (allDocumentsForOfficial == 0) {
            allDocumentsForOfficial = minRequiredDocumentForOfficial
        }
        filledDocuments += filledDocumentsForOfficial;
        allDocuments += allDocumentsForOfficial;
        if (md.entity_type.value == 'entity') {
            for (let property of getEntity()) {
                allDocuments += 1;
                const propValue = md.struct.legalEntity[property.docType]
                if (property.isMandatory && property.isMandatory(md.supervised.value) == false) {
                    allDocuments -=1
                    continue;
                }
                if (   
                    property.file && 
                    propValue.file && 
                    propValue.file.filename
                    && propValue.comment_data_handler  
                ) {
                    filledDocuments += 1;
                }
                if (property.docType == '') {
                    if (property.additinal_fields) {
                        let filled = true;
                        for(let field of property.additinal_fields) {
                            console.log('field', field, md.struct.legalEntity[field])
                            if ([
                                'address_line_1', 
                                'address_line_2', 
                                'city', 
                                'address_country', 
                                'zip'
                            ].includes(field)) {
                                if (!md.struct.legalEntity['address'][field].value) {
                                    filled = false;
                                    break;
                                }
                            } else {
                                if (!md.struct.legalEntity[field].value) {
                                    filled = false;
                                    break;
                                }
                            }
                          
                        }
                        if (filled) {
                            filledDocuments += 1;
                        }
                    }
                }
            }
            const minRequiredDocumentForUbo = getUboPercentage().length;
            if (md.supervised.value == false) {
                let filledDocumentsForUbo = 0;
                let allDocumentsForUbo = 0;
                for (let ubo of md.struct.legalEntity.mbd_ubo) {
                    for (let property of getUboPercentage()) {
                        allDocumentsForUbo += 1;
                        const propValue = ubo[property.docType]
                        if (property.isMandatory && property.isMandatory(ubo.location_type.value) == false) {
                            allDocumentsForUbo -=1
                            continue;
                        }
                        if (   
                            property.file && 
                            propValue &&
                            propValue.file && 
                            propValue.file.filename
                            && propValue.comment_data_handler  
                        ) {
                            if (property.additinal_fields) {
                                let filled = true;
                                for(let field of property.additinal_fields) {
                                    if (!propValue[field].value) {
                                        filled = false;
                                        break;
                                    }
                                }
                                if (filled) {
                                    filledDocumentsForUbo += 1;
                                }
                            } else {
                                filledDocumentsForUbo += 1;
                            }

                        }
                        if (!property.file) {
                            if (propValue) {
                                filledDocumentsForUbo += 1;
                            }
                        }
                    }
                }
                if (allDocumentsForUbo == 0) {
                    allDocumentsForUbo = minRequiredDocumentForUbo
                }
                filledDocuments += filledDocumentsForUbo;
                allDocuments += allDocumentsForUbo;
            }
          
           
        } 

    }
    if (allDocuments == 0) {
        allDocuments = minRequiredDocument
    }

    return [filledDocuments, allDocuments]
}




function getFilledDocsUbo () {
    let minRequiredDocument = getUboPercentage().length;
    let filledDocuments = 0;
    let allDocuments = 0
    
    for( let ubo of props.dataStructure['ubos']) {
        for (let property of getUboPercentage()) {
            allDocuments += 1;
            const propValue = ubo.struct.person[property.docType]
            if (property.isMandatory && property.isMandatory(ubo.struct.person.location_type.value) == false) {
                allDocuments -=1
                continue;
            }
            if (   
                property.file && 
                propValue.file && 
                propValue.file.filename
                && propValue.comment_data_handler  
            ) {
                if (property.additinal_fields) {
                    let filled = true;
                    for(let field of property.additinal_fields) {
                        if (!propValue[field].value) {
                            filled = false;
                            break;
                        }
                    }
                    if (filled) {
                        filledDocuments += 1;
                    }
                } else {
                    filledDocuments += 1;
                }

            }
            if (!property.file) {
                if (propValue) {
                    filledDocuments += 1;
                }
            }
        }
    }
    if (allDocuments == 0) {
        allDocuments = minRequiredDocument
    }
    return [filledDocuments, allDocuments]
}



function getFilledDocsGeneral () {
    const filledDocs = generalSectionDocs.reduce( (acc, cur ) => {

        const fileArea = props.dataStructure[cur.docType];

        if (['scfba', 'cosbaof'].includes(cur.docType)) {

            const fileAreaFirst = fileArea[0];
            console.log('fileArea', fileArea)
            if (fileAreaFirst && fileAreaFirst?.file?.filename && fileAreaFirst.comment_data_handler) {
                acc +=1;
            } 
        } else {
            if(fileArea?.file?.filename && fileArea.comment_data_handler) {

                if (cur.additinal_fields) {
                    let filled = true;
                    for(let field of cur.additinal_fields) {
                        console.log('field', field, fileArea[field])
                        
                        if (!fileArea[field]) {
                            filled = false;
                            break;
                        }
                    }
                    if (filled) {
                        acc += 1;
                    }
                } else {
                    acc += 1;
                }
            }
        }
        return acc
    }, 0)

    return [filledDocs, generalSectionDocs.length]
}

function renderChart() {
    const [filledGeneralSectionDocs, totalGeneralSectionDocs] = getFilledDocsGeneral();

    const [filledUbo, TotalUbo] = getFilledDocsUbo()
    const [filledMD, TotalMD] = getFilledDocsMD()

    chartRequirements.value = {
        filledGeneralSectionDocs,
        totalGeneralSectionDocs,
        filledUbo,
        TotalUbo,
        filledMD,
        TotalMD
    }


    
    const percentageGeneral = 100 / totalGeneralSectionDocs;
    const filledPercentageGeneral = percentageGeneral * filledGeneralSectionDocs;
    const remainingPercentageGeneral = 100 - filledPercentageGeneral;

    const percentageUbo = 100 / TotalUbo;
    const filledPercentageUbo = percentageUbo * filledUbo;
    const remainingPercentageUbo = 100 - filledPercentageUbo;


    const percentageMD = 100 / TotalMD;
    const filledPercentageMD = percentageMD * filledMD;
    const remainingPercentageMD = 100 - filledPercentageMD;


    const data = {
        labels: [
            // 'Corporate Filled',
            // 'Corporate Not Filled',
            // 'Ubo Filled',
            // 'Ubo Not Filled',
            // 'MD Filled',
            // 'MD Not Filled',
        ],
        
        datasets: [{
            label: 'KYC documents not filled', 
            data: [
            filledPercentageGeneral, remainingPercentageGeneral],
            backgroundColor: [
                'rgb(51, 103, 214)',
                'rgb(255 194 194)',
            ],
            hoverOffset: 20,
            cutout: 140
        },
        {
            label: 'UBO Section', 
            data: [
                filledPercentageUbo, remainingPercentageUbo, 
            ],
            backgroundColor: [
                'rgb(66, 133, 244)',
                'rgb(255 194 194)',
            ],
            hoverOffset: 20,
            cutout: 140
        },
        {
            label: 'Managing Board of Dirrectors Section', 
            data: [
                filledPercentageMD, remainingPercentageMD, 
            ],
            backgroundColor: [
                'rgb(160, 194, 249)',
                'rgb(255 194 194)',
            ],
            hoverOffset: 20,
            cutout: 140
        }
    ]
    };


    const config = {
        type: 'doughnut',
        data: data,
    };




  new Chart(
    document.getElementById('acquisitions'),
    config
  );
};
onMounted(() => {
    renderChart();
});

</script>

<style lang="scss" scoped>


    .labels {
        display: flex;
        gap: 10px 
    }
    .holder-label {
        // position: absolute;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        width: 280px;
    }
    .chart-container {
        position: relative;
        flex-direction: row;

        align-items: flex-start;
    }
    .tip {
        width: 40px;
        height: 20px;
    }
    .corporate {
        background-color: rgb(51, 103, 214);
    }
    .ubo {
        background-color: rgb(66, 133, 244);
    }
    .md {
        background-color: rgb(160, 194, 249);
    }
    .missing {
        background-color: rgb(255 194 194);
    }
    canvas {
        width: 400px;
        height: 400px;
        max-height: 400px;
        max-width: 400px;
    }
</style>