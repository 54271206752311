<template>
    <div class="main-block">
        <table>
            <thead>
                <tr class="table-row">
                    <th>Action</th>
                    <th>Allow</th>
                    <th>Restrict</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-row section-name">
                    <td>User Management</td>
                    <td></td>
                    <td></td>
                </tr>

                <tr>
                    <td>View User</td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="view_user" @change="inputChange" id="allow-view_user"></td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR' " type="radio" name="view_user" @change="inputChange" id="deny-view_user"></td>
                </tr>
                <tr>
                    <td>Create User</td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="create_user" @change="inputChange" id="allow-create_user"></td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="create_user" @change="inputChange" id="deny-create_user"></td>
                </tr>
                <tr>
                    <td>Edit User</td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="update_user" @change="inputChange" id="allow-update_user"></td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="update_user" @change="inputChange" id="deny-update_user"></td>
                </tr>
                <tr>
                    <td>Delete User</td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="delete_user" @change="inputChange" id="allow-delete_user"></td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="delete_user" @change="inputChange" id="deny-delete_user"></td>
                </tr>
                <tr>
                    <td>View User Permission</td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="view_permission_of_user" @change="inputChange" id="allow-view_permission_of_user"></td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="view_permission_of_user" @change="inputChange" id="deny-view_permission_of_user"></td>
                </tr>
                <tr>
                    <td>Change User Permission</td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="change_permission_of_user" @change="inputChange" id="allow-change_permission_of_user"></td>
                    <td><input :disabled="inputsDisabled || user.type == 'ADMINISTRATOR'" type="radio" name="change_permission_of_user" @change="inputChange" id="deny-change_permission_of_user"></td>
                </tr>



                <tr class="table-row section-name">
                    <td>Dashboard Section</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>View Dashboard Section</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_dashboard_tab" @change="inputChange" id="allow-view_dashboard_tab"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_dashboard_tab" @change="inputChange" id="deny-view_dashboard_tab"></td>
                </tr>

                <tr>
                    <td>View Chart</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_chart" @change="inputChange" id="allow-view_chart"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_chart" @change="inputChange" id="deny-view_chart"></td>
                </tr>

                <tr>
                    <td>Update Dashboard Section</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="update_dashboard" @change="inputChange" id="allow-update_dashboard"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="update_dashboard" @change="inputChange" id="deny-update_dashboard"></td>
                </tr>

                <tr>
                    <td>Approve Review</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="verify_report" @change="inputChange" id="allow-verify_report"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="verify_report" @change="inputChange" id="deny-verify_report"></td>
                </tr>


                <tr class="table-row section-name">
                    <td>Sanctions / PEP / Relevant Adverse Information</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Do a Sanctions / PEP / Relevant Adverse Information</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="world_search" @change="inputChange" id="allow-world_search"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="world_search" @change="inputChange" id="deny-world_search"></td>
                </tr>
                <!-- <tr class="table-row section-name">
                    <td>Global File Access</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>All Compliance Sections Visible</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_files_section_everywhere" @change="inputChange" id="allow-view_files_section_everywhere"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_files_section_everywhere" @change="inputChange" id="deny-view_files_section_everywhere"></td>
                </tr>
                <tr>
                    <td>Upload Files Everywhere</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_files_sectionEeverywhere" @change="inputChange" id="allow-upload_files_sectionEeverywhere"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_files_section_everywhere" @change="inputChange" id="deny-upload_files_section_everywhere"></td>
                </tr>
                <tr>
                    <td>Delete files Everywhere</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_files_section_everywhere" @change="inputChange" id="allow-delete_files_section_everywhere"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_files_section_everywhere" @change="inputChange" id="deny-delete_files_section_everywhere"></td>
                </tr>
                <tr>
                    <td>Download files from all sections</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="download_files_section_everywhere" @change="inputChange" id="allow-download_files_section_everywhere"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="download_files_section_everywhere" @change="inputChange" id="deny-download_files_section_everywhere"></td>
                </tr>
                <tr>
                    <td>Leave Comments on files</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="leave_comments_on_files" @change="inputChange" id="allow-leave_comments_on_files"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="leave_comments_on_files" @change="inputChange" id="deny-leave_comments_on_files"></td>
                </tr> -->
                
                <tr class="table-row section-name">
                    <td>Corporate Section Access</td>
                    <td></td>
                    <td></td>
                </tr>

                <tr>
                    <td>General Section Visible</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_general_section" @change="inputChange" id="allow-view_general_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_general_section" @change="inputChange" id="deny-view_general_section"></td>
                </tr>
                <tr>
                    <td>View Listing Service</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_listing_service" @change="inputChange" id="allow-view_listing_service"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_listing_service" @change="inputChange" id="deny-view_listing_service"></td>
                </tr>
                <tr>
                    <td>Edit Listing Service</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="update_listing_service" @change="inputChange" id="allow-update_listing_service"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="update_listing_service" @change="inputChange" id="deny-update_listing_service"></td>
                </tr>
                <tr>
                    <td>Upload File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_general_section" @change="inputChange" id="allow-upload_general_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_general_section" @change="inputChange" id="deny-upload_general_section"></td>
                </tr>
                <tr>
                    <td>Delete File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_general_section" @change="inputChange" id="allow-delete_general_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_general_section" @change="inputChange" id="deny-delete_general_section"></td>
                </tr>
                <tr>
                    <td>Download File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="donwload_general_section" @change="inputChange" id="allow-donwload_general_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="donwload_general_section" @change="inputChange" id="deny-donwload_general_section"></td>
                </tr>


                <tr class="table-row section-name">
                    <td>Corporate UBO Section Access</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Corporate UBO Section Visible</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_ubo_section_general" @change="inputChange" id="allow-view_ubo_section_general"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_ubo_section_general" @change="inputChange" id="deny-view_ubo_section_general"></td>
                </tr>
                <tr>
                    <td>Create UBO</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="create_ubo_section_general" @change="inputChange" id="allow-create_ubo_section_general"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="create_ubo_section_general" @change="inputChange" id="deny-create_ubo_section_general"></td>
                </tr>
                <tr>
                    <td>Upload File & Fill In Information</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_ubo_section_general" @change="inputChange" id="allow-upload_ubo_section_general"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_ubo_section_general" @change="inputChange" id="deny-upload_ubo_section_general"></td>
                </tr>
                <tr>
                    <td>Delete Section</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_ubo_section_general" @change="inputChange" id="allow-delete_ubo_section_general"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_ubo_section_general" @change="inputChange" id="deny-delete_ubo_section_general"></td>
                </tr>
                <tr>
                    <td>Delete File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_ubo_section_files" @change="inputChange" id="allow-delete_ubo_section_files"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_ubo_section_files" @change="inputChange" id="deny-delete_ubo_section_files"></td>
                </tr>
                <tr>
                    <td>Download File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="donwload_ubo_section_general" @change="inputChange" id="allow-donwload_ubo_section_general"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="donwload_ubo_section_general" @change="inputChange" id="deny-donwload_ubo_section_general"></td>
                </tr>

                <tr class="table-row section-name">
                    <td>Managing Directors Section Access</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Managing Directors Section Visible</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_mbd_section" @change="inputChange" id="allow-view_mbd_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_mbd_section" @change="inputChange" id="deny-view_mbd_section"></td>
                </tr>
                <tr>
                    <td>Create Sections</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="create_mbd_section" @change="inputChange" id="allow-create_mbd_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="create_mbd_section" @change="inputChange" id="deny-create_mbd_section"></td>
                </tr>
                <tr>
                    <td>Upload File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_file_mbd_section" @change="inputChange" id="allow-upload_file_mbd_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="upload_file_mbd_section" @change="inputChange" id="deny-upload_file_mbd_section"></td>
                </tr>
                <tr>
                    <td>Delete File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_file_mbd_section" @change="inputChange" id="allow-delete_file_mbd_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_file_mbd_section" @change="inputChange" id="deny-delete_file_mbd_section"></td>
                </tr>
                <tr>
                    <td>Delete Section</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_mbd_section" @change="inputChange" id="allow-delete_mbd_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_mbd_section" @change="inputChange" id="deny-delete_mbd_section"></td>
                </tr>
                <tr>
                    <td>Download File</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="donwload_file_mbd_section" @change="inputChange" id="allow-donwload_file_mbd_section"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="donwload_file_mbd_section" @change="inputChange" id="deny-donwload_file_mbd_section"></td>
                </tr>

                <tr class="table-row section-name">
                    <td>Subsidiary Section Access</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Subsidiary Section Visible</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_subsidiary" @change="inputChange" id="allow-view_subsidiary"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_subsidiary" @change="inputChange" id="deny-view_subsidiary"></td>
                </tr>
           
                <tr>
                    <td>Create Subsidiary</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="create_subsidiary" @change="inputChange" id="allow-create_subsidiary"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="create_subsidiary" @change="inputChange" id="deny-create_subsidiary"></td>
                </tr>
                <tr>
                    <td>Update Subsidiary</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="update_subsidiary" @change="inputChange" id="allow-update_subsidiary"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="update_subsidiary" @change="inputChange" id="deny-update_subsidiary"></td>
                </tr>
                <tr>
                    <td>Delete Subsidiary</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_subsidiary" @change="inputChange" id="allow-delete_subsidiary"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="delete_subsidiary" @change="inputChange" id="deny-delete_subsidiary"></td>
                </tr>


                <tr class="table-row section-name">
                    <td>Adjustments & History of Changes</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>View Change Log</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_change_log" @change="inputChange" id="allow-view_change_log"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_change_log" @change="inputChange" id="deny-view_change_log"></td>
                </tr>
           
                <tr>
                    <td>View Adjustment Requests</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_requested_changes" @change="inputChange" id="allow-view_requested_changes"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_requested_changes" @change="inputChange" id="deny-view_requested_changes"></td>
                </tr>
                <tr>
                    <td>Approve Adjustment</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="approve_requested_changes" @change="inputChange" id="allow-approve_requested_changes"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="approve_requested_changes" @change="inputChange" id="deny-approve_requested_changes"></td>
                </tr>
                <tr>
                    <td>Reject Adjustment</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="reject_requested_changes" @change="inputChange" id="allow-reject_requested_changes"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="reject_requested_changes" @change="inputChange" id="deny-reject_requested_changes"></td>
                </tr>
                <tr>
                    <td>Can Veiw Details</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="can_view_details" @change="inputChange" id="allow-can_view_details"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="can_view_details" @change="inputChange" id="deny-can_view_details"></td>
                </tr>
                <tr>
                    <td>Can Download Files</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="can_download_files" @change="inputChange" id="allow-can_download_files"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="can_download_files" @change="inputChange" id="deny-can_download_files"></td>
                </tr>


                <tr class="table-row section-name">
                    <td>Internal & External Audit Section</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>View Audit Section</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_audit_section_comment" @change="inputChange" id="allow-view_audit_section_comment"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_audit_section_comment" @change="inputChange" id="deny-view_audit_section_comment"></td>
                </tr>
                <tr>
                    <td>Edit Audit Section</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="edit_audit_section_comment" @change="inputChange" id="allow-edit_audit_section_comment"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="edit_audit_section_comment" @change="inputChange" id="deny-edit_audit_section_comment"></td>
                </tr>


                <tr class="table-row section-name">
                    <td>Section Visibility</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>View Document History</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_document_history" @change="inputChange" id="allow-view_document_history"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_document_history" @change="inputChange" id="deny-view_document_history"></td>
                </tr>
                <tr>
                    <td>View Wallet Section</td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_wallet" @change="inputChange" id="allow-view_wallet"></td>
                    <td><input :disabled="inputsDisabled" type="radio" name="view_wallet" @change="inputChange" id="deny-view_wallet"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import API from '@/api/index.js'
import { PermissionChecker } from '@/utils/iam_utils';


import { useStore } from 'vuex';

const store = useStore();




const props = defineProps(['user'])
const myUser = store.state.user;
const loaded = ref(false);
const userPermissions = ref(null);
const APIPermissions = ref(null);
const inputsDisabled = ref(null);
onMounted(async () => {
    const response =  await API.loadPermissions(props.user._id)
    APIPermissions.value = response.permissions;

    userPermissions.value = response.permissions;

    inputsDisabled.value = !PermissionChecker.changeUserPermissions(userPermissions.value);

    for (let permissionKey in APIPermissions.value) {
        let name = permissionKey;
        let value = APIPermissions.value[name];

        let element = document.getElementById(`${value}-${name}`);
        if (element) {
            element.checked = true;
        }

    }
}) 

async function inputChange(event) {
    let name = event.target.name;

    let value = event.target.id.split('-')[0];
    console.log('element', name, value);
    await API.updateUserPermissions({user_id: props.user._id, name, effect: value});
    if (myUser._id == props.user._id) {
        store.state.userPermissions[name] = value;
    }
}

</script>

<style lang="scss" scoped>
    .main-block {
        width: 100%;
        padding: 25px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
    }
 
    table {
        border-collapse: collapse;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        width: 100%;
    }

    tr {
        font-size: 14px;
        color: black;
        td, th {
            padding: 15px 18px;
            &:first-child {
                text-align: left;
                width: 400px;
            }
            input {
                height: 16px;
                width: 16px;
                border: unset;
                outline: unset;
            }
        }
    }
    .section-name {
        font-size: 12px;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        font-weight: bold;
        td {
            background-color: #F8FAFC;
            padding: 8px 15px;
        }
    }

</style>




