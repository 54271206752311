<template>
    <div class="mainView">
        <div class="ticketView">
            <div class="table-container">
                <div class="table-content">
                    <div class="table-header" >
                        <h4 class="table-title" v-if="!user.is_super_user"> My Tickets</h4>
                        <h4 class="table-title" v-else>Tickets</h4>

                        <!-- v-if="!user.is_super_user"  -->
                        <button 
                        @click="createTicket()">Create Ticket</button>
                    </div>
                    <table class="table-list">
                        <thead>
                            <tr class='header-of-table'>
                                <th>Subject</th>
                                <th>Ticket Id</th>
                                <th>User</th>
                                <th>Severity</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        </thead>


                        <tbody class='table-content-row top-border'>
                            <tr v-for="ticket in tickets" :key="ticket._id" @click="goToPage(ticket)" class="row-pointer">
                                <td>{{ ticket.subject }}</td>
                                <td>{{ ticket.ticket_id }}</td>
                                <td>{{ ticket.user_id }}</td>
                                <td>{{ ticket.severity }}</td>
                                <td>{{ ticket.status }}</td>
                                <td>{{ dayjs(ticket.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <PopupCover v-if="showPopups" @close="closePopup">
            <CreateTicket 
                forCreate="true"
                :loading="loading"
                @close="closePopup" 
                @ticketInfoUpdate="createTickethandler"/>
        </PopupCover>
    </div>
</template>



<script setup>
    import { ref,onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import API from '../api/index.js'
    import dayjs from 'dayjs'
    
    import CreateTicket from '@/components/CreateTicket.vue';
    import PopupCover from '@/components/PopupCover.vue';
    
    const tickets = ref(null);
    const count = ref(null);
    const showPopups = ref(false);
    const store = useStore();

    const user = ref(store.state.user);
    const loading = ref(false)
    const router = useRouter();

    function createTicket(){
        showPopups.value = true
    }

    function goToPage(ticket) {
        router.push({
            name: 'single_ticket',
            params: { id: ticket.ticket_id }
        })
    }
    async function createTickethandler(ticketInfo){
        if (loading.value == true){
            return;
        }
        loading.value = true;
        const ticket = ticketInfo.ticket;
        const fileInfo = ticketInfo.files;
        console.log('fileInfo', fileInfo)
        if (!ticket.subject){
            store.dispatch('addNotification', {
                text: 'Please fill the subject field',
                type: 'error'
            })
            return
        }
        if (!ticket.content.trim()){
            store.dispatch('addNotification', {
                text: 'Please fill the content field',
                type: 'error'
            })
            return
        }
        let fileIds = []
        for (let file of fileInfo) {
            let fileId = await API.uploadSupportFile(file);
            fileIds.push(fileId);
        }
        console.log('fileIds', fileIds)
        await API.createTicket({ ticket, fileIds});
        loading.value = false;
        closePopup();
        window.location.reload(); 
    }
    function closePopup(){
        showPopups.value = false
    }


    onMounted(async () => {
        const response = await API.getTickets();
        tickets.value = response.tickets;
        count.value = response.count;
    })
</script>

<style lang="scss" scoped>

    .mainView {
        max-width: 1000px;
        margin: 0 auto;
    }
    .ticketView {
        margin-top: 40px;
    }
    .table-container {
        width: 100%;
        max-width: 998px
    }
    .table-content {
        width: 100%;
        max-width: 998px;
        background-color: white;
        overflow-y: auto;
        border: 1px solid var(--border-color);
        border-radius: 10px;
    }
    .table-header {
        background-color: white;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .top-border {
        border-top: 1px solid var(--border-color);
    }

    .row-pointer {
        cursor: pointer;
    }
    .table-title {
        display: flex;
        justify-content: space-between;
        color: black;
        font-size: 22px;
    }
    .table-header {
        display: flex;
        justify-content: space-between;
        button {
            width: fit-content;
            padding: 10px;  
        }
    }
    .table-list {
        padding: 20px;
        width: 100%;
        border-collapse: collapse;
        th, td {
            padding: 20px 30px;
            color: black;
            td {
                border: none;
            }
        }
        tr {
            text-align: left;
        }

    }

    .add-new td{
        padding-top: 0px;
        padding-bottom: 0px;

    }
    .save-button {
        padding: 10px 20px;
        width: fit-content;
        margin-left: auto;
    }
    .header-of-table {
        background-color: var(--app-background);
    }
    h4 {
        margin: 0;
    }
    .add-new-tableitem-area {
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .text-input {
        &:deep(input) {
            text-align: center;
        }
    }
    .table-header {
        border-bottom: 1px solid #ccc;
    }

    .more-actions {
        padding: 5px 0px;
        border-radius: 4px;
        max-width: 40px;
        width: 30px;
        margin-left: auto;
        cursor: pointer;
        &:hover {
            background: #d9d9d9;

            .dropdown {
                display: flex;
                flex-direction: column;

            }
        }
        position: relative;

        .dropdown {
            display: none;
            position: absolute;
            top: 30px;
            right: 0;
            background: white;
            border: 1px solid #ccc;
            border-radius: 4px;
            z-index: 100;
            width: 130px;
            div {
                padding: 10px;
                cursor: pointer;
                &:hover {
                    background: #d9d9d9;
                }
            }
        }
    }
</style>