<template>
    <div>
        <div class="kyc-fields-area mb-20" v-if="PermissionChecker.viewListingService()">
            <div class="kyc-item-section flex column ">
                <h3 class="black">Listing of services provided by trust service provider to company</h3>
                <textarea v-model="textareaValue" 
                    :disabled="!PermissionChecker.updateListingService()"
                    class='border-color' placeholder="Write a comment here"
                    @input="textareaUpdates()"
                ></textarea>
            </div>
        </div>
        <div class="kyc-fields-area mb-20">
            <div class="table-wrap">
            <table style="height: 100%;">
            <TableHeaderRow />
            <tbody>
                    <TableFileRow 
                        :id="dataStructure['kyc-ma-1']?.id"
                        header="Management Agreement"
                        fileType='kyc-ma-1'
                        :file="dataStructure['kyc-ma-1']?.file"  
                        :filePresent="dataStructure['kyc-ma-1']?.file"  
                        :index="dataStructure['kyc-ma-1']?.file?.id" 
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-ma-1']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-ma-1']?.comment_data_handler"
                        :comment_compliance_officer_value="dataStructure['kyc-ma-1']?.comment_compliance_officer"
                        :comment_supervisor_value="dataStructure['kyc-ma-1']?.comment_supervisor"
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-ppa-2']?.id"
                        header="Principal Party Agreement"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-ppa-2']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-ppa-2']?.comment_data_handler"
                        :comment_compliance_officer_value="dataStructure['kyc-ppa-2']?.comment_compliance_officer"
                        :comment_supervisor_value="dataStructure['kyc-ppa-2']?.comment_supervisor"
                        fileType='kyc-ppa-2'
                        :filePresent="dataStructure['kyc-ppa-2']?.file"  
                        :file="dataStructure['kyc-ppa-2']?.file"  
                        :index="dataStructure['kyc-ppa-2']?.file?.id"
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"  
                    />

                    <TableFileRow 
                        :id="dataStructure['kyc-feeibtbvdna-3']?.id"
                        header="Foreign Exchange Exemption issued by the Bank van de Nederlandse Antillen"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-feeibtbvdna-3']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-feeibtbvdna-3']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-feeibtbvdna-3']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-feeibtbvdna-3']?.comment_supervisor" 
                        fileType='kyc-feeibtbvdna-3'
                        :filePresent="dataStructure['kyc-feeibtbvdna-3']?.file"
                        @dateInputChangeFunc="handleDateChange"
                        :index="dataStructure['kyc-feeibtbvdna-3']?.file?.id"  
                        :file="dataStructure['kyc-feeibtbvdna-3']?.file"  
                        :issueDateValue="dataStructure['kyc-feeibtbvdna-3']?.dateIssued" 
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-lteabibtdoea-4']?.id"
                        header="License to Establish a Business issued by the Department of Economic Affairs"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-lteabibtdoea-4']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-lteabibtdoea-4']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-lteabibtdoea-4']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-lteabibtdoea-4']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-lteabibtdoea-4']?.file"    
                        :file="dataStructure['kyc-lteabibtdoea-4']?.file"  
                        fileType='kyc-lteabibtdoea-4'
                        @dateInputChangeFunc="handleDateChange"
                        :index="dataStructure['kyc-lteabibtdoea-4']?.file?.id"  
                        :issueDateValue="dataStructure['kyc-lteabibtdoea-4']?.dateIssued" 
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()" 
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-mlibtdoea-4-1']?.id"
                        header="Management License issued by the Department of Economic Affairs"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-mlibtdoea-4-1']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-mlibtdoea-4-1']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-mlibtdoea-4-1']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-mlibtdoea-4-1']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-mlibtdoea-4-1']?.file"    
                        :file="dataStructure['kyc-mlibtdoea-4-1']?.file"  
                        fileType='kyc-mlibtdoea-4-1'
                        @dateInputChangeFunc="handleDateChange"
                        :index="dataStructure['kyc-mlibtdoea-4-1']?.file?.id"  
                        :issueDateValue="dataStructure['kyc-mlibtdoea-4-1']?.dateIssued" 
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()" 
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-aoi-5']?.id"
                        header="Articles of Incorporation"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-aoi-5']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-aoi-5']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-aoi-5']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-aoi-5']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-aoi-5']?.file"   
                        :file="dataStructure['kyc-aoi-5']?.file"  
                        :index="dataStructure['kyc-aoi-5']?.file?.id"  
                        fileType='kyc-aoi-5'
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-attaoi-5-1']?.id"
                        header="Amendments to the Articles of Incorporation"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-attaoi-5-1']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-attaoi-5-1']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-attaoi-5-1']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-attaoi-5-1']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-attaoi-5-1']?.file"  
                        :file="dataStructure['kyc-attaoi-5-1']?.file"  
                        fileType='kyc-attaoi-5-1'
                        @dateInputChangeFunc="handleDateChange"
                        :index="dataStructure['kyc-attaoi-5-1']?.file?.id"  
                        :issueDateValue="dataStructure['kyc-attaoi-5-1']?.dateIssued"  
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-reftcoc-5-2']?.id"
                        header="Recent Extract from the Chamber of Commerce"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-reftcoc-5-2']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-reftcoc-5-2']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-reftcoc-5-2']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-reftcoc-5-2']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-reftcoc-5-2']?.file"    
                        :file="dataStructure['kyc-reftcoc-5-2']?.file"  
                        fileType='kyc-reftcoc-5-2'
                        @dateInputChangeFunc="handleDateChange"
                        :index="dataStructure['kyc-reftcoc-5-2']?.file?.id"  
                        :issueDateValue="dataStructure['kyc-reftcoc-5-2']?.dateIssued"  
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-oc-6']?.id"
                        header="Organizational Chart"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-oc-6']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-oc-6']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-oc-6']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-oc-6']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-oc-6']?.file"   
                        :file="dataStructure['kyc-oc-6']?.file"   
                        :index="dataStructure['kyc-oc-6']?.file?.id"  
                        fileType='kyc-oc-6'  
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-sr-7']?.id"
                        header="Shareholders' register"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-sr-7']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-sr-7']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-sr-7']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-sr-7']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-sr-7']?.file"   
                        :file="dataStructure['kyc-sr-7']?.file"   
                        :index="dataStructure['kyc-sr-7']?.file?.id"  
                        fileType='kyc-sr-7'
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-mreftcoc-7-1']?.id"
                        header="Share transfer agreement (after establishment)"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-mreftcoc-7-1']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-mreftcoc-7-1']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-mreftcoc-7-1']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-mreftcoc-7-1']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-reftcoc-7-1']?.file"    
                        :file="dataStructure['kyc-reftcoc-7-1']?.file"    
                        :index="dataStructure['kyc-reftcoc-7-1']?.file?.id"  
                        fileType='kyc-reftcoc-7-1'
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-sof-8']?.id"
                        header="Source of funds"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-sof-8']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-sof-8']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-sof-8']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-sof-8']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-sof-8']?.file"    
                        :file="dataStructure['kyc-sof-8']?.file"    
                        :index="dataStructure['kyc-sof-8']?.file?.id"  
                        fileType='kyc-sof-8'
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-ruling']?.id"
                        header="Tax Ruling"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['tax-ruling']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['tax-ruling']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['tax-ruling']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['tax-ruling']?.comment_supervisor" 
                        :filePresent="dataStructure['tax-ruling']?.file"    
                        :file="dataStructure['tax-ruling']?.file"    
                        :index="dataStructure['tax-ruling']?.file?.id"  
                        fileType='tax-ruling'
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    </tbody>
                </table>
            </div>
        </div>
        <div class="kyc-fields-area mb-20">
            <div class="flex header-of-section">
                <h3>
                    Copy of signed bank account opening forms
                </h3>
            <button class="add-new fit-content" @click="addnewDocument('cosbaof')">Add New</button>
            </div>
            <div class="table-wrap">

                <table style="height: 100%;">

                <tbody>

                    <TableFileRow 
                        v-for="(item, index) in dataStructure['cosbaof']"
                        :header="`Copy of signed bank account opening forms ${item?.bank_name ? '(' + item?.bank_name + ')' : ''}`"
                        :key="`cosbaof-${index}`"

                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        @changeInfo="changeInfo"
                        :proposedChanges="item?.proposedChanges"
                        :bank_name="item?.bank_name"
                        :comment_data_handler_value="item?.comment_data_handler"
                        :comment_compliance_officer_value="item?.comment_compliance_officer"
                        :comment_supervisor_value="item?.comment_supervisor"
                        :filePresent="item?.file"    
                        :file="item?.file"    
                        fileType='cosbaof'
                        :index="item.id"
                        :id="item.id"
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    
                </tbody>
                </table>
            </div>
        </div>
        <div class="kyc-fields-area mb-20">
            <div class="flex header-of-section">
                <h3>
                    Signature card for bank account
                </h3>
            <button class="add-new fit-content" @click="addnewDocument('scfba')">Add New</button>
            </div>
            <div class="table-wrap">
                <table style="height: 100%;">

                <tbody>

                    <TableFileRow 
                        v-for="(item, index) in dataStructure['scfba']"
                        :class="`vi-${index}`"
                        :key="`scfba-${index}`"
                        :header="`Signature card for bank account ${item?.bank_name ? '(' + item?.bank_name + ')' : ''}`"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        @changeInfo="changeInfo"

                        :bank_name="item?.bank_name"
                        :proposedChanges="item?.proposedChanges"
                        :comment_data_handler_value="item?.comment_data_handler"
                        :comment_compliance_officer_value="item?.comment_compliance_officer"
                        :comment_supervisor_value="item?.comment_supervisor"
                        :filePresent="item?.file"    
                        :file="item?.file"    
                        fileType='scfba'
                        :index="item.id"
                        :id="item.id"
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                </tbody>
                </table>
            </div>
        </div>
        <div class="kyc-fields-area mb-20">
            <div class="flex header-of-section">
                <h3>
                    Annual Accounts
                </h3>
            </div>
            <div class="table-wrap">

                <table style="height: 100%;">

                <tbody>
                    <TableFileRow 
                        :id="dataStructure['kyc-aa-y1-12']?.id"

                        header="Annual Accounts - last book year"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-aa-y1-12']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-aa-y1-12']?.comment_data_handler"
                        :comment_compliance_officer_value="dataStructure['kyc-aa-y1-12']?.comment_compliance_officer"
                        :comment_supervisor_value="dataStructure['kyc-aa-y1-12']?.comment_supervisor"
                        
                        :fiscal_year_ending_on="dataStructure['kyc-aa-y1-12']?.fiscal_year_ending_on"
                        :year="dataStructure['kyc-aa-y1-12']?.year"
                        fileType='kyc-aa-y1-12'
                        title="Annual Accounts - last two (2) book year"
                        @changeInfo="changeInfo"
                        :index="dataStructure['kyc-aa-y1-12']?.file?.id"  
                        :filePresent="dataStructure['kyc-aa-y1-12']?.file"  
                        :file="dataStructure['kyc-aa-y1-12']?.file"  
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-aa-y2-12']?.id"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-aa-y2-12']?.proposedChanges"
                        :comment_data_handler_value="dataStructure['kyc-aa-y2-12']?.comment_data_handler"
                        :comment_compliance_officer_value="dataStructure['kyc-aa-y2-12']?.comment_compliance_officer"
                        :comment_supervisor_value="dataStructure['kyc-aa-y2-12']?.comment_supervisor"
                        fileType='kyc-aa-y2-12'
                        header="Annual Accounts - second last book year"

                        :fiscal_year_ending_on="dataStructure['kyc-aa-y2-12']?.fiscal_year_ending_on"
                        @changeInfo="changeInfo"
                        :year="dataStructure['kyc-aa-y2-12']?.year"

                        :index="dataStructure['kyc-aa-y2-12']?.file?.id"  
                        :filePresent="dataStructure['kyc-aa-y2-12']?.file"  
                        :file="dataStructure['kyc-aa-y2-12']?.file"   
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-dogmos-13']?.id"
                        header="Discharge of General Meeting of Shareholders - last book year"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-dogmos-13']?.proposedChanges"

                        @changeInfo="changeInfo"
                        :year="dataStructure['kyc-dogmos-13']?.year"

                        :comment_data_handler_value="dataStructure['kyc-dogmos-13']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-dogmos-13']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-dogmos-13']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-dogmos-13']?.file"  
                        :file="dataStructure['kyc-dogmos-13']?.file"  
                        :index="dataStructure['kyc-dogmos-13']?.file?.id"  
                        fileType='kyc-dogmos-13'
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                    <TableFileRow 
                        :id="dataStructure['kyc-fpt-14']?.id"
                        header="Filing Profit Tax - last book year"
                        @handleFileChange="handleFileChange"
                        @commentChange="commentChange"
                        :proposedChanges="dataStructure['kyc-fpt-14']?.proposedChanges"

                        @changeInfo="changeInfo"
                        :year="dataStructure['kyc-fpt-14']?.year"

                        :comment_data_handler_value="dataStructure['kyc-fpt-14']?.comment_data_handler" 
                        :comment_compliance_officer_value="dataStructure['kyc-fpt-14']?.comment_compliance_officer" 
                        :comment_supervisor_value="dataStructure['kyc-fpt-14']?.comment_supervisor" 
                        :filePresent="dataStructure['kyc-fpt-14']?.file"    
                        :file="dataStructure['kyc-fpt-14']?.file"    
                        :index="dataStructure['kyc-fpt-14']?.file?.id"  
                        fileType='kyc-fpt-14'
                        :upload_available="PermissionChecker.uploadFileCorporate()"
                        :download_available="PermissionChecker.downloadCorporate()"
                        :delete_available="PermissionChecker.deleteCorporate()"
                    />
                </tbody>
                </table>
            </div>
        </div>
        <div class="kyc-fields-area mb-20">
            <div class="flex header-of-section">
                <h3>
                    Subsidiaries
                </h3>
            </div>
            <div class="table-wrap">

                <table style="height: 100%;" v-if="dataStructure['subsidiaries'].length">
                    <tbody>
                        <TableFileRow v-for = "(item, index) in dataStructure['subsidiaries']"
                            :id="item?.id"
                            :subsidiary_id="item?.subsidiary_id"
                            :header="`${item.name} annual accounts - last book year` "
                            @handleFileChange="handleFileChange"
                            @commentChange="commentChange"
                            :proposedChanges="item?.proposedChanges"
                            @changeInfo="changeInfo"
                            :year="item?.year"
                            :comment_data_handler_value="item?.comment_data_handler" 
                            :comment_compliance_officer_value="item?.comment_compliance_officer" 
                            :comment_supervisor_value="item?.comment_supervisor" 
                            :filePresent="item?.file"    
                            :file="item?.file"    
                            :index="item?.file?.id"  
                            fileType='subsidiaries'
                            :upload_available="PermissionChecker.uploadFileCorporate()"
                            :download_available="PermissionChecker.downloadCorporate()"
                            :delete_available="PermissionChecker.deleteCorporate()"
                        />
                    </tbody>
                </table>
                <div v-else> No Subsidiaries added</div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, computed, defineEmits, onMounted, defineProps } from 'vue'
import TableFileRow from '@/components/TableFileRow.vue'
import TableHeaderRow from '@/components/TableHeaderRow.vue'
import { PermissionChecker } from '@/utils/iam_utils';

const emit = defineEmits(['textareaUpdates', 'handleFileChange', 'handleDateChange', 'addnewDocument', 'changeInfop'])

const props = defineProps(['dataStructure'])


function commentChange(value) {
    emit('commentChange', value)
}

function textareaUpdates(value) {
    emit('textareaUpdates', textareaValue.value)
}
function addnewDocument(fileType) {
    emit('addnewDocument', fileType)
}

function changeInfo(event) {
    emit('changeInfo', event)
}
const textareaValue = ref(props.dataStructure.textarea.value)

function handleFileChange(event){
    console.log('GENERAL KYCVIEW handleFileChange', event)
    emit('handleFileChange', event)
}
function handleDateChange(event){
    emit('handleDateChange', event)
}

</script>

<style scoped lang="scss">
    .header-of-section {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        button {
            margin-top: 0;
        }
        h3 {
            color: black;
        }   
    }
    .header-section {
        padding-left: 20px;
        background-color: white;
        height: 125px;
        display: flex;
        padding-left: 35px;
        align-items: center;
        justify-content: center;
    }
    
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
        position: relative;

        .tab-views {
            font-size: 14px;
            position: absolute;
            left: 0;
            top: 40px;
            display: flex;
            .tab {
                padding: 10px 20px;
                cursor: pointer;
                &.active {
                    background-color: #f5f5f5;
                }
            }
        
        }
    }
    .kyc-fields-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .kyc-fields-area {
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
    }
    h3 {
        margin: 0px;
        margin-bottom: 4px;
    }
    .kyc-item-section {
        position: relative;
        width: 100%;
        margin-top: 15px;
        h3 {
            position: absolute;
            background: var(--app-background);
            border-radius: 5px;
            border: 1px solid var(--border-color);
            padding: 0 5px;
            left: -1.5px;
            border-bottom-left-radius: 0;
            color: #000;
            font-weight: 600;
            text-align: left;
            max-width: 86%;
            top: -12px;
            left: 0px;
            font-size: 14px;
            z-index: 2;
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    .black {
        color: black;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        border-radius: 10px;
        height: 150px;

        &:focus {
            outline: none;
            border: 1px solid var(--primary-color);
        }
    }
    .data-holder-comment {
        height: 120px;
        width: 500px;
    }
    .document-comments {
        vertical-align: bottom;
        
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .margin-top-negative {
        margin-top: -40px;
        margin-bottom: 5px;
    }
    .limited-width {
        
    }


table {
    border-collapse: collapse;
    width: 100%;
}

thead {
    font-weight: bold;
}
td {
    padding: 10px;
    border: 1px solid var(--border-color);
}

.no-border > td {
    border: none;
}
.margin-20 {
    height: 20px;
}

</style>