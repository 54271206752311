<template>
    <div class="styled-input" :class="`${error ? 'error-present': '' } ${hasEditPermission ? '': 'disabled'}`">
      <label :class="{light_label, focused: focused || inputValue || placeholder || type == 'date'}" :for="inputId">{{ label }}</label>
      <input v-if="type !== 'options'"
        :type="type"
        :class="`${theme} ${light_label ? 'light_label' : '', value ? 'hasValue' : ''}`"
        :id="inputId"
        :disabled="!hasEditPermission"
        v-model="inputValue"
        :placeholder="placeholder"
        @focus="this.focused = true"
        @blur="this.focused = false"
        @input="emitInputEvent"
        ref="inputComponent"
      />
      
      <select v-else
        class="select-elem"
        :class="theme"
        :id="inputId"
        :disabled="!hasEditPermission"
        v-model="inputValue"
        @change="emitInputEvent">
        <option v-for="option in options" :value="option.value">{{ option.text }}</option>
      </select>
      <div v-if="error" class="error">{{ error }}</div>
      <img v-if="initialType == 'password' && !canSee"
        src="@/assets/password-look.svg"
        class="password-icon"
        @click="changeTypeOfInput"
      >
      <img v-else-if="initialType == 'password' && canSee"
        src="@/assets/password-hide.svg"
        class="password-icon"
        @click="changeTypeOfInput"
      >
    </div>
  </template>
  
  <script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import Utils from '@/utils';
  export default {
    
    data() {
      return {
        focused: false,
        inputValue: '',
        initialType: '',
        canSee: false,
      }
    },
    name: 'Input',

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true,
      },
      light_label: {
        type: String,
      },  
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      theme: {
        type: String,
        default: 'dark',
      },
  
      key_ref: {
        type: String,
        default: '',
      },
      max: {
        type: Number,
        default : null,
      },
      min: {
        type: String,
        default : null,
      },
      max_length: {
        type: Number,
        default : null,
      },
      min_length: {
        type: Number,
        default : null,
      },
      options: {
        type: Array,
        default: () => [],
      },
      error: {
        type: String,
        default : null,
      },
      kyc_validate: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.initialType = this.type;
      if (this.type == 'date' && this.value) {
        this.inputValue = this.value.split('T')[0];
      } else {
        this.inputValue = this.value;
      }
    },
    methods: {
      ...mapActions(['addNotification']),
      changeTypeOfInput(){
        console.log('changeTypeOfInput')
        this.canSee = !this.canSee;
        const input = this.$refs.inputComponent;
        console.log('input', input)

        if (this.canSee) {
          input.setAttribute('type', 'text');
        } else {
          input.setAttribute('type', 'password');
        }

      }
    },
    computed: {
      ...mapState(['validationOn', 'user', 'canEditKycInfo']),
      ...mapGetters(['canEditKycInfo']),
      inputId() {
        return 'input-' + Math.random().toString(36).substr(2, 10);
      },
      hasEditPermission() {

        if (!this.user) {
          return true;
        }
        return !this.disabled;
      },
      emitInputEvent() {
        // if (this.validationOn) {
        //   Utils.validate
        // }
        let calculated_value = this.inputValue;
        if (this.type == 'number' && this.inputValue) {
          if (this.max && this.inputValue > this.max) {
            calculated_value = +this.max;
            this.inputValue = calculated_value;
          }
          if (this.min && this.inputValue < this.min) {
            calculated_value = +this.min;
            this.inputValue = calculated_value;
          }
        }
        if (this.max_length && this.inputValue.toString().length > this.max_length) {
          this.addNotification({text: 'Input too long', type: 'error'})
        }
        this.$emit('input', calculated_value);
        this.$emit('inputChange', [this.key_ref, calculated_value]);
      }
    },
    watch: {
      value(newValue, oldValue) {
        this.inputValue = newValue;
      },
    },
  };
  </script>
  
  <style scoped lang='scss'>
  .styled-input {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .disabled {
    opacity: 0.7;
  }
  .password-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  label {
    position: absolute;
    top: 8px;
    left: 10px;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    font-weight: lighter;
    color: rgb(116 119 127);
    text-align: left;
    transition: all 0.3s;
    z-index: 2;
    &.focused {
      top: -5px;
      padding: 0 4px;
      background: white;
      left: 7px;
      font-size: 10px;
      color: #3D5873;
    }
  }
  
  input, select {
    background: unset;
    color: black;
    caret-color: black;
    outline: none;
    caret-color: #2b263a;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    border-radius: 5px;
    transition: padding 0.3s;
    border: 1px solid var(--border-color);
    &.select-elem {
      width: 100%;
    }
    &:focus {
      padding-top: 12px;
      padding-bottom: 8px;
      border: 1px solid var(--primary-color);
    }
    &.hasValue {
      padding-top: 12px;
      padding-bottom: 8px;
    }
  }
 
  
  
  .error-present {
    input, select {
      border-color: red;
      border-width: 1.5px;
    }
  }
  .light_label {
    font-size: 13px;
    margin-bottom: 0;
  }
  .error {
    color: red;
    text-align: left;
    padding-left: 10px;
    font-size: 10px;
  }
  input[type="checkbox"] {
    margin-right: auto;
  }
  </style>