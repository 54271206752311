<template>
    <div class="container">
        <div class="header">
            <text v-if="view=='forConfirmation'"> 
                Action Required 
            </text>

            <text v-else> 
                History View 
            </text>

            
            <div class="cross" @click="closePopup">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="info-holder w-full flex gap-5 column main-container" >
            <div>
                <div class="line-label">Date:</div>
                <div class="info-line">{{ renderTime(reviewAction.datetime) }} </div>
                <div class="line-label">Changes made by:</div>
                <div class="info-line">{{ reviewAction.user_name }} </div>
                
                <div v-if="reviewAction.handled_at" class="line-label" style='margin-top:20px;'>Handled At:</div>
                <div v-if="reviewAction.handled_at" class="info-line">{{ renderTime(reviewAction.handled_at)}}</div>

                <div v-if="reviewAction.handled_by_name" class="line-label">Handled By:</div>
                <div v-if="reviewAction.handled_by_name" class="info-line">{{ reviewAction.handled_by_name}}</div>
                
                <div v-if="view == 'forHistory'" class="line-label">Action Taken:</div>
                <div v-if="view == 'forHistory'" class="info-line">{{ reviewAction.actionTaken}}</div>
            </div>
            <div v-if="fileToShow">
                <p class="header-small">Account manager last comment since {{renderTime(fileToShow.comment_data_handler_at)}}</p>

                <CommentSection 
                    attrId='comment_data_handler'
                    class="markdown-content small"
                    :class="{disabled: true}"
                    :disabled="true"
                    :commentSection="fileToShow.comment_data_handler"
                /> 
            </div>
            <div v-if="reviewAction.kyc_file && fileToShow && reviewAction.actionType == 'remove' ">
                <div class=" w-cover current-version flex-1 flex column">
                    <h3 class="header-small">Current Version</h3>
                    <UploaderTable 

                        :disabled="true"
                        :key="`current-${fileToShow.documentType}-${subsidiary_id}`"
                        :filePresent="fileToShow.file"    
                        :headerText="getDocumentType"
                        :file="fileToShow.file"  
                        :title="''"
                        :fileType="fileToShow.fileType"
                        :index="fileToShow._id"  
                        :fiscal_year_ending_on="fileToShow.fiscal_year_ending_on"
                        :bank_name="fileToShow.bank_name"
                        :year_title="'year_title'"
                        :year="fileToShow.year"
                        :subsidiary_id="fileToShow.subsidiary_id"
                        :second_line_normal="'second_line_normal'"
                        :issueDateValue="fileToShow.dateIssued"  
                        :label="'label'"
                        :fullWidth="true"
                        :download_available="PermissionChecker.canDownloadFiles()"
                        
                        />
                </div>
            
            </div>
            <div class='flex gap-40 w-cover padding-20' v-else-if="reviewAction.kyc_file && fileToShow">
                <div class="current-version flex-1 flex column" v-if="reviewAction.actionTaken != 'initiated'">
                    <h3 class="header-small">
                    
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                        
                    </h3>
                    <UploaderTable 
                        :disabled="true"
                        :key="`current-${fileToShow.documentType}-${subsidiary_id}`"
                        :filePresent="fileToShow.file"    
                        :headerText="getDocumentType"
                        :file="fileToShow.file"  
                        :title="''"
                        :fileType="fileToShow.fileType"
                        :index="fileToShow._id"  
                        :fiscal_year_ending_on="fileToShow.fiscal_year_ending_on"
                        :year_title="'year_title'"
                        :year="fileToShow.year"
                        :bank_name="fileToShow.bank_name"
                        :subsidiary_id="fileToShow.subsidiary_id"
                        :second_line_normal="'second_line_normal'"
                        :issueDateValue="fileToShow.dateIssued"  
                        :label="'label'"
                        :fullWidth="true"
                        :download_available="PermissionChecker.canDownloadFiles()"

                        />
                </div>
                <div class="proposed-changes flex-1">
                    <h3 class="header-small">
                        <text v-if="reviewAction.actionTaken == 'initiated'">Initial</text> 
                        <text v-else>Adjusted</text> 
                    Version</h3>
                    <UploaderTable 
                        :disabled="true"
                        :key="`current-${fileToShow.fileType}-${subsidiary_id}`"
                        :filePresent="JSON.parse(reviewAction.fileInfo)?.new"    
                        :headerText="getDocumentType"
                        :file="JSON.parse(reviewAction.fileInfo)?.new"  
                        :title="title"
                        :fileType="fileToShow.fileType"
                        :index="fileToShow._id"  
                        :fiscal_year_ending_on="JSON.parse(reviewAction.fields)?.fiscal_year_ending_on?.new"
                        :year_title="'year_title'"

                        :bank_name="JSON.parse(reviewAction.fields)?.bank_name?.new"
                        :year="JSON.parse(reviewAction.fields)?.year?.new" 
                        :subsidiary_id="fileToShow.subsidiary_id"
                        :second_line_normal="'second_line_normal'"
                        :issueDateValue="JSON.parse(reviewAction.fields)?.dateIssued?.new"  
                        :label="'label'"
                        disabled="true"
                        :fullWidth="true"
                        :download_available="PermissionChecker.canDownloadFiles()"

                    />
                </div>
            </div>
            <div class='flex gap-40 w-cover padding-20' v-else-if="reviewAction.person">
                <div class="current-version flex-1 flex column" v-if="personToShow && reviewAction.actionType == 'remove'">
                    <PersonComp
                        v-if="reviewAction.ubo_related"
                        :key="`${reviewAction.ubo_related}_person_view_`"
                        :person="personToShow"
                        ubo_person="true"
                        :source_of_funds="true"
                        :source_of_wealth="true"
                        :bank_reference_letter="true"
                        :google_search="true"
                        :world_check="true"
                        :preview="true"
                        :canDownloadFiles="PermissionChecker.canDownloadFiles()"


                    ></PersonComp>
                    <PersonComp
                        v-else
                        :key="`${personToShow.id}_person_view`"
                        :person="personToShow"
                        :isOfficial="true"
                        :bank_reference_letter="true"
                        :world_check="true"
                        :google_search="true"
                        :preview="true"
                        :canDownloadFiles="PermissionChecker.canDownloadFiles()"


                    ></PersonComp>
                </div> 
                <div class="flex  flex-1 gap-40 row" v-if="personToShow && reviewAction.actionType != 'remove'">
                    <div class="current-version flex-1 flex column" v-if="personToShow && reviewAction.actionTaken != 'initiated'">
                    <h3 class="header-small">
                        <text v-if="view == 'forConfirmation'">Current Version</text>
                        <text v-else>Previous Version</text>
                    </h3>
                    <div class="info-holder">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            :disabled="true"
                            class="country-select flex-1"
                            label="Origin"
                            type="options"
                            :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                            theme="light"
                            light_label="true"
                            :value="personToShow.location_type.value"
                            :class="{changes: localFields?.location_type?.new != undefined}"

                        ></InpComponent>
                        <InpComponent
                            v-if="reviewAction.ubo_related"
                            :disabled="true"
                            class="flex-1"
                            label="Percentage of shares held"
                            type="number"
                            :light_label="true"
                            theme="light"
                            :class="{changes: localFields?.percentage_held?.new != undefined}"
                            :value="personToShow.percentage_held.value"
                            disabled="true"
                        ></InpComponent>
                        <InpComponent
                            class="flex-1"
                            label="Title"
                            :class="{changes: localFields?.title?.new != undefined}"
                            v-if="!reviewAction.ubo_related"
                            type="options"
                            :options="[
                                { value: 'Director', text: 'Director' },
                                { value: 'Secretary', text: 'Secretary' },
                                { value: 'MD', text: 'MD' },
                                { value: 'Registered Agent', text: 'Registered Agent' },
                            ]"
                            :light_label="true"
                            theme="light"
                            :value="personToShow.title.value"
                            disabled="true"
                        ></InpComponent>
                        <div class="flex-1">
                            <div class="passport-fields file-area">
                            <p class="field-label">Passport fields</p>
                            
                            <InpComponent
                                label="First Name"
                                type="text"
                                :light_label="true"
                                theme="light"
                                :class="{changes: localFields?.firstName?.new != undefined}"

                                :disabled="true"
                                :value="personToShow.passport_file.firstName.value"
                            ></InpComponent>
                            
                                <InpComponent
                                    label="Last Name"
                                    :class="{changes: localFields?.lastName?.new != undefined}"
                                    type="text"
                                    :light_label="true"
                                    theme="light"
                                    :disabled="true"
                                    :value="personToShow.passport_file.lastName.value"
                                    ></InpComponent>
                                
                                <InpComponent
                                    label="Date of Birth"
                                    type="date"
                                    :class="{changes: localFields?.dateOfBirth?.new != undefined}"
                                    :light_label="true"
                                    :disabled="true"
                                    theme="light"
                                    :value="personToShow.passport_file.dateOfBirth.value">
                                </InpComponent>

                                <InpComponent
                                    label="Passport Number"
                                    type="number"
                                    :class="{changes: localFields?.passportNumber?.new != undefined}"
                                    :light_label="true"
                                    :disabled="true"
                                    theme="light"
                                    :value="personToShow.passport_file.passportNumber.value"
                                ></InpComponent>
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: localFields?.passportCountry?.new != undefined}"
                                    :disabled="true"
                                    theme="light"
                                    :light_label="true"
                                    :value="personToShow.passport_file.passportCountry.value"
                                ></InpComponent>
                                <InpComponent
                                    label="Issue Date"
                                    type="date"
                                    :class="{changes: localFields?.dateIssued?.new != undefined}"
                                    :disabled="true"
                                    :light_label="true"
                                    theme="light"
                                    :value="personToShow.passport_file.dateIssued.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Expiry Date"
                                    :class="{changes: localFields?.dateExpiry?.new != undefined}"
                                    type="date"
                                    :light_label="true"
                                    :disabled="true"
                                    theme="light"
                                    :value="personToShow.passport_file.dateExpiry.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Taxpayer Identification Number"
                                    type="number"
                                    :light_label="true"
                                    :class="{changes: localFields?.tinNumber?.new != undefined}"
                                    :disabled="true"
                                    theme="light"
                                    :value="personToShow.passport_file.tinNumber.value"
                                ></InpComponent>
                            </div>
                        </div>
                        <div class="file-area flex-1">
                            <p class="field-label">Address fields</p>
                            <InpComponent
                                label="Address Line 1"
                                type="text"
                                light_label="true"
                                :class="{changes: localFields?.address_line_1?.new != undefined}"
                                disabled="true"
                                theme="light"
                                :value="personToShow.address_file.address_line_1.value"
                            ></InpComponent>

                            <InpComponent
                                label="Address Line 2 (optional)"
                                type="text"
                                :class="{changes: localFields?.address_line_2?.new != undefined}"
                                light_label="true"
                                disabled="true"
                                theme="light"
                                :value="personToShow.address_file.address_line_2.value"
                            ></InpComponent>

                            <InpComponent
                                label="City / Town"
                                type="text"
                                light_label="true"
                                :class="{changes: localFields?.city?.new != undefined}"
                                disabled="true"
                                theme="light"
                                :value="personToShow.address_file.city.value"
                            ></InpComponent>

                            <InpComponent
                                class="country-select"
                                label="Country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                :class="{changes: localFields?.address_country?.new != undefined}"
                                theme="light"
                                disabled="true"
                                light_label="true"
                                :value="personToShow.address_file.address_country.value"
                            ></InpComponent>

                            <InpComponent
                                label="Zip"
                                type="number"
                                disabled="true"
                                :class="{changes: localFields?.zip?.new != undefined}"
                                theme="light"
                                light_label="true"
                                :value="personToShow.address_file.zip.value"
                            ></InpComponent>

                            <InpComponent
                                v-if="personToShow.address_file.address_date_issues"
                                class="issue-date"
                                label="Issue Date"
                                :class="{changes: localFields?.address_date_issues?.new != undefined}"
                                type="date"
                                :light_label="true"
                                theme="light"
                                disabled="true"
                                :value="personToShow.address_file.address_date_issues.value"
                            ></InpComponent>
                        
                        </div>
                        <div class='file-area flex-1' v-if="
                            (
                                personToShow.location_type.value != 'local'
                            )">
                            <p class="field-label">Second document fields</p>
                            <div class="passport-fields ">

                                <InpComponent
                                    label="Document Type"
                                    type="options"
                                    :light_label="true"
                                    theme="light"
                                    :kyc_validate="true"
                                    :class="{changes: localFields?.secondPassportType?.new != undefined}"
                                    disabled="true"

                                    :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                                    :value="personToShow.secondPassport_file.secondPassportType.value"
                                ></InpComponent>

                                <InpComponent
                                    :label="'Document Number'"
                                    type="number"
                                    :disabled="disabled_number"
                                    :light_label="true"
                                    theme="light"
                                    :class="{changes: localFields?.secondPassportNumber?.new != undefined}"

                                    disabled="true"
                                    :value="personToShow.secondPassport_file.secondPassportNumber.value"
                                ></InpComponent>
                                
                                <InpComponent
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: localFields?.secondPassportCountry?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="personToShow.secondPassport_file.secondPassportCountry.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Issue Date"
                                    type="date"
                                    :light_label="true"
                                    :class="{changes: localFields?.secondDateIssued?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="personToShow.secondPassport_file.secondDateIssued.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Expiry Date"
                                    type="date"
                                    :light_label="true"
                                    :class="{changes: localFields?.secondDateExpiry?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="personToShow.secondPassport_file.secondDateExpiry.value"
                                ></InpComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="proposed-changes flex-1" v-if="personToShow && localFields">
                    <h3 class="header-small">New Version</h3>
                    <div class="info-holder">
                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                            <InpComponent
                                class="country-select flex-1"
                                label="Origin"
                                type="options"
                                :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                                theme="light"
                                disabled="true"
                                light_label="true"
                                :value="localFields?.location_type?.new || personToShow.location_type.value"
                                :class="{changes: localFields?.location_type?.new != undefined}"

                            ></InpComponent>
                            <InpComponent
                                class="flex-1"
                                label="Percentage of shares held"
                                v-if="reviewAction.ubo_related"

                                :class="{changes: localFields?.percentage_held?.new != undefined}"
                                type="number"
                                :light_label="true"
                                theme="light"
                                :value="localFields?.percentage_held?.new || personToShow.percentage_held.value"
                                disabled="true"
                            ></InpComponent>
                            <InpComponent
                                class="flex-1"
                                :class="{changes: localFields?.title?.new != undefined}"
                                v-if="!reviewAction.ubo_related"

                                label="Title"
                                type="options"
                                :options="[
                                    { value: 'Director', text: 'Director' },
                                    { value: 'Secretary', text: 'Secretary' },
                                    { value: 'MD', text: 'MD' },
                                    { value: 'Registered Agent', text: 'Registered Agent' },
                                ]"
                                :light_label="true"
                                theme="light"
                                :value="localFields?.title?.new || personToShow.title.value"
                                disabled="true"
                            ></InpComponent>
                            <div class="flex-1">
                                <div class="passport-fields file-area">
                                <p class="field-label">Passport fields</p>
                                
                                <InpComponent
                                        label="First Name"
                                        :class="{changes: localFields?.firstName?.new != undefined}"
                                        type="text"
                                        :light_label="true"
                                        theme="light"
                                        disabled="true"
                                        :value="localFields?.firstName?.new || personToShow.passport_file.firstName.value"
                                    ></InpComponent>
                                
                                    <InpComponent
                                        :class="{changes: localFields?.lastName?.new != undefined}"
                                        label="Last Name"
                                        type="text"
                                        :light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.lastName?.new || personToShow.passport_file.lastName.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Date of Birth"
                                        type="date"
                                        :class="{changes: localFields?.dateOfBirth?.new != undefined}"
                                        :light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.dateOfBirth?.new || personToShow.passport_file.dateOfBirth.value">
                                    </InpComponent>

                                    <InpComponent
                                        label="Passport Number"
                                        type="number"
                                        :class="{changes: localFields?.passportNumber?.new != undefined}"
                                        :light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.passportNumber?.new || personToShow.passport_file.passportNumber.value"
                                    ></InpComponent>
                                    <InpComponent
                                        class="country-select"
                                        :class="{changes: localFields?.passportCountry?.new != undefined}"
                                        label="Issue country"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        theme="light"
                                        disabled="true"
                                        :light_label="true"
                                        :value="localFields?.passportCountry?.new || personToShow.passport_file.passportCountry.value"
                                    ></InpComponent>
                                    <InpComponent
                                        label="Issue Date"
                                        :class="{changes: localFields?.dateIssued?.new != undefined}"
                                        type="date"
                                        :light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.dateIssued?.new || personToShow.passport_file.dateIssued.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Expiry Date"
                                        type="date"
                                        :class="{changes: localFields?.dateExpiry?.new != undefined}"
                                        :light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.dateExpiry?.new || personToShow.passport_file.dateExpiry.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Taxpayer Identification Number"
                                        type="number"
                                        :light_label="true"
                                        disabled="true"
                                        :class="{changes: localFields?.tinNumber?.new != undefined}"
                                        theme="light"
                                        :value="localFields?.tinNumber?.new || personToShow.passport_file.tinNumber.value"
                                    ></InpComponent>
                                </div>
                            </div>
                            <div class="file-area flex-1">
                                <p class="field-label">Address fields</p>
                                <InpComponent
                                    label="Address Line 1"
                                    type="text"
                                    light_label="true"
                                    :class="{changes: localFields?.address_line_1?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="localFields?.address_line_1?.new || personToShow.address_file.address_line_1.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Address Line 2 (optional)"
                                    type="text"
                                    :class="{changes: localFields?.address_line_2?.new != undefined}"
                                    light_label="true"
                                    disabled="true"
                                    theme="light"
                                    :value="localFields?.address_line_2?.new || personToShow.address_file.address_line_2.value"
                                ></InpComponent>

                                <InpComponent
                                    label="City / Town"
                                    type="text"
                                    light_label="true"
                                    :class="{changes: localFields?.city?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="localFields?.city?.new || personToShow.address_file.city.value"
                                ></InpComponent>

                                <InpComponent
                                    class="country-select"
                                    label="Country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: localFields?.address_country?.new != undefined}"
                                    theme="light"
                                    disabled="true"
                                    light_label="true"
                                    :value="localFields?.address_country?.new || personToShow.address_file.address_country.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Zip"
                                    type="number"
                                    disabled="true"
                                    :class="{changes: localFields?.zip?.new != undefined}"
                                    theme="light"
                                    light_label="true"
                                    :value="localFields?.zip?.new || personToShow.address_file.zip.value"
                                ></InpComponent>

                                <InpComponent
                                    v-if="personToShow.address_file.address_date_issues"
                                    class="issue-date"
                                    label="Issue Date"
                                    :class="{changes: localFields?.address_date_issues?.new != undefined}"
                                    type="date"
                                    :light_label="true"
                                    theme="light"
                                    disabled="true"
                                    :value="localFields?.address_date_issues?.new || personToShow.address_file.address_date_issues.value"
                                ></InpComponent>
                            
                            </div>
                            <div class='file-area flex-1' v-if="(localFields?.location_type?.new != 'local' &&  personToShow.location_type.value != 'local')">
                                <p class="field-label">Second document fields</p>
                                <div class="passport-fields ">

                                    <InpComponent
                                        label="Document Type"
                                        type="options"
                                        :light_label="true"
                                        theme="light"
                                        disabled="true"

                                        :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                                        :value="localFields?.secondPassportType?.new || personToShow.secondPassport_file.secondPassportType.value"
                                    ></InpComponent>

                                    <InpComponent
                                        :label="'Document Number'"
    
                                        type="number"
                                        
                                        :disabled="true"
                                        theme="light"
                                        :value="localFields?.secondPassportNumber?.new || personToShow.secondPassport_file.secondPassportNumber.value"
                                    ></InpComponent>
                                    
                                    <InpComponent
                                        class="country-select"
                                        label="Issue country"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        disabled="true"
                                        theme="light"
                                        :light_label="true"
                                        :value="localFields?.secondPassportCountry?.new || personToShow.secondPassport_file.secondPassportCountry.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Issue Date"
                                        type="date"
                                        :light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.secondDateIssued?.new || personToShow.secondPassport_file.secondDateIssued.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Expiry Date"
                                        type="date"
                                        :light_label="true"
                                        theme="light"
                                        :value="localFields?.secondDateIssued?.new || personToShow.secondPassport_file.secondDateExpiry.value"
                                    ></InpComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                
            </div>
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.legalEntity">
                <div class="current-version flex-1 flex column" v-if="entityToShow && reviewAction.actionType == 'remove'">
                    <EntityView
                        :key="`entity_view_${entityToShow.id}`"
                        :entity="entityToShow"
                        :mbd="objectMap.mbds[entityToShow.mbd_related]"
                        :canDownloadFiles="PermissionChecker.canDownloadFiles()"
                        
                    ></EntityView>
                </div> 
                <div class="flex flex-1 gap-40 row" v-if="entityToShow && reviewAction.actionType != 'remove'">
                    <div class="current-version flex-1 flex column" v-if="entityToShow && reviewAction.actionTaken != 'initiated'">
                        <h3 class="header-small">
                            <text v-if="view == 'forConfirmation'">Current Version</text>
                            <text v-else>Previous Version</text>
                        </h3>

                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                                <InpComponent 
                                    :disabled="true"
                                    class="country-select"
                                    label="Supervised"
                                    type="options"
                                    :options="[{value: false, text: 'No'}, {value: true, text: 'Yes'}]"
                                    theme="light"
                                    origin="kyc-section"
                                    disabled="true"
                                    light_label="true"
                                    :key_ref="'supervised'"
                                    :value="entityToShow.supervised.value"
                                    :class="{changes: localFields?.supervised?.new != undefined}"

                                ></InpComponent>
                                <InpComponent
                                    :disabled="true"
                                    class="name-input"
                                    label="Name of the Entity"
                                    type="text"
                                    disabled="true"
                                    light_label="true"
                                    theme="light"
                                    :class="{changes: localFields?.name?.new != undefined}"
                                    :value="entityToShow.name.value"
                                ></InpComponent>
                            <div class="file-area flex-1">
                                <p class="field-label">Address fields</p>
                                <InpComponent
                                    :disabled="true"
                                    label="Address Line 1"
                                    type="text"
                                    light_label="true"
                                    :class="{changes: localFields?.address_line_1?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="entityToShow.address.address_line_1.value"
                                ></InpComponent>

                                <InpComponent
                                    :disabled="true"
                                    label="Address Line 2 (optional)"
                                    type="text"
                                    :class="{changes: localFields?.address_line_2?.new != undefined}"
                                    light_label="true"
                                    disabled="true"
                                    theme="light"
                                    :value="entityToShow.address.address_line_2.value"
                                ></InpComponent>

                                <InpComponent
                                    :disabled="true"
                                    label="City / Town"
                                    type="text"
                                    light_label="true"
                                    :class="{changes: localFields?.city?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="entityToShow.address.city.value"
                                ></InpComponent>

                                <InpComponent
                                    :disabled="true"
                                    class="country-select"
                                    label="Country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    :class="{changes: localFields?.address_country?.new != undefined}"
                                    theme="light"
                                    disabled="true"
                                    light_label="true"
                                    :value="entityToShow.address.address_country.value"
                                ></InpComponent>

                                <InpComponent
                                    :disabled="true"
                                    label="Zip"
                                    type="number"
                                    disabled="true"
                                    :class="{changes: localFields?.zip?.new != undefined}"
                                    theme="light"
                                    light_label="true"
                                    :value="entityToShow.address.zip.value"
                                ></InpComponent>       
                            </div>
                        </div>
                    </div>

                    <div class="proposed-changes flex-1 flex column" v-if="entityToShow">
                        <h3 class="header-small">New Version</h3>

                        <div class="info-holder">
                            <p class="field-label">General Fields</p>

                                <InpComponent 
                                    class="country-select"
                                    label="Supervised"
                                    type="options"
                                    :options="[{value: false, text: 'No'}, {value: true, text: 'Yes'}]"
                                    theme="light"
                                    disabled="true"
                                    light_label="true"
                                    :value="localFields?.supervised?.new || entityToShow.supervised.value"
                                    :class="{changes: localFields?.supervised?.new != undefined}"
                                ></InpComponent>
                                <InpComponent
                                    class="name-input"
                                    label="Name of the Entity"
                                    type="text"
                                    light_label="true"
                                    theme="light"
                                    disabled="true"
                                    :class="{changes: localFields?.name?.new != undefined}"
                                    :value="localFields?.name?.new || entityToShow.name.value"
                                ></InpComponent>
                                <div class="file-area flex-1">
                                    <p class="field-label">Address fields</p>
                                    <InpComponent
                                        label="Address Line 1"
                                        type="text"
                                        light_label="true"
                                        :class="{changes: localFields?.address_line_1?.new != undefined}"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.address_line_1?.new || entityToShow.address.address_line_1.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Address Line 2 (optional)"
                                        type="text"
                                        :class="{changes: localFields?.address_line_2?.new != undefined}"
                                        light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.address_line_2?.new || entityToShow.address.address_line_2.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="City / Town"
                                        type="text"
                                        light_label="true"
                                        :class="{changes: localFields?.city?.new != undefined}"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.city?.new || entityToShow.address.city.value"
                                    ></InpComponent>

                                    <InpComponent
                                        class="country-select"
                                        label="Country"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        :class="{changes: localFields?.address_country?.new != undefined}"
                                        theme="light"
                                        disabled="true"
                                        light_label="true"
                                        :value="localFields?.address_country?.new || entityToShow.address.address_country.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Zip"
                                        type="number"
                                        disabled="true"
                                        :class="{changes: localFields?.zip?.new != undefined}"
                                        theme="light"
                                        light_label="true"
                                        :value="localFields?.zip?.new || entityToShow.address.zip.value"
                                    ></InpComponent>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.ubo_related">
                <div class="current-version flex-1 flex column" v-if="uboToShow && reviewAction.actionType == 'remove'">
                    <p class=field-label>Delete action requested on the following element</p>
                    <UBO
                        :key="`index_ubo-${uboToShow.id}`"
                        :ubo="uboToShow"
                        :location_type="uboToShow.location_type"
                        :id="uboToShow.id"
                        :preview="true"
                        :canDownloadFiles="PermissionChecker.canDownloadFiles()"

                    ></UBO>
                </div>
                
                <div class="current-version flex-1 flex column" v-if="currentUBO && reviewAction.actionType != 'remove'">
                    <p class=field-label>Delete action requested on the following element</p>
                    <UBO
                        :key="`index_ubo-${currentUBO.id}`"
                        :ubo="currentUBO.struct"
                        :location_type="currentUBO.location_type"
                        :id="currentUBO.id"
                        :preview="true"
                        :canDownloadFiles="PermissionChecker.canDownloadFiles()"

                    ></UBO>
                </div>
            </div>
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.mbd_related">
                <div class="current-version flex-1 flex column" v-if="mbToShow && reviewAction.actionType == 'remove'">
                    <p class=field-label>Delete action requested on the following element</p>

                    <ManagingDirrector 
                        :key="`index_mbd-${mbToShow.id}`"
                        :mbd="mbToShow"
                        class="managing-director"
                        :preview="true"
                        :canDownloadFiles="PermissionChecker.canDownloadFiles()"

                    />
                </div> 
          
                <div class="flex flex-1 gap-40 row" v-if="mbToShow && reviewAction.actionType != 'remove'">
                   
                    <div class="current-version flex-1 flex column" v-if="mbToShow && reviewAction.actionTaken != 'initiated'">
                        <h3 class="header-small">
                            <text v-if="view == 'forConfirmation'">Current Version</text>
                            <text v-else>Previous Version</text>
                        </h3>
                        
                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                            <InpComponent
                                class="country-select"
                                label="Origin"
                                type="options"
                                :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                disabled="true"

                                :class="{changes: localFields?.location_type?.new != undefined}"
                                :value="mbToShow['location_type'].value"

                            ></InpComponent>
                            <InpComponent 
                                v-if="mbToShow['location_type'].value === 'abroad'"
                                class="country-select"
                                label="Director Type"
                                type="options"
                                :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                
                                :class="{changes: localFields?.entity_type?.new != undefined}"
                                :value="mbToShow['entity_type'].value"
                            ></InpComponent>
                        </div>
                    </div>

                    <div class="proposed-changes flex-1 flex column" v-if="mbToShow">
                        <h3 class="header-small">New Version</h3>

                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                            <InpComponent
                                class="country-select"
                                label="Origin"
                                type="options"
                                :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                :class="{changes: localFields?.location_type?.new != undefined}"
                                :value="localFields?.location_type?.new || mbToShow['location_type'].value"

                            ></InpComponent>
                            <InpComponent 
                                v-if="mbToShow['location_type'].value === 'abroad'"
                                class="country-select"
                                label="Director Type"
                                type="options"
                                :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                :class="{changes: localFields?.entity_type?.new != undefined}"
                                :value="localFields?.entity_type?.new || mbToShow['entity_type'].value"
                            ></InpComponent>
                        </div>
                    </div>

                </div>
            </div> 
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.listingService">

                <div class="current-changes flex-1 flex column" v-if="currentListing">
                    <h3 class="header-small">Current Version</h3>

                    <div class="info-holder">
                        <textarea
                            class="textarea"
                            :value="localFields?.text_val?.current"
                            disabled="true"
                        ></textarea>
                    </div>
                </div>
                <div class="proposed-changes flex-1 flex column" v-if="currentListing">
                    <h3 class="header-small">New Version</h3>
                    <div class="info-holder">
                        <textarea
                            class="textarea"
                            :value="localFields?.text_val?.new"
                            disabled="true"
                        ></textarea>
                    </div>
                </div>
            </div>





            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.subsidiary">
                <div class="current-version flex-1 flex column" v-if="reviewAction.actionType == 'remove' && sourceObjectState">
                    <div>

                    </div>
                    <p class=field-label>Delete action requested on the following Subsidiary</p>

                    <div class="line-label">Subsidiary Name</div>
                    <div class="info-line">{{ sourceObjectState.name }} </div>

                    <div class="line-label">Last Annual Account on File</div>
                    <div class="info-line">{{ sourceObjectState.last_annual_account_on_file }} </div>

                    <div class="line-label">Comment upon adding</div>
                    <div class="info-line" v-html="sourceObjectState.comment"></div>

                </div> 
          
                <div class="flex flex-1 gap-40 row" v-if="sourceObjectState && reviewAction.actionType != 'remove'">
                   
                    <div class="current-version flex-1 flex column" v-if="sourceObjectState && reviewAction.actionTaken != 'initiated'">
                        <h3 class="header-small">
                            <text v-if="view == 'forConfirmation'">Current Version</text>
                            <text v-else>Previous Version</text>
                        </h3>
                        
                        <div class="info-holder">
                            <div class="info-holder">
                                <div class="line-label">Subsidiary Name</div>
                                <div class="info-line" :class="{changes: localFields?.name?.new != undefined}">{{ localFields?.name?.current || sourceObjectState.name || '----'}} </div>
                                <div class="line-label">Last Annual Account on File</div>
                                <div class="info-line" :class="{changes: localFields?.last_annual_account_on_file?.current != undefined}">{{ localFields?.last_annual_account_on_file?.current || sourceObjectState.name || '----'}} </div>
                            </div>
                        </div>
                    </div>

                    <div class="proposed-changes flex-1 flex column" v-if="sourceObjectState">
                        <h3 class="header-small">New Version</h3>

                        <div class="info-holder">
                            <div class="info-holder">
                                <div class="line-label">Subsidiary Name</div>
                                <div class="info-line" :class="{changes: localFields?.name?.new != undefined}">{{ localFields?.name?.new || sourceObjectState.name || '----' }} </div>
                                <div class="line-label">Last Annual Account on File</div>
                                <div class="info-line" :class="{changes: localFields?.last_annual_account_on_file?.new != undefined}">{{ localFields?.last_annual_account_on_file?.new || sourceObjectState.last_annual_account_on_file || '----'}} </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div> 
      
            <div v-else>

                source obj__ {{ sourceObjectState }}__

                <br>
                <br>
                <br>
                <br>

                currentFile_{{ currentFile }}_
              
                <br>
                <br>
                <br>

                filetoShow_{{ fileToShow }}_

                <br>
                <br>
                <br>
                <br>
                <br>
                <br>

                {{reviewAction}}
            </div>
        </div>
        <div v-if="view=='forConfirmation' && (PermissionChecker.approveRequestChanges() || PermissionChecker.rejectRequestChanges())" class="add-new-ticket-info flex gap-5">
            <button v-if="PermissionChecker.rejectRequestChanges()" class="transparent cancel-btn" @click="denyChanges()">Deny</button>
            <button v-if="PermissionChecker.approveRequestChanges()" @click="approveChanges()">Approve</button>
        </div>

        <!-- <PopupCover v-if="deleteObject" >
            <ConfirmationPopup 
                @close="popupConfirm"
                @confirm="popupConfirm"
                @cancel="() => deleteObject = !deleteObject"
                :title="'Delete Subsidiary'"
                :message="'Are you sure you want to delete this subsidiary?'"
            />
        </PopupCover> -->
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import Utils from '../utils/index.js'
    import UBO from '@/views/UBO.vue'

    import { PermissionChecker } from '@/utils/iam_utils.js'
    import PersonComp from '@/components/PersonComp.vue'
    import EntityView from '@/components/EntityView.vue'
    import ManagingDirrector from '@/components/ManagingDirrector.vue'
    import InpComponent from '@/components/InpComponent.vue';
    import AddressComp from '@/components/AddressComp.vue';
    import CommentSection from '@/views/CommentSection.vue';
    import PopupCover from '@/components/PopupCover.vue';
    import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
    import API from '@/api/index';
    import dayjs from 'dayjs';
    import { getPersonStructure, getLegalEntityStructure, getMbodStructure } from '../utils/kyc_utils.js';
    import UploaderTable from '@/components/UploaderTable.vue';
    function renderTime(time) {
        if (!time) return '';
        return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
    }
    const store = useStore();
    const emit = defineEmits(['close', 'deleteConfirm']);
    const objectMap = computed(() => store.state.objectMap);
    const dataStructure = computed(() => store.state.dataStructureStore);
    const props = defineProps([
        'reviewAction', 
        'view',
    ])
    const localFields = ref({});
    if (props.reviewAction.fields) {
        localFields.value = JSON.parse(props.reviewAction.fields);
    }
    const currentFile = ref(null);
    const fileToShow = ref(null);
    const curentPerson = ref(null);
    const currentEntity = ref(null);
    const currentMD = ref(null);
    const currentListing = ref(null);
    const currentUBO = ref(null);

    const uboToShow = ref(null);
    const mbToShow = ref(null);
    const entityToShow = ref(null);
    const prevEntity = ref(null);
    const sourceObjectState = ref(null);
    const personToShow = ref(null);
    const prevFile = ref(null);

    
    const getDocumentType = computed(() => {
        if (currentFile.value) {
            if (currentFile.value.documentType == 'subsidiaries') {
                return `${currentFile.value.name} annual accounts - last book year`
            }
            if (currentFile.value.documentType == 'scfba') {
                return `Signature card for bank account`
            }
            if (currentFile.value.documentType == 'cosbaof') {
                return 'Copy of signed bank account opening forms'
            }

            return currentFile.value.title;
        }
    })
      

    onMounted(() => {
        let prevPerson = null;
        if (props.reviewAction.sourceObjectState) {
            sourceObjectState.value = JSON.parse(props.reviewAction.sourceObjectState);
        }
        if (props.reviewAction.kyc_file) {
            currentFile.value = objectMap.value.files[props.reviewAction.kyc_file];

            if (sourceObjectState.value) {
                prevFile.value = {
                    file: {
                        documentType: sourceObjectState.value.documentType,
                        filename: sourceObjectState.value.filename,
                        id: sourceObjectState.value.id,
                        serverName: sourceObjectState.value.serverName
                    },
                    fileType: sourceObjectState.value.fileType,
                    year: sourceObjectState.value.year,
                    fiscal_year_ending_on: sourceObjectState.value.fiscal_year_ending_on,
                    comment_data_handler: sourceObjectState.value.comment_data_handler,
                    comment_compliance_officer: sourceObjectState.value.comment_compliance_officer,
                    comment_supervisor: sourceObjectState.value.comment_supervisor,
                    id: sourceObjectState.value.id,
                }
            }
            
            fileToShow.value = currentFile.value;
            if (props.view != 'forConfirmation' && prevFile.value) {                
                fileToShow.value = prevFile.value;
            }
        }
        if (props.reviewAction.person) {
            curentPerson.value = objectMap.value.people[props.reviewAction.person];

            personToShow.value = curentPerson.value;
            if (props.view != 'forConfirmation' && sourceObjectState.value) {
                personToShow.value = getPersonStructure(null, sourceObjectState.value);
            }

        }
        if (props.reviewAction.legalEntity) {
            currentEntity.value = objectMap.value.entities[props.reviewAction.legalEntity];

            entityToShow.value = currentEntity.value;
            if (props.view != 'forConfirmation' && sourceObjectState.value) {
                entityToShow.value = getLegalEntityStructure(sourceObjectState.value);
            } 
        }
        if (props.reviewAction.ubo_related) {
            currentUBO.value = objectMap.value.ubos[props.reviewAction.ubo_related];

            uboToShow.value = currentUBO.value;
            if (props.view != 'forConfirmation' && sourceObjectState.value) {
                uboToShow.value = sourceObjectState.value;
            }
        }
        if (props.reviewAction.mbd_related) {
            currentMD.value = objectMap.value.mbds[props.reviewAction.mbd_related];
            mbToShow.value = currentMD.value;
            if (props.view != 'forConfirmation' && sourceObjectState.value) {
                mbToShow.value = getMbodStructure(sourceObjectState.value);
            }
        }
        if (props.reviewAction.listingService) {
            currentListing.value = dataStructure.value['textarea'];
        }
       
      
    })

    function closePopup() {
        emit('close')
    }
    async function approveChanges(){
        console.log('props.reviewAction', props.reviewAction._id)
        const response = await API.approveChanges({id: props.reviewAction._id});
        console.log('response', response)
        console.log('response', response.status)
        console.log('response status', response.status == 'success')
        if (response.status == 'success') {
            store.dispatch('addNotification', {
                type: 'success',
                text: 'Changes approved successfully'
            });
            emit('deleteConfirm')
            closePopup();
            store.state.approvedActions += 1;
            store.state.notificationsCount -= 1;
        }
    }
    async function denyChanges() {
        console.log('props.reviewAction', props.reviewAction._id, store.state.confirmationNeeded)

        const response = await API.denyChanges({id: props.reviewAction._id});
        console.log('response', response)
        console.log('response', response.status)
        console.log('response status', response.status == 'success')
        if (response.status == 'success') {
            console.log('file', response.file)
            store.dispatch('addNotification', {
                type: 'success',
                text: 'Changes denied successfully'
            });
            store.state.confirmationNeeded = store.state.confirmationNeeded.filter(el => el._id != props.reviewAction._id)
            store.state.notificationsCount -= 1;
            closePopup();
        }
    }
    

</script>


<style scoped lang="scss">

    .input-row {
        > * {
            flex: 1;
        }
    }
    .info-section-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: self-start;
    }
    .info-section {
        text-align: left;
        line-height: 18px;
    }
    .name {
        font-size: 14px;
        color: rgb(116 119 127);
    }
    .value {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }
    .field-label {
        font-size: 14px;
        color: black;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        text-align: left;
    }
    .button-delete {
        align-items: flex-end;
    }
    .header-small {
        text-align: left;
        color: black;
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
    }
    .header-of-comments {
        text-align: left;
        font-size: 16px;
        color: black;
        font-weight: 600;
        padding-left: 5px;
    }
    .generate-password {
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        height: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
        &:hover {
            cursor: pointer;
            background-color: var(--button-hover-color-blue);
        }

    }

    .main-container {
        max-height: 80vh;
        overflow: auto;
    }
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 1100px;
        max-width: 95vw;
        border: 1px solid var(--border-color);
        max-height: 100vh;
        overflow-y: auto;
        padding: 20px;
        box-sizing: border-box;
        max-height: 95vh;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        margin-left: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .info-line {
        color: black;
        text-align: left;
        font-weight: 600;
        margin: 0;
    }
    .line-label {
        text-align: left;
        font-weight: 600;
        margin: 0;
        margin-top: 5px;
        font-size: 12px;
        color: rgb(151, 151, 151);
    }
    .cross {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .proposed-changes {
        max-width: 50%;
        margin-left: auto;
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
       
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        border-radius: 10px;
        min-height: 150px;
        max-height: 300px;
        height: fit-content;
        width: 100%
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:deep(.ql-container){
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &.disabled {
            background-color: #f5f5f5;
            opacity: 0.6;
        }
    }
    .w-cover {
        width: 99%;
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }
    .info-section {
        width: 95%;
    }
    .comment {
        padding: 10px;
        background-color: #f5f5f5;
        border-radius: 5px;
        width: 100%;
        min-height: 60px;
        font-weight: 300;
        font-size: 14px;
        color: black;
        opacity: 0.6;
        border: 1px solid var(--border-color);
        &:deep(*) {
            margin: 0;
        }
    }
    .uploader-table {
        flex: 2;
    }
    .comment-info {
        flex: 3;
    }
    .info-holder {
        margin-bottom: 20px;
    }
</style>