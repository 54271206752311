<template>
    <div class="notification-item" :class="view">
        <div class="icon">
            <img src="@/assets/info-circle.svg"
            style="cursor: pointer;">
        </div>
        <div class="notiifcation-info-conteiner">
            <div class="notification-time">
                {{ renderTime(notification.datetime) }} by
                    <div class="user-name">
                    {{ notification.user_name }}
                </div>
            </div>
            <div class="notification-message">
                <div v-html="renderTitle(notification)"></div>
                
                <div class="flex gap-4 wrap" v-if="notification.fields || notification.fileInfo">
                    <div v-if="notification.fileInfo" class="change-item">
                        New file uploaded
                    </div>
                    <div v-for="change in Object.keys(JSON.parse(notification.fields))" class="change-item ">
                        {{ fieldToName(change) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>

import { ref, computed, onMounted, watch, defineEmits } from 'vue'

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

const props = defineProps(['notification', 'view'])



function renderTitle(action) {
    
    let prefix = 'Following change(s) was/were made to the '
    if (action.actionType == 'remove') {
        prefix = 'Delete request for the '
    }

    if (action.subsidiary) {
        return prefix + '<b>Subsidiary</b>'
    }
    else if (action.kyc_file) {
        let text = prefix + '<b>' + typeToName(action.documentType) + '</b>'
        return text
    }
    else if (action.person) {
        let text = prefix + '<b>Individual section</b>'
        if (action.ubo_related) {
            text += ' in the UBO section'
        }
        if (action.mbd_related) {
            text += ' in the Managing Director section'
        }
        return text
    }
    else if (action.legalEntity) {
        return (prefix + '<b>Entity</b>')
    }
    else if (action.ubo_related) {
        let text = prefix + '<b>UBO</b>'
        if (action.mbd_related) {
            text += ' for the Managing Director section'
        }
        return text
    }
    else if (action.mbd_related) {
        return (prefix + '<b>Managing Director Section </b>')
    }
    else if (action.listingService) {
        return (prefix + '<b>Listing of services provided by trust service provider to company</b>')
    }

    else {
        return 'Unknown objects' 
    }
};


function fieldToName(field) {
    const dataStructure = {
        'year': 'Year',
        'fiscal_year_ending_on': 'Fiscal Year Ending On',
        'dateIssued': 'Date Issued',
        'supervised': 'Supervised',
        'entity_type': 'Director Type',
        'location_type': 'Origin',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'text_val': 'Description',
        'tinNumber': 'Taxpayer Identification Number',
        'last_annual_account_on_file': 'Last Annual Account on File',
        'name': 'Name',
        'bank_name': 'Bank Name',
        'dateOfBirth': 'Date of Birth',
        'percentage_held': 'Percentage of Shares Held',
    }
    if (props.notification.documentType == 'kyc-reftcoc-5-2' && field == 'dateIssued') {
        return 'Incoporation Date'
    }
    return dataStructure[field] || field
}


function renderTime(time) {
  return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}

function typeToName(type) {


    const dataStructure = {
        'textarea': 'Listing of services provided by trust service provider to company',
        'kyc-ma-1': 'Management Agreement',
        'kyc-ppa-2': 'Principal Party Agreement',
        'kyc-feeibtbvdna-3': 'Foreign Exchange Exemption issued by the Bank van de Nederlandse Antillen',
        'kyc-lteabibtdoea-4': 'License to Establish a Business issued by the Department of Economic Affairs',
        'kyc-mlibtdoea-4-1': 'Management License issued by the Department of Economic Affairs',
        'kyc-aoi-5': 'Articles of Incorporation',
        'kyc-attaoi-5-1': 'Amendments to the Articles of Incorporation',
        'kyc-reftcoc-5-2': 'Recent Extract from the Chamber of Commerce',
        'kyc-oc-6': 'Organizational Chart',
        'kyc-sr-7': 'Shareholders\' register',
        'kyc-reftcoc-7-1': 'Share transfer agreement (after establishment)',
        'kyc-sof-8': 'Source of funds',

        'world_check': 'World Check',
        'google_search': 'Google Search',
        'source_of_funds': 'Source of Funds',
        'source_of_wealth': 'Source of Wealth',
        'passport_file': 'Certified copy of passport',
        'address_file': 'Certified copy of proof of address',
        'secondPassport_file': 'Certified copy of valid second ID',
        'bank_reference_letter': 'Bank reference letter',


        'entity_aoi': 'Articles of Incorporation',
        'entity_coc': 'Chamber of Commerce / Certificate of Incorporation',
        'entity_members': 'Share Register / Register of Members',
        'entity_rodo': 'Register of Directors(optional)',
        'entity_soi': 'Certificate of Incumbency',
        'entity_license': 'License',

  
        "ubos": [],
        'mbds': [],
        'tax-ruling': 'Tax Ruling',
        'cosbaof': 'Copy of signed bank account opening forms',
        'scfba': 'Signature card for bank account',
        'kyc-aa-y1-12': 'Annual Accounts last book year',
        'kyc-aa-y2-12': 'Annual Accounts second last book year',
        'kyc-dogmos-13': 'Discharge of General Meeting of Shareholders (last book year)',
        'kyc-fpt-14': 'Filing Profit Tax (last book year)',
        'subsidiaries': 'subsidiaries',
    }
    return dataStructure[type] || type

}


</script>

<style scoped lang="scss">


.notification-view {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white;
    margin: auto;
    padding: 20px;
    margin: 20px 40px;
    margin-top: 80px;
}
.notification-item {
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}
.forHistory {
    .change-item {
        background-color: #595b61;
        color: #f9f9f9;
    }
}
.notification-container {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white; 
}
.user-name {
    font-weight: bold;
    color: #595b61;
}
.notification-time {
    color: #74777F;
    font-size: 12px;
    display: flex;
    gap: 5px;
}
.notification-message {
    text-align: left;
    color: #181C22;
    font-size: 16px;
}
.notiifcation-info-conteiner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.time-relative {
    width: 100px;
    padding-right: 0;
}
.table-users {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #e6eaed;
}
.is-read-false {
    background: #f9f9f9;
}
td {
    padding: 10px 30px;
    text-align: left;
}
th {
    text-align: left;
    padding: 10px 30px;
    background: #E6EAED;
}
.preview {
    padding: 10px;
    cursor: pointer;
    width: 30px;
}

.icon {
    background-color: gray;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224 226 236);
    position: relative;
    &.is-read-false {
        &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 10px;
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: rgb(224 226 236);
    
        }

    }
}
.change-item {
    padding: 2px 12px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 3px;
    width: fit-content;
}
.pagination {
    display: flex;
    gap: 10px;

  &:deep(li) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

  }
  &:deep(.paginate-buttons) {
    height: 40px;

    width: 40px;
   
    border-radius: 20px;

    cursor: pointer;

    background-color: rgb(242, 242, 242);

    border: 1px solid rgb(217, 217, 217);

    color: black;
  }

  &:deep(.paginate-buttons:hover) {
    background-color: #d8d8d8;
  }

  &:deep(.active-page) {
    background-color: var(--primary-color);

    border: 1px solid var(--primary-color);

    color: white;
  }

  &:deep(.active-page:hover) {
    background-color: var(--primary-color);
  }
}

.notificaiton-read {
    width: 100%;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
}
</style>